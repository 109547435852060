@use '../../../../../../cdk/src/hsvs/hsvs';

@mixin markdown-inline-base($encapsulated: false) {
  @include hsvs.host('aza', 'markdownInline', $encapsulated) {
    @include hsvs.view('image') {
      max-width: 100%;
    }

    @include hsvs.view('link') {
      transition:
        color 0.25s,
        text-decoration-color 0.25s;

      color: var(--aza-markdown-link-color, #00e);
      text-decoration: underline;
      text-decoration-color: transparent;

      &:hover {
        color: var(--aza-markdown-link-color-active, #00e);
        text-decoration-color: var(--aza-markdown-link-color-active, #00e);
      }
    }

    @include hsvs.view('code') {
      color: var(--aza-markdown-inline-code-color, #d05);
    }

    @include hsvs.view('strikeThrough') {
      text-decoration: line-through;
    }
  }
}
