@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/functions';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/hero-background';

%_title {
  margin-top: 0;
  margin-bottom: 40px;

  text-align: center;

  @include mixins.media-breakpoint-up('lg') {
    margin-bottom: 60px;
  }
}

@include hsvs.host('aza', 'viewerPage') {
  --media-offset: 60px;

  display: block;
  height: calc(100vh - var(--media-offset));
  overflow: auto;

  @include hsvs.view('viewerBg') {
    position: fixed;
    inset: 0;

    z-index: -1;

    background: radial-gradient(
        36.41% 94.18% at 84.95% 0%,
        rgba(64, 74, 163, 0.4) 0%,
        rgba(64, 74, 163, 0) 89.63%
      ),
      radial-gradient(
        47.73% 101.51% at 0% -1.51%,
        rgba(145, 70, 255, 0.2) 0%,
        rgba(145, 70, 255, 0) 84.88%
      ),
      radial-gradient(
        29.01% 110.51% at 73.23% -10.51%,
        #341b64 15.2%,
        rgba(55, 25, 99, 0) 69.09%
      ),
      radial-gradient(
        39.27% 49.2% at 50% 43.74%,
        rgba(151, 48, 148, 0.25) 6.45%,
        rgba(144, 20, 141, 0.105618) 42.11%,
        rgba(139, 0, 135, 0) 77.71%,
        rgba(139, 0, 135, 0) 100%
      );
  }

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    --media-offset: 80px;
  }

  @include hsvs.view('sectionContainer') {
    max-width: 1232px;
    margin: 0 auto;
    padding: 0 16px;
  }

  @include hsvs.view('scrollContainer') {
    flex-direction: row;
    align-items: normal;
  }

  @include hsvs.view('sectionContainerHide') {
    width: 100%;
  }
}
