@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/base-classes/common';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'permissionItem') {
  display: flex;
  gap: 18px;

  align-items: center;

  @include hsvs.view('textContainer') {
    flex: 1;
  }

  @include hsvs.view('title') {
    @include typography.font('body-m', 'regular');
  }

  @include hsvs.view('description') {
    @include typography.font('body-xs', 'regular');
    margin-top: 6px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('notice') {
    @include typography.font('body-xs', 'semi-bold');
    margin-top: 6px;

    color: var(--aza-error-color);
  }

  @include hsvs.view('requiredLabel') {
    @include typography.font('body-xs', 'semi-bold');

    color: var(--aza-text-02-color);
  }
}
