@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'tableOfContents') {
  display: block;

  @include hsvs.view('itemLink') {
    @include typography.font('body-m', 'semi-bold');
    display: block;
    margin: 5px 0;

    transition:
      0.3s color,
      0.3s text-decoration-color;

    color: var(--aza-link-normal-color);
    text-decoration: underline;
    text-decoration-color: transparent;

    @include hsvs.state((), (), ':hover') {
      $color: var(--aza-link-hover-color);

      color: $color;
      text-decoration-color: $color;
    }
  }

  @include hsvs.view('subtree') {
    padding-left: 16px;
  }
}
