@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/titles';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'newsPage') {
  display: block;

  @include hsvs.view('title') {
    @extend %aza-title;
  }

  @include hsvs.view('description') {
    @include typography.font('body-s');
    margin-top: 5px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('news') {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr;
    align-items: flex-start;
    margin: 30px 0 60px;

    @include mixins.media-breakpoint-up(md) {
      grid-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mixins.media-breakpoint-up(xl) {
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      margin: 50px 0 120px;
    }

    @include mixins.media-breakpoint-up(xxl) {
      grid-gap: 50px;
    }
  }
}
