@use '../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'dialogCloseButton') {
  display: block;
  // TODO move positioning out from the button
  position: absolute;
  top: 0;
  right: 0;

  @include hsvs.view('button') {
    margin: 2px;
    padding: 10px;

    border: none;

    background: none;

    color: var(--aza-accent-color);

    cursor: pointer;
  }
}
