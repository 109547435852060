@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'feedShopRedeem') {
  @include typography.font('body-l', 'semi-bold');

  @include hsvs.view('content') {
    display: grid;
    grid-template-columns: 99px minmax(0, 1fr);
    gap: 0 20px;
  }

  @include hsvs.view('shopItemPoster') {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }

  @include hsvs.view('shopItemPosterBack') {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;

    transform: translateX(-50%);
    filter: blur(10px);

    opacity: 0.4;
  }

  @include hsvs.view('shopItemImageContainer') {
    @include mixins.fixed-ratio-image-container(323, 392, $objectPosition: top);
  }

  @include hsvs.view('infoContainer') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 20px 16px 0;
    gap: 6px;
  }

  @include hsvs.view('azaPrice') {
    color: var(--aza-accent-color);
  }

  @include hsvs.view('bagImage') {
    width: 21px;
    height: 21px;

    vertical-align: middle;
  }

  @include hsvs.view('note') {
    @include typography.font('body-xs', 'light');

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('alignedLine') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 12px;
  }
}
