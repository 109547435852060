@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/base-classes/shop-card';

@include hsvs.host(aza, shopCard) {
  @extend %shop-card-layout;

  @include typography.font(body-m, semi-bold);

  @include hsvs.view(poster) {
    @extend %shop-card-poster;

    position: relative;
  }

  @include hsvs.view('posterImageBack') {
    @extend %shop-card-poster-back;
  }

  @include hsvs.view('posterImage') {
    @extend %shop-card-poster-image;
  }

  @include hsvs.view('descriptionSection') {
    @extend %shop-card-description-section;
  }

  @include hsvs.view('itemName') {
    margin: 0 0 10px;

    @include typography.font('body-m', 'semi-bold');
  }

  @include hsvs.view('itemMetadata') {
    display: flex;
    flex: 1;
    align-items: flex-end;
    margin-top: 15px;
    margin-bottom: 7px;
  }

  @include hsvs.view('controls') {
    display: flex;
    justify-content: space-between;
  }

  @include hsvs.view('buyLoader') {
    width: 30px;
    padding: 3px 35px;
  }

  @include hsvs.view('discountTag') {
    display: inline-block;
    position: absolute;
    z-index: 2;
    top: 2em;
    left: 0;
    padding: 0.3em 1.3em;

    background-color: var(--aza-accent-color);
  }

  @include hsvs.view('aza') {
    --aza-core-aza-icon-size: 20px;
  }

  @include hsvs.view('reducedPriceTag') {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @include hsvs.view('originalPrice') {
    position: relative;
    padding: 0 0.2em;

    @include hsvs.state((), (), ('::after')) {
      content: '';

      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 0.075em;

      background-color: currentColor;
    }
  }
}
