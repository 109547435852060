@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'triviaGameQuestionForm') {
  display: block;

  @include hsvs.view('title') {
    @include typography.font('h5-mobile', 'semi-bold');

    margin-top: 0;
    margin-bottom: 40px;
  }

  @include hsvs.view('form') {
    display: grid;
    width: 100%;
    gap: 16px;
  }

  @include hsvs.view('question') {
    @include typography.font('body-l', 'semi-bold');
  }

  @include hsvs.view('answer') {
    @include typography.font('body-m');
  }

  @include hsvs.view('action') {
    display: flex;
    justify-content: flex-end;
  }

  @include hsvs.view('button') {
    min-width: 150px;
  }
}
