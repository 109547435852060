@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/base-classes/hero-background';

@include hsvs.host('aza', 'streamerPage') {
  display: block;

  @include hsvs.view('heroSection') {
    @extend %hero-background;
  }

  @include hsvs.view('standOutSection') {
    margin: 60px 0 80px;
    $top-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(0, 102, 255, 0.2),
          rgba(0, 102, 255, 0)
        ),
      size: 639px 530px,
      offset-x: 0px,
      offset-y: -150px,
    );
    $bottom-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(204, 0, 177, 0.3),
          rgba(204, 0, 176, 0)
        ),
      size: 680px 480px,
      offset-x: 0px,
      offset-y: 0px,
    );
    $gradient-inflation: 200px;
    @include mixins.gradient-background(
      $top-gradient,
      $bottom-gradient,
      calc(50%),
      calc(25%),
      $gradient-inflation,
      0.75
    );

    @include mixins.media-breakpoint-up(lg) {
      margin: 80px 0 120px;
    }

    @include mixins.media-breakpoint-up(xxl) {
      margin: 80px 0 120px;
      $top-gradient: (
        gradient:
          radial-gradient(
            closest-side,
            rgba(0, 102, 255, 0.2),
            rgba(0, 102, 255, 0)
          ),
        size: 639px 530px,
        offset-x: 250px,
        offset-y: -150px,
      );
      $bottom-gradient: (
        gradient:
          radial-gradient(
            closest-side,
            rgba(204, 0, 177, 0.5),
            rgba(204, 0, 176, 0)
          ),
        size: 680px 480px,
        offset-x: 250px,
        offset-y: 0px,
      );
      $gradient-inflation: 200px;
      @include mixins.gradient-background(
        $top-gradient,
        $bottom-gradient,
        calc(50% - min(750px, 50vw)),
        calc(50% + 25px),
        $gradient-inflation,
        0.75
      );
    }
  }

  @include hsvs.view('streamerEarnSection') {
    margin: 60px 0 80px;
    $top-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(23, 0, 255, 0.23),
          rgba(23, 78, 255, 0)
        ),
      size: 400px 270px,
      offset-x: 50px,
      offset-y: -90px,
    );
    $bottom-gradient: (
      gradient: variables.$default-bottom-gradient,
      size: 680px 480px,
      offset-x: -90px,
      offset-y: 60px,
    );
    $gradient-inflation: 200px;
    @include mixins.gradient-background(
      $top-gradient,
      $bottom-gradient,
      calc(50% + min(750px, 50vw)),
      calc(50% + 25px),
      $gradient-inflation,
      0.75
    );

    @include mixins.media-breakpoint-up(lg) {
      margin: 80px 0 120px;
    }
  }

  @include hsvs.view('testimonialSection') {
    margin-bottom: 120px;
    $top-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(23, 0, 255, 0.23),
          rgba(23, 78, 255, 0)
        ),
      size: 600px 400px,
      offset-x: 50px,
      offset-y: -90px,
    );
    $bottom-gradient: (
      gradient: variables.$default-bottom-gradient,
      size: 890px 730px,
      offset-x: -90px,
      offset-y: 60px,
    );
    $gradient-inflation: 200px;
    @include mixins.gradient-background(
      $top-gradient,
      $bottom-gradient,
      calc(50% - min(750px, 50vw)),
      calc(25% - 25px),
      $gradient-inflation,
      0.75
    );

    @include mixins.media-breakpoint-up('lg') {
      @include mixins.gradient-background(
        $top-gradient,
        $bottom-gradient,
        calc(50% - min(750px, 50vw)),
        calc(50% - 25px),
        $gradient-inflation,
        0.75
      );
    }
  }
}
