@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../styles/abstracts/base-classes/titles';

@include hsvs.host('aza', 'consolePage') {
  display: block;
  padding-top: 70px;
  padding-bottom: 90px;

  @include hsvs.view('streamSessionInfo') {
    margin-bottom: 40px;
  }

  @include hsvs.view('pageTitle') {
    @extend %aza-mobile-only-title;
  }
}
