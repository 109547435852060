@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'advertisePageExperienceSection') {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include hsvs.view('content') {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 1260px;

    @include mixins.media-breakpoint-up(xl) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      gap: 2em;
    }
  }

  @include hsvs.view('descriptionSection') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;

    @include mixins.media-breakpoint-up(xl) {
      align-items: flex-start;
      padding: 0;
    }
  }

  @include hsvs.view('imageWrapper') {
    max-width: 100%;

    @include mixins.media-breakpoint-up(xl) {
      max-width: 588px;
    }
  }

  @include hsvs.view('imageContainer') {
    @include mixins.fixed-ratio-image-container(588, 347);
  }

  @include hsvs.view('title') {
    @include typography.font('h2', 'semi-bold');

    margin-top: 0;

    color: var(--aza-text-01-color);
    text-align: center;

    @include mixins.media-breakpoint-up(xl) {
      @include typography.font('h2', 'semi-bold');

      margin-bottom: 20px;

      text-align: start;
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-l', 'light');

    margin: 0;
    padding-bottom: 25px;

    color: var(--aza-text-01-color);
    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      text-align: start;
    }
  }

  @include hsvs.view('contactUsLink') {
    width: 260px;
    height: 56px;
    padding: 16px 22px;
  }

  @include hsvs.view('sellingPoints') {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    min-height: 190px;
    margin-top: 90px;

    @include mixins.media-breakpoint-up(xl) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      max-width: 1260px;
    }
  }

  @include hsvs.view('sellingContent') {
    z-index: 1;
    padding: 30px;

    border-radius: 12px;

    background-color: var(--aza-base-02-color);
  }

  @include hsvs.view('sellingTitle') {
    @include typography.font('h6', 'semi-bold');

    margin-top: 0;
    margin-bottom: 20px;

    color: var(--aza-text-01-color);

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('sellingDescription') {
    @include typography.font('body-l', 'light');

    margin: 0;

    color: var(--aza-text-01-color);
  }
}
