@use '../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'closableErrorSnackbar') {
  @include typography.font('body-s');

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  margin: 0 16px;
  padding: 14px 16px;

  border-radius: 4px;

  background-color: var(--aza-error-color);
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);

  color: var(--aza-text-01-color);

  @include hsvs.view('button') {
    min-width: 3.5em;
  }
}
