@use 'sass:math';

@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/mixins' as coreMixins;
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/functions';
@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'advertisePageHero') {
  display: block;

  @include hsvs.view('content') {
    display: grid;
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
    align-items: center;
    width: 100%;
    max-width: 1260px;
    margin: auto;

    @include mixins.media-breakpoint-up(lg) {
      grid-column-gap: 72px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 349px;
      padding: 6em 0 14em;
    }
  }

  @include hsvs.view('title') {
    @include typography.font('h3', 'semi-bold');
    max-width: 683px;
    margin: 0 0 35px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h1', 'semi-bold');

      margin: 0 0 45px;

      color: var(--aza-base-02-color);
    }
  }

  @include hsvs.view('recentCampaigns') {
    display: grid;
    grid-row-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    align-items: center;
    max-width: 458px;
    max-height: 62px;
    padding: 14px 30px 14px 30px;

    border-radius: 5px;

    background-color: var(--aza-base-02-color);
  }

  @include hsvs.view('campaignIcon') {
    max-height: 21.3px;

    @include mixins.media-breakpoint-up('lg') {
      max-height: 30px;
    }
  }

  @include hsvs.view('videoContainer') {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 349px;
    padding-top: functions.get-height-percentage-from-resolution(1920, 1080);
    overflow: hidden;
    isolation: isolate;

    border-radius: 5px;
  }

  @include hsvs.view('video') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
