@use '../../../../cdk/src/hsvs/hsvs';

@mixin azaIcon-base($encapsulated: false) {
  @include hsvs.host('azaCore', 'azaIcon', $encapsulated) {
    --aza-core-aza-icon-size: 36px;

    display: inline-flex;
    min-width: var(--aza-core-aza-icon-size);
    max-width: var(--aza-core-aza-icon-size);
    min-height: var(--aza-core-aza-icon-size);
    max-height: var(--aza-core-aza-icon-size);

    @include hsvs.view('svg') {
      width: 100%;
    }
  }
}
