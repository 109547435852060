@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'advertisePageInvestmentPotentialSection') {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 127px 0;
  gap: 50px;

  @include mixins.media-breakpoint-up('xl') {
    flex-direction: row;
  }

  @include hsvs.view('titleContainer') {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include mixins.media-breakpoint-up('xl') {
      align-items: baseline;
      max-width: 531px;
    }
  }

  @include hsvs.view('title') {
    color: var(--aza-text-01-color);
    @include typography.font('h2-mobile', 'semi-bold');

    text-align: center;

    @include mixins.media-breakpoint-up('xl') {
      @include typography.font('h2', 'semi-bold');

      text-align: start;
    }
  }

  @include hsvs.view('titleBody') {
    color: var(--aza-text-01-color);
    @include typography.font('body-l', 'light');

    text-align: center;

    @include mixins.media-breakpoint-up('xl') {
      text-align: start;
    }
  }

  @include hsvs.view('contactUsLink') {
    width: 260px;
  }

  @include hsvs.view('sellingPoints') {
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-content: center;
    width: 100%;

    gap: 15px;

    @include mixins.media-breakpoint-up('xl') {
      grid-template-columns: 1fr 1fr;
      max-width: 670px;

      gap: 30px;
    }
  }

  @include hsvs.view('sellingContent') {
    z-index: 1;
    padding: 20px;

    border-radius: 12px;

    background-color: var(--aza-base-02-color);

    gap: 10px;
  }

  @include hsvs.view('sellingLongContent') {
    z-index: 1;
    padding: 20px;

    border-radius: 12px;

    background-color: var(--aza-base-02-color);

    gap: 10px;

    @include mixins.media-breakpoint-up('xl') {
      grid-column-end: 3;
      grid-column-start: 1;
    }
  }

  @include hsvs.view('sellingTitle') {
    @include typography.font('body-xxl', 'semi-bold');
    color: var(--aza-text-01-color);
  }

  @include hsvs.view('sellingDescription') {
    @include typography.font('body-l', 'light');
    color: var(--aza-text-01-color);
  }
}
