@use '../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'classicTriviaGameState') {
  display: block;

  @include hsvs.view('game') {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    gap: 50px;
  }

  @include hsvs.view('questionState') {
    flex: 1;
  }

  @include hsvs.view('question') {
    min-width: min(270px, 100%);
  }
}
