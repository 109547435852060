@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'setupPageAccount') {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  column-gap: 25px;

  @include hsvs.view('image') {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }

  @include hsvs.view('name') {
    @include typography.font('body-l', 'semi-bold');
    overflow: hidden;

    color: var(--aza-text-01-color);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
