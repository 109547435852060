@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/titles';

@include hsvs.host('aza', 'contactsPage') {
  display: block;

  @include hsvs.view('title') {
    @extend %aza-title;
  }

  @include hsvs.view('contentContainer') {
    display: grid;
    grid-gap: variables.$desktop-padding;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    margin: variables.$desktop-padding 0 60px;
  }

  @include hsvs.view('contentCard') {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: variables.$desktop-padding 20px;

    border-radius: 10px;

    background-color: var(--aza-base-02-color);

    text-align: center;

    @include mixins.media-breakpoint-up(xxl) {
      padding: 54px 60px;
    }
  }

  @include hsvs.view('description') {
    @include typography.font('h5-mobile', 'semi-bold');

    max-width: 554px;
    margin: 24px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h5', 'semi-bold');
    }
  }

  @include hsvs.view('emailLinkWrapper') {
    display: flex;
    align-items: center;
    min-height: 56px;
  }

  @include hsvs.view('emailLink') {
    @include typography.font('h6');

    transition: color 150ms;

    color: var(--aza-link-normal-color);
    text-decoration: none;

    @include hsvs.state((), (), ':hover') {
      color: var(--aza-link-hover-color);
    }
  }

  @include hsvs.view('discordLogo') {
    margin: 0 30px;

    vertical-align: middle;
  }

  @include hsvs.view('discordButton') {
    background-color: #5865f2;

    &:not(:disabled):hover {
      background-color: #5865f2;
    }
  }
}
