@use '../mixins';
@use '../variables';

%nav-sidebar-container {
  display: grid;
  grid-template-columns: 100%;
  flex: 1;
  height: 100%;
  padding-top: variables.$mobile-header-height;

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    padding-top: variables.$desktop-header-height;
  }

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    grid-template-columns: max-content minmax(0, 1fr);
  }
}

%nav-sidebar {
  display: none;

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    display: block;
  }
}

%nav-item-container {
  display: none;

  @include mixins.media-breakpoint-up('xxl') {
    display: flex;
    flex: 1;
    align-items: center;
  }
}
