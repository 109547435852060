@use '../../../../../../cdk/src/hsvs/hsvs';

@use '../../../../styles/base-classes/buttons';

@mixin button-base($encapsulated: false) {
  @include hsvs.host('aza', 'button', $encapsulated) {
    // NOTE: nested selectors with & don't work with :host
    // Check https://github.com/angular/angular/issues/46466 for reference
    @if $encapsulated == true {
      @include buttons.aza-button();
    } @else {
      @extend %button;
    }

    padding: 12px 14px;

    color: var(--aza-light-stable-color);

    @include hsvs.state([fab]) {
      width: 48px;
      height: 48px;
      padding: 0;

      border-radius: 50%;
    }

    @include hsvs.state(([color, primary])) {
      @if $encapsulated == true {
        @include buttons.aza-button-primary();
      } @else {
        @extend %button--primary;
      }
    }

    @include hsvs.state(([color, secondary])) {
      @if $encapsulated == true {
        @include buttons.aza-button-secondary();
      } @else {
        @extend %button--secondary;
      }
    }

    @include hsvs.state(([color, outlined])) {
      position: relative;
      overflow: hidden;

      background-color: transparent;
      box-shadow: 0 0 0 2px inset var(--aza-accent-color);

      color: var(--aza-accent-color);
      isolation: isolate;
    }

    @include hsvs.state(([color, outlined] '::before')) {
      content: '';

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transition: opacity 0.5s;

      opacity: 0;
      background-image: linear-gradient(
        273.86deg,
        rgb(144, 70, 159) 0%,
        rgb(241, 56, 134) 40.7%,
        rgb(238, 58, 87) 77.33%
      );
    }

    @include hsvs.state(([color, outlined] ':hover')) {
      background-color: transparent;
    }

    @include hsvs.state(([color, outlined] ':hover' '::before')) {
      opacity: 0.14;
    }

    @include hsvs.state(([color, outlined] ':active' '::before')) {
      opacity: 0.24;
    }

    //TODO add outlined-primary in showcase
    @include hsvs.state(([color, outlined-primary])) {
      position: relative;
      overflow: hidden;

      background-color: transparent;
      box-shadow: 0 0 0 2px inset var(--aza-link-normal-color);

      color: var(--aza-link-normal-color);
      isolation: isolate;
    }

    @include hsvs.state(([color, outlined-primary] '::before')) {
      content: '';

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transition: opacity 0.5s;

      opacity: 0;
      background-image: linear-gradient(
        273.86deg,
        rgba(44, 81, 189),
        rgba(84, 59, 161),
        rgba(88, 52, 136)
      );
    }

    @include hsvs.state(([color, outlined-primary] ':hover')) {
      background-color: transparent;
    }

    @include hsvs.state(([color, outlined-primary] ':hover' '::before')) {
      opacity: 0.14;
    }

    @include hsvs.state(([color, outlined-primary] ':active' '::before')) {
      opacity: 0.24;
    }

    @include hsvs.state([size, small]) {
      padding: 6px 14px;
    }

    @include hsvs.state([size, large]) {
      padding: 16px 14px;
    }

    @include hsvs.state(([fab], [size, small])) {
      width: 36px;
      height: 36px;
      padding: 0;
    }

    @include hsvs.state(([fab], [size, large])) {
      width: 60px;
      height: 60px;
      padding: 0;
    }
  }
}
