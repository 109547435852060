@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'overlayGameSection') {
  display: block;
  margin-top: 200px;

  $top-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(0, 102, 255, 0.2),
        rgba(0, 102, 255, 0)
      ),
    size: 639px 530px,
    offset-x: -700px,
    offset-y: -100px,
  );
  $bottom-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(204, 0, 177, 0.3),
        rgba(204, 0, 176, 0)
      ),
    size: 680px 480px,
    offset-x: -700px,
    offset-y: -100px,
  );
  $gradient-inflation: 200px;

  @include mixins.gradient-background(
    $top-gradient,
    $bottom-gradient,
    calc(50%),
    calc(25%),
    $gradient-inflation,
    0.75
  );

  @include hsvs.view('content') {
    display: grid;

    gap: 30px;

    @include mixins.media-breakpoint-up('xxl') {
      grid-template-columns: 1fr 1.5fr;
    }
  }

  @include hsvs.view('imageWrapper') {
    max-width: 604px;
  }

  @include hsvs.view('imageContainer') {
    @include mixins.fixed-ratio-image-container(604, 569);
  }

  @include hsvs.view('section') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include hsvs.view('sectionTitle') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0;
    margin-bottom: 50px;

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('h2', 'semi-bold');

      margin-bottom: 60px;
    }
  }

  @include hsvs.view('descriptionSection') {
    display: grid;
    grid-template-columns: 1fr;

    gap: 30px 50px;

    margin-bottom: 40px;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include hsvs.view('description') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    row-gap: 12px;
  }

  @include hsvs.view('descriptionTitle') {
    @include typography.font('h6', 'semi-bold');

    margin: 0;
  }

  @include hsvs.view('descriptionText') {
    @include typography.font('body-l', 'light');

    margin: 0;
  }

  @include hsvs.view('action') {
    margin-top: auto;
  }

  @include hsvs.view('link') {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
}
