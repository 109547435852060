@use '../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'activityFeed') {
  @include hsvs.view('feedItemBox') {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
  }
  @include hsvs.view('itemContainer') {
    max-height: 100%;
    overflow: hidden;
  }
  @include hsvs.view('card') {
    display: table;
    margin: 0 0 16px 0;
  }
}
