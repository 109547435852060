::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;

  background: var(--aza-base-02-color);
}

body::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;

  background-color: var(--aza-scroll-bar-alpha-color);
}
