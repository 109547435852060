@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'streamerPageTestimonial') {
  display: block;

  text-align: center;

  @include hsvs.view('title') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0;
    margin-bottom: 78px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');

      margin-bottom: 144px;
    }
  }

  @include hsvs.view('testimonial') {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 70px 40px;

    @include mixins.media-breakpoint-up('sm') {
      padding: 0 10px;
    }
  }

  @include hsvs.view('streamerCard') {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    max-width: 636px;
    margin: 0 auto;
    padding: 67px 24px 36px;

    transform: scale(1);
    transition: transform 0.2s;

    border-radius: 10px;

    background-color: var(--aza-base-02-color);

    color: var(--aza-text-01-color);

    @include hsvs.state((), (), ':hover') {
      @include mixins.media-breakpoint-up('sm') {
        transform: scale(1.1);
      }
    }
  }

  @include hsvs.view('streamerImage') {
    position: absolute;
    top: 0;
    left: 50%;
    width: 96px;
    height: 96px;
    padding: 4px;

    transform: translate(-50%, -50%);

    border: 2px solid var(--aza-text-02-color);
    border-radius: 50%;

    box-shadow: 0 4px 18px 0 rgba(#000000, 0.4);
  }

  @include hsvs.view('name') {
    @include typography.font('h6', 'semi-bold');

    margin-bottom: 7px;
  }

  @include hsvs.view('followers') {
    @include typography.font('body-xs', 'light');
    margin-bottom: 17px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('svg') {
    width: 100%;
    max-width: 44px;
    margin-bottom: 17px;
  }

  @include hsvs.view('review') {
    @include typography.font('body-l');
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  @include hsvs.view('button') {
    width: 100%;
    margin-top: 60px;
    @include mixins.media-breakpoint-up('lg') {
      width: fit-content;
      padding: 16px 40px;
    }
  }
}
