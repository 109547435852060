@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host(aza, currencyDisplayReduced) {
  display: flex;
  align-items: center;
  gap: 8px;

  @include hsvs.view('aza') {
    --aza-core-aza-icon-size: 20px;
  }

  @include hsvs.view('originalPrice') {
    position: relative;
    padding: 0 0.2em;

    @include hsvs.state((), (), ('::after')) {
      content: '';

      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 0.075em;

      background-color: currentColor;
    }
  }

  @include hsvs.view('reducedPrice') {
    color: var(--aza-success-color);
  }
}
