@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';

%_link {
  color: var(--aza-text-01-color);
  text-decoration: none;
}

@include hsvs.host('aza', 'footer') {
  @include typography.font('body-s', 'light');

  display: block;
  padding: 20px 0;

  background-color: var(--aza-base-02-color);

  color: var(--aza-text-01-color);

  @include hsvs.view('bottomSection') {
    display: flex;
    flex-wrap: wrap;
    column-gap: 45px;
    row-gap: 25px;
  }

  @include hsvs.view('introTitle') {
    @include typography.font('body-l', 'semi-bold');

    min-width: 330px;
    margin: 14px 0 0;

    color: var(--aza-text-02-color);
    white-space: nowrap;

    @include mixins.media-breakpoint-up('sm') {
      min-width: auto;
    }
  }

  @include hsvs.view('bottomSectionNavContainer') {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    column-gap: 55px;
    row-gap: 30px;

    @include mixins.media-breakpoint-up('md') {
      flex-wrap: nowrap;
    }
  }

  @include hsvs.view('bottomSectionNav') {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    min-width: 330px;
    column-gap: 55px;
    row-gap: 30px;

    @include mixins.media-breakpoint-up('sm') {
      flex-wrap: nowrap;
      min-width: auto;
    }
  }

  @include hsvs.view('columnTitle') {
    @include typography.font('body-m', 'semi-bold');

    margin: 0 0 7px;

    white-space: nowrap;
  }

  @include hsvs.view('verticalNav') {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    white-space: nowrap;
  }

  @include hsvs.view('navItem') {
    @extend %_link;

    @include typography.font('body-s', 'light');

    @include hsvs.state((), (), (':hover')) {
      text-decoration: underline;
    }
  }

  @include hsvs.view('careerLink') {
    @extend %_link;

    display: grid;
    grid-template-columns: auto minmax(80px, 1fr) auto;
  }

  @include hsvs.view('careerLinkExternal') {
    width: 100%;
    overflow: hidden;

    color: var(--aza-link-normal-color);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include hsvs.view('socialLinks') {
    display: flex;
    flex-direction: column;

    @include mixins.media-breakpoint-up('lg') {
      align-items: flex-end;
    }
  }

  @include hsvs.view('socialLinksNav') {
    display: flex;
    gap: 20px;
  }

  @include hsvs.view('copyright') {
    @include typography.font('body-xs', 'light');

    margin-top: auto;
    padding-top: 20px;
  }

  @include hsvs.view('icon') {
    display: inline-block;
    margin-left: 10px;

    color: var(--aza-link-normal-color);
  }
}
