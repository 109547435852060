@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'addClassicTriviaPartyDialog') {
  display: block;

  @include hsvs.view('action') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
