@use 'sass:selector';
@use '../../../../cdk/src/hsvs/hsvs';

@function _deep-selector($selector, $encapsulated) {
  @if ($encapsulated == true) {
    @return selector.append('::ng-deep', $selector);
  }

  @return $selector;
}

@mixin passcode-input-base($encapsulated: false) {
  @include hsvs.host('azaCore', 'passcodeInput', $encapsulated) {
    display: inline-block;

    @include hsvs.view('passcodeInput') {
      --item-spacing: 0.5em;
      --item-height: 2.584em;
      --item-border: 2px solid transparent;
      --item-border-bottom: 2px solid transparent;
      --item-border-has-value: 2px solid var(--aza-text-01-color);
      --item-border-bottom-has-value: 2px solid var(--aza-text-01-color);
      --item-border-focused: 2px solid var(--aza-text-01-color);
      --item-border-bottom-focused: 2px solid var(--aza-text-01-color);
      --item-shadow-focused: none;
      --item-border-radius: 10px;
      --item-background: var(--aza-base-03-alpha-color);
      --color: inherit;

      width: 17em;

      @include hsvs.state((), (), [disabled]) {
        opacity: 0.5;
      }

      #{_deep-selector('span', $encapsulated)} {
        padding-left: 0 !important;

        &:first-child {
          padding-left: 0 !important;
        }

        &:last-child {
          padding-right: 0 !important;
        }

        & input[type='tel'] {
          box-sizing: border-box !important;
        }
      }
    }
  }
}
