@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/mixins' as core-mixins;
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/functions';
@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'header') {
  display: flex;
  position: relative;
  flex-direction: column;
  height: variables.$mobile-header-height;

  background-color: var(--aza-base-02-color);
  box-shadow: 0 3px 10px rgba(black, 0.17);

  color: var(--aza-text-01-color);

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    height: variables.$desktop-header-height;
  }

  @include hsvs.view('loading') {
    @include core-mixins.loading-indicator-background();

    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    contain: strict;
  }

  @include hsvs.view('container') {
    display: flex;
    position: relative;
    z-index: 1;
    flex: 1;
  }

  @include hsvs.view('content') {
    display: flex;
    flex: 1;
    column-gap: 20px;
  }

  @include hsvs.view('mobileMenu') {
    display: flex;
    align-items: center;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      display: none;
    }
  }

  @include hsvs.view('navWrapper') {
    display: none;
    margin-right: auto;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      display: flex;
      align-items: center;
    }
  }

  @include hsvs.view('logoLink') {
    display: flex;

    cursor: pointer;
  }

  @include hsvs.view('userCorner') {
    display: flex;
    align-items: center;
    gap: 40px;

    margin-left: auto;
  }
}
