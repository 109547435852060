@use 'sass:map';

@use '../../../../../core/src/styles/typography';

@use '../variables';
@use '../mixins';

%aza-container-fluid {
  width: variables.$container-fluid-width;
  margin: 0 auto;
}

%aza-container-responsive {
  width: variables.$restricted-container-responsive-width;
  margin: 0 auto;
}

%aza-position-behind-header {
  margin-top: -#{variables.$mobile-header-height};
  padding-top: variables.$mobile-header-height;

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    margin-top: -#{variables.$desktop-header-height};
    padding-top: variables.$desktop-header-height;
  }
}

// TODO remove in favor of core/styles/base-classes/_links.scss
%aza-accent-link {
  @include typography.font('body-s', 'semi-bold');
  transition:
    color 0.25s,
    text-decoration-color 0.25s;

  color: var(--aza-accent-color);
  text-decoration: underline;
  text-decoration-color: transparent;

  &:hover {
    $color: var(--aza-accent-active-color);

    color: $color;
    text-decoration-color: $color;
  }
}

%aza-vertical-nav-item-spacing {
  padding: var(
    --aza-vertical-nav-item-padding,
    #{16px variables.$container-padding}
  );
}

%aza-visual-background {
  background: var(--aza-base-02-color);
}

%aza-nav-link {
  display: inline-flex;
  position: relative;

  background-color: transparent;

  color: var(--aza-text-01-color);
  text-decoration: none;

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 4px;

    transform: translateX(-50%);
    transition: width 0.25s ease-in-out;

    background-color: var(--aza-accent-color);
  }
}

%aza-nav-link--active {
  font-weight: typography.font-weight('semi-bold');
  &::after {
    width: 100%;
  }
}

%aza-link {
  transition-property: color;
  transition-duration: 200ms;

  color: var(--aza-link-normal-color);
  font-weight: typography.font-weight('semi-bold');

  cursor: pointer;
}

%aza-dialog-link {
  @extend %aza-link;

  text-decoration: none;

  &:hover {
    color: var(--aza-link-hover-color);
  }
}

%aza-dialog-link-with-underline {
  @extend %aza-link;

  &:hover {
    $color: var(--aza-link-hover-color);

    color: $color;
    text-decoration-color: $color;
  }
}

%aza-switch-theme-nav-item {
  @extend %aza-vertical-nav-item-spacing;

  display: flex;
  align-items: center;

  background-color: transparent;

  color: var(--aza-text-02-color);

  cursor: pointer;
  gap: 14px;

  &:hover {
    background-color: var(--aza-base-01-color);
  }
}
