@mixin aza-loading-indicator-animation {
  @keyframes aza-loading-indicator {
    from {
      background-position: 0 0;
    }

    to {
      background-position: 32px 0;
    }
  }
}
