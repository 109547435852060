@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'transactionsPage') {
  display: block;

  @include hsvs.view('action') {
    margin: 20px 0;
  }

  @include hsvs.view('select') {
    width: min(100%, 350px);
  }
}
