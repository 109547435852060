@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/base-classes/header';

@include hsvs.host('aza', 'landing') {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @include hsvs.view('header') {
    @extend %aza-header;
  }

  @include hsvs.view('main') {
    flex: 1;
    padding-top: variables.$mobile-header-height;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      padding-top: variables.$desktop-header-height;
    }
  }
}
