@use '../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'warningMessage') {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  column-gap: 18px;

  border-radius: 10px;

  background-color: rgba(255, 153, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 153, 0, 1);
}
