@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/base-classes/common';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../../../core/src/styles/base-classes/links';

@include hsvs.host('aza', 'accountDeleteSnackbar') {
  @include typography.font('body-s');

  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
  margin: 0 16px;
  padding: 16px 24px;

  border-left: 6px solid var(--aza-error-color);
  border-radius: 5px;

  background-color: var(--aza-base-02-color);
  box-shadow: 0px 4px 12px rgba(4, 0, 26, 0.25);

  color: var(--aza-text-01-color);

  @include hsvs.view('contentSide') {
    flex: 1;
  }

  @include hsvs.view('warningLine') {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  @include hsvs.view('icon') {
    width: 20px;
    height: 20px;

    color: var(--aza-error-color);
  }

  @include hsvs.view('warningText') {
    @include typography.font('body-l', 'semi-bold');
  }

  @include hsvs.view('notice') {
    @include typography.font('body-s', 'light');
  }

  @include hsvs.view('time') {
    @include typography.font('body-s', 'semi-bold');
  }

  @include hsvs.view('cancelButton') {
    @extend %aza-link--error;
    @include typography.font('body-s', 'semi-bold');
    padding: 0;

    border: none;

    background: none;

    cursor: pointer;
  }
}
