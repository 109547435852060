@use '../../../../cdk/src/hsvs/hsvs';

@mixin slim-range-slider-base($encapsulated: false) {
  %_knob-before-activated {
    box-shadow: 0 0 0 var(--aza-slim-range-slider-knob-outlide-width)
      var(--aza-slim-range-slider-color);
  }

  @include hsvs.host(aza, slimRangeSlider, $encapsulated) {
    --aza-slim-range-slider-left: 0;
    --aza-slim-range-slider-right: 0;
    --aza-slim-range-slider-knobs-count: 2;

    --aza-slim-range-slider-color: #ee3a57;
    --aza-slim-range-slider-track-height: 4px;
    --aza-slim-range-slider-highlight-height: 6px;
    --aza-slim-range-slider-knob-size: 20px;
    --aza-slim-range-slider-knob-outlide-width: 10px;

    --aza-slim-range-slider-max-shift: calc(
      100% - var(--aza-slim-range-slider-knob-size) *
        var(--aza-slim-range-slider-knobs-count)
    );

    display: block;
    position: relative;
    z-index: 0;
    height: 40px;

    @include hsvs.view(track) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: var(--aza-slim-range-slider-track-height);
      margin-top: auto;
      margin-bottom: auto;

      border-radius: calc(var(--aza-slim-range-slider-track-height) / 2);

      opacity: 0.24;
      background-color: var(--aza-slim-range-slider-color);
    }

    @include hsvs.view(highlight) {
      position: absolute;
      top: 0;
      right: calc(
        var(--aza-slim-range-slider-max-shift) *
          var(--aza-slim-range-slider-right)
      );
      bottom: 0;
      left: calc(
        var(--aza-slim-range-slider-max-shift) *
          var(--aza-slim-range-slider-left)
      );
      height: var(--aza-slim-range-slider-highlight-height);
      margin: auto 0;

      border-radius: calc(var(--aza-slim-range-slider-highlight-height) / 2);

      background-color: var(--aza-slim-range-slider-color);
    }

    @include hsvs.view(knob) {
      position: absolute;
      top: 0;
      bottom: 0;
      width: var(--aza-slim-range-slider-knob-size);
      height: var(--aza-slim-range-slider-knob-size);
      margin-top: auto;
      margin-bottom: auto;

      border-radius: calc(var(--aza-slim-range-slider-knob-size) / 2);

      background-color: var(--aza-slim-range-slider-color);

      cursor: ew-resize;

      &::before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transition: 100ms box-shadow;

        border-radius: 50%;

        opacity: 0.24;
        box-shadow: 0 0 0 0 var(--aza-slim-range-slider-color);

        pointer-events: none;
      }

      @include hsvs.state((), (), ':hover') {
        &::before {
          @extend %_knob-before-activated;
        }
      }

      @include hsvs.state((), (), ':active') {
        &::before {
          @extend %_knob-before-activated;
        }
      }

      @include hsvs.state((), (), [type start]) {
        left: calc(
          var(--aza-slim-range-slider-max-shift) *
            var(--aza-slim-range-slider-left)
        );
      }

      @include hsvs.state((), (), [type end]) {
        right: calc(
          (var(--aza-slim-range-slider-max-shift)) *
            var(--aza-slim-range-slider-right)
        );
      }
    }
  }
}
