@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'userCorner') {
  display: flex;
  align-items: center;
  column-gap: 40px;

  @include hsvs.view('balance') {
    display: flex;
    column-gap: 10px;
  }

  @include hsvs.view('balanceContent') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @include hsvs.view('balanceTitle') {
    @include typography.font('body-xs', 'light');

    color: var(--aza-text-02-color);
    line-height: 12px;
  }

  @include hsvs.view('balanceValue') {
    @include typography.font('body-m', 'semi-bold');

    color: var(--aza-text-01-color);
    line-height: 18px;
  }
}
