@use '../typography';

%aza-form-error {
  @include typography.font('body-xs', 'semi-bold');
  position: absolute;
  top: 100%;
  left: 0;

  color: var(--aza-error-color);
}
