@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'navSidebar') {
  display: block;
  position: sticky;
  top: variables.$desktop-header-height;
  max-height: calc(100vh - #{variables.$desktop-header-height});
  overflow: auto;

  background-color: var(--aza-base-02-color);

  @include mixins.media-breakpoint-up('xxl') {
    min-width: 270px;
  }
}
