@use '../../../../cdk/src/hsvs/hsvs';

@mixin expansion-panel-base($encapsulated: false) {
  @include hsvs.host('aza', 'expansionPanel', $encapsulated) {
    --aza-expansion-panel-border-radius: 5px;
    --aza-expansion-panel-background-color: #2f3031;
    --aza-expansion-panel-toggle-icon-line-border-radius: 5px;
    --aza-expansion-panel-color: #fff;
    --aza-expansion-panel-spacing: 16px;

    display: block;
    padding: var(--aza-expansion-panel-spacing);
    overflow: hidden;

    border-radius: var(--aza-expansion-panel-border-radius);

    background-color: var(--aza-expansion-panel-background-color);

    color: var(--aza-expansion-panel-color);

    @include hsvs.view('control') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include hsvs.view('toggleIconWrapper') {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }

    @include hsvs.view('toggleIcon') {
      position: relative;
      width: 40px;
      min-width: 40px;
      height: 40px;

      border: 0;

      background: transparent;

      cursor: pointer;
    }

    @include hsvs.view('toggleIconLine') {
      position: absolute;

      transition: transform 300ms;

      border-radius: var(--aza-expansion-panel-toggle-icon-line-border-radius);

      background-color: var(--aza-expansion-panel-color);

      @include hsvs.state((), (), [vertical]) {
        top: 25%;
        bottom: 25%;
        left: calc(45% + 1px);
        width: 2px;
      }

      @include hsvs.state([expanded]) {
        transform: rotate(90deg);
      }

      @include hsvs.state((), (), [horizontal]) {
        top: calc(45% + 1px);
        right: 25%;
        left: 25%;
        height: 2px;

        transition:
          transform 300ms,
          right 300ms,
          left 300ms;
      }

      @include hsvs.state([expanded], (), [horizontal]) {
        right: 50%;
        left: 50%;
      }
    }

    @include hsvs.view('contentWrapper') {
      overflow: hidden;

      transform-origin: top;
    }

    @include hsvs.view('content') {
      margin-top: var(--aza-expansion-panel-spacing);
    }
  }
}
