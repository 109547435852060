@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/functions';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'setupPage') {
  display: block;
  padding-top: 70px;
  padding-bottom: 44px;

  @include hsvs.view('container') {
    display: grid;
    grid-gap: 40px;
    grid-template-areas: 'setup-instructions' 'setup';
    grid-template-columns: minmax(0, 1fr);

    @include mixins.media-breakpoint-up('xxxl') {
      grid-template-areas: 'setup setup-instructions';
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @include hsvs.view('setup') {
    grid-area: setup;
  }

  @include hsvs.view('setupSection') {
    margin-bottom: 40px;
    padding-bottom: 40px;

    border-bottom: 1px solid var(--aza-base-03-alpha-color);
  }

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    margin: 0 0 22px;

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m');

    margin: 0.5em 0;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('currentAccountListContainer') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    gap: 25px;
  }

  @include hsvs.view('setupInstructions') {
    grid-area: setup-instructions;
  }

  @include hsvs.view('setupInstructionsVideoContainer') {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: functions.get-height-percentage-from-resolution(1920, 1080);
    overflow: hidden;

    border-radius: 5px;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
