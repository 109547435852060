@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'textLabel') {
  @include typography.font('body-s');
  display: inline-block;
  padding: 7px 17px;

  border-radius: 2px;

  background-color: var(--aza-text-highlight-alpha-color);

  color: var(--aza-text-01-color);
}
