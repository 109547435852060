@use 'sass:math';
@use '../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'logo') {
  display: inline-block;

  @include hsvs.state([size small]) {
    width: 117px;
  }

  @include hsvs.state([size medium]) {
    width: 148px;
  }

  @include hsvs.state([size large]) {
    width: 236px;
  }

  @include hsvs.state([size extra-large]) {
    width: 400px;
  }

  @include hsvs.view('container') {
    position: relative;
    padding-top: math.div(42, 148) * 100%;
  }

  @include hsvs.view('logo') {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    color: var(--aza-text-01-color);
  }
}
