@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'classicTriviaEditHeader') {
  display: block;

  @include hsvs.view('link') {
    @extend %aza-dialog-link;

    margin-right: auto;
  }

  @include hsvs.view('settings') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 15px;
    gap: 30px 50px;
  }

  @include hsvs.view('title') {
    @include typography.font('h6', 'semi-bold');

    margin: 0;
  }

  @include hsvs.view('sliderTitle') {
    @include typography.font('body-s', 'light');

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('btnReset') {
    margin: 0;
    padding: 0;

    border: 0;
    outline: 0;

    background: none;

    color: white;

    cursor: pointer;
  }

  @include hsvs.view('questionSettings') {
    display: grid;
    gap: 20px;
  }

  @include hsvs.view('actin') {
    display: grid;
    align-items: start;
  }

  @include hsvs.view('checkWrapper') {
    margin-top: 20px;
  }
}
