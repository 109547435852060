@use '../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../core/src/styles/typography';

@use '../../../../styles/abstracts/base-classes/placeholder-loaders';
@use '../../../../styles/abstracts/base-classes/shop-card';
@use '../../../../styles/abstracts/variables';
@use '../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'shopCardStub') {
  @extend %shop-card-layout;
  @include typography.font(body-m, semi-bold);

  min-height: 586px;

  @include hsvs.view('poster') {
    @extend %shop-card-poster;
    background-color: var(--aza-card-stub-alpha-color);
  }

  @include hsvs.view('descriptionSection') {
    @extend %shop-card-description-section;
  }

  @include hsvs.view('nameStub') {
    @extend %placeholder-loader-stub-line;
    height: 48px;
  }

  @include hsvs.view('metadataStubGroup') {
    margin: auto 0 7px;
  }

  @include hsvs.view('platformStub') {
    @extend %placeholder-loader-stub-line;
    width: 75%;
    height: 20px;
    margin-top: 8px;
  }

  @include hsvs.view('regionsStub') {
    @extend %placeholder-loader-stub-line;
    width: 60%;
    height: 20px;
    margin-top: 8px;
  }

  @include hsvs.view('controlsStubGroup') {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr) minmax(0, 1fr);
    align-items: center;
    gap: 8px;
  }

  @include hsvs.view('azaStub') {
    @extend %placeholder-loader-stub-circle;
    width: 20px;
    height: 20px;
  }

  @include hsvs.view('priceStub') {
    @extend %placeholder-loader-stub-line;
    width: 90%;
    height: 20px;
  }

  @include hsvs.view('ctaStub') {
    @extend %placeholder-loader-stub-line;
    width: 70%;
    height: 36px;
    justify-self: flex-end;
  }
}
