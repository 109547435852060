@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'buttonReveal') {
  display: grid;

  @include hsvs.view('available') {
    grid-area: 1/1 / 2/2;
  }

  @include hsvs.view('curtain') {
    grid-area: 1/1 / 2/2;
  }

  @include hsvs.view('codeContainer') {
    display: flex;
    grid-area: 1/1 / 2/2;
    align-items: center;
    overflow: hidden;
    column-gap: 5px;

    font-family: monospace;

    @include typography.font('body-s');
  }

  @include hsvs.view('btnCopyCode') {
    margin: 0;
    padding: 0;
    padding: 5px;

    border: none;

    background: none;

    color: var(--aza-accent-color);

    cursor: pointer;
  }

  @include hsvs.view('code') {
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include hsvs.view('copyIcon') {
    color: var(--aza-accent-color);
  }
}
