@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/base-classes/shop-card';

@include hsvs.host('aza', 'purchaseCard') {
  @extend %shop-card-layout;

  transition: 0.3s box-shadow;

  @include hsvs.state([new]) {
    box-shadow: 0 0 0 3px var(--aza-success-color);
  }

  @include hsvs.view('imageContainer') {
    @extend %shop-card-poster;
  }

  @include hsvs.view('imageBack') {
    @extend %shop-card-poster-back;
  }

  @include hsvs.view('posterImage') {
    @extend %shop-card-poster-image;
  }

  @include hsvs.view('description') {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px 12px 12px;
  }

  @include hsvs.view('itemName') {
    @include typography.font('body-m', 'semi-bold');

    flex: 1;
  }

  @include hsvs.view('purchaseMetadata') {
    @include typography.font('body-xs');

    margin: 20px 0;
  }

  @include hsvs.view('purchased') {
    margin-bottom: 2px;
  }

  @include hsvs.view('thumbnail') {
    color: var(--aza-text-01-color);
    @include typography.font('body-m', 'semi-bold');
  }

  @include hsvs.view('pendingText') {
    @include typography.font('body-xxs', 'semi-bold');
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 68px;
    padding: 0 10px;

    border-radius: 5px;

    background-color: var(--aza-top-bar-not-logged-in-alpha-color);

    color: var(--aza-text-02-color);
    line-height: 16px;
    text-align: center;
  }

  @include hsvs.view('pendingTextHead') {
    @include typography.font('body-l', 'semi-bold');
  }
}
