@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';

%_member-origin {
  @include typography.font('body-xs');
  margin-top: 22px;
}

%_member-name {
  @include typography.font('body-xl', 'semi-bold');
}

@include hsvs.host('aza', 'aboutAzarusPageCompany') {
  display: block;

  @include hsvs.view('blockTitle') {
    @include typography.font('h2-mobile', 'semi-bold');
    margin: 0 0 30px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h2', 'semi-bold');
      margin: 0 0 24px;
    }
  }

  @include hsvs.view('foundersAndAdvisorsContainer') {
    display: flex;
    grid-gap: 90px;
    flex-wrap: wrap;
  }

  @include hsvs.view('foundersWrapper') {
    flex: 0;
    max-width: 100%;
  }

  @include hsvs.view('advisorsWrapper') {
    flex: 1;
    max-width: 100%;

    @include mixins.media-breakpoint-up(lg) {
      min-width: 700px;
    }
  }

  @include hsvs.view('foundersContainer') {
    display: flex;
    max-width: 100%;
    overflow: auto;
    gap: 50px;
  }

  @include hsvs.view('founderImageContainerWrapper') {
    width: 210px;
  }

  @include hsvs.view('founderImageContainer') {
    @include mixins.fixed-ratio-image-container(1, 1);

    border-radius: 10px;
  }

  @include hsvs.view('founderTitle') {
    @extend %_member-origin;
  }

  @include hsvs.view('founderName') {
    @extend %_member-name;
  }

  @include hsvs.view('companyGoal') {
    @include typography.font('body-m', 'light');
    margin-top: 40px;
  }

  @include hsvs.view('advisorsContainer') {
    display: flex;
    max-width: 100%;
    overflow: auto;
    gap: 25px;

    @include mixins.media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(auto-fit, 210px);
      overflow: visible;
      gap: 60px 35px;
    }
  }

  @include hsvs.view('advisorImageContainerWrapper') {
    width: 210px;

    @include mixins.media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  @include hsvs.view('advisorImageContainer') {
    @include mixins.fixed-ratio-image-container(1, 1);

    border-radius: 50%;
  }

  @include hsvs.view('advisorOrigin') {
    @extend %_member-origin;
    text-align: center;
  }

  @include hsvs.view('advisorName') {
    @extend %_member-name;
    text-align: center;
  }

  @include hsvs.view('investorsContainer') {
    margin: 90px 0;

    @include mixins.media-breakpoint-up(lg) {
      margin: 120px 0;
    }
  }

  @include hsvs.view('investors') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    gap: 34px 20px;

    @include mixins.media-breakpoint-up(lg) {
      flex-wrap: wrap;
      justify-content: center;
      overflow: visible;
    }

    @include mixins.media-breakpoint-up(xxxl) {
      justify-content: space-between;
    }
  }

  @include hsvs.view('investorsTitle') {
    @include typography.font('h2-mobile', 'semi-bold');
    margin: 0 0 30px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h2', 'semi-bold');
      margin: 0 0 40px;

      text-align: center;
    }

    @include mixins.media-breakpoint-up(xxxl) {
      text-align: left;
    }
  }

  @include hsvs.view('investorImage') {
    border-radius: 10px;
  }

  @include hsvs.view('angelsContainer') {
    margin: 90px 0;

    @include mixins.media-breakpoint-up(lg) {
      margin: 120px 0;
    }
  }

  @include hsvs.view('angelsTitle') {
    @include typography.font('h2-mobile', 'semi-bold');
    margin: 0 0 30px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h2', 'semi-bold');
      margin: 0 0 40px;
    }
  }

  @include hsvs.view('angels') {
    display: flex;
    max-width: 100%;
    overflow: auto;
    gap: 25px;

    @include mixins.media-breakpoint-up(xxl) {
      gap: 60px;
    }

    @include mixins.media-breakpoint-up(xxxl) {
      gap: 100px;
    }
  }

  @include hsvs.view('angel') {
    flex-shrink: 0;
    width: 210px;

    @include mixins.media-breakpoint-up(lg) {
      width: 170px;
    }

    @include mixins.media-breakpoint-up(xl) {
      width: 210px;
    }
  }

  @include hsvs.view('angelImageContainer') {
    @include mixins.fixed-ratio-image-container(1, 1);

    border-radius: 50%;
  }

  @include hsvs.view('angelOrigin') {
    @extend %_member-origin;
    display: block;
    max-width: 100%;
    height: 32px;
    overflow: hidden;

    text-align: center;
  }

  @include hsvs.view('angelName') {
    @extend %_member-name;
    text-align: center;
  }
}
