@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

%exchangeLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 14px;

  @include mixins.media-breakpoint-up('xl') {
    width: max-content;
  }
}

@include hsvs.host('aza', 'getAzaCoinSection') {
  display: block;
  margin-top: 120px;

  $top-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(0, 102, 255, 0.2),
        rgba(0, 102, 255, 0)
      ),
    size: 639px 530px,
    offset-x: 700px,
    offset-y: 0px,
  );
  $bottom-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(204, 0, 177, 0.3),
        rgba(204, 0, 176, 0)
      ),
    size: 680px 480px,
    offset-x: 700px,
    offset-y: 0px,
  );
  $gradient-inflation: 200px;

  @include mixins.gradient-background(
    $top-gradient,
    $bottom-gradient,
    calc(50%),
    calc(25%),
    $gradient-inflation,
    0.75
  );

  @include hsvs.view('sectionTitle') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0;
    margin-bottom: 22px;

    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('sectionSabTitle') {
    max-width: 500px;
    margin: 0 auto 54px auto;

    text-align: center;
  }

  @include hsvs.view('cardList') {
    display: grid;
    grid-template-columns: 1fr;

    gap: 40px;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mixins.media-breakpoint-up('xl') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include hsvs.view('card') {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    border-radius: 10px;

    background-color: var(--aza-base-02-color);
  }

  @include hsvs.view('cardHeader') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 140px;
    margin-bottom: 24px;
    padding: 0 16px;

    background: linear-gradient(
      90deg,
      rgba(69, 33, 112, 0.8) 2.41%,
      rgba(69, 33, 112, 0) 83.33%
    );

    @include mixins.media-breakpoint-up('md') {
      margin-bottom: 56px;
      padding: 0 46px;
    }
  }

  @include hsvs.view('main') {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 16px 16px 16px;

    @include mixins.media-breakpoint-up('md') {
      padding: 0 46px 46px 46px;
    }
  }

  @include hsvs.view('cardTitle') {
    @include typography.font('h4-mobile', 'semi-bold');

    margin: 0;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('cardSabTitle') {
    @include typography.font('body-s', 'semi-bold');

    margin: 0;

    @include mixins.media-breakpoint-up('xl') {
      @include typography.font('body-m', 'semi-bold');
    }
  }

  @include hsvs.view('linkText') {
    padding-left: 15px;

    white-space: nowrap;
  }

  @include hsvs.view('icon') {
    max-width: 60px;
  }

  @include hsvs.view('exchangeList') {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px 134px;

    flex: 1;

    @include mixins.media-breakpoint-up('xl') {
      display: flex;
    }
  }

  @include hsvs.view('exchangeItem') {
    position: relative;
    flex-grow: 1;

    @include hsvs.state((), (), (':not(:first-child)::before')) {
      content: '';

      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: 1px;

      background-color: var(--aza-base-03-alpha-color);
    }

    @include mixins.media-breakpoint-up('xl') {
      @include hsvs.state((), (), (':not(:first-child)::before')) {
        content: '';

        position: absolute;
        top: 0;
        left: -68px;
        width: 1px;
        height: 100%;

        background-color: var(--aza-base-03-alpha-color);
      }
    }
  }

  @include hsvs.view('exchangeLogo') {
    width: auto;
    max-width: 100%;
    height: 16.67vw;
    max-height: 60px;

    @include mixins.media-breakpoint-up('xl') {
      max-height: 86px;
    }
  }

  @include hsvs.view('exchangeLinkWrapper') {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
  }

  @include hsvs.view('exchangeLink') {
    @extend %exchangeLink;
  }

  @include hsvs.view('exchangeAction') {
    width: 100%;
    margin-top: 10px;
    margin-right: 14px;

    @include mixins.media-breakpoint-up('xl') {
      margin-top: 40px;
    }
  }

  @include hsvs.view('exchangeStatement') {
    @include typography.font('h2-mobile', 'semi-bold');

    line-height: min(16.67vw, 60px);

    @include mixins.media-breakpoint-up('xl') {
      @include typography.font('h2', 'semi-bold');
      line-height: 86px;
    }
  }

  @include hsvs.view('notifyLink') {
    @extend %exchangeLink;
    margin-top: 46px;
  }
}
