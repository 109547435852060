@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'store') {
  display: block;

  @extend %aza-container-responsive;

  @include hsvs.view('title') {
    margin: 0 0 55px 0;

    font-size: clamp(38px, 7vw, 50px);
    font-weight: 600;
    line-height: clamp(52px, 7vw, 60px);
    text-align: center;
  }

  @include hsvs.view('accept') {
    position: relative;

    color: var(--aza-accent-color);
    text-wrap: nowrap;
  }

  @include hsvs.view('action') {
    display: flex;
  }

  @include hsvs.view('link') {
    @extend %aza-link;

    margin-left: auto;
  }
}
