@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'confirmQuestionDeleteDialog') {
  display: block;

  @include hsvs.view('title') {
    @include typography.font('h5-mobile', 'semi-bold');

    margin-top: 0;
    margin-bottom: 30px;
  }

  @include hsvs.view('actionInfo') {
    @include typography.font('body-m', 'light');

    margin: 0;
  }

  @include hsvs.view('action') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 37px;
    gap: 40px;
  }
}
