@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaGreetingPopup') {
  display: block;

  @include hsvs.view('header') {
    @include typography.font('h2-mobile', 'semi-bold');
    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('imageContainer') {
    margin-top: 30px;
    @include mixins.fixed-ratio-image-container(596, 341);
  }

  @include hsvs.view('text') {
    @include typography.font('body-xxl', 'semi-bold');
    margin-top: 40px;

    color: var(--aza-text-02-color);
    text-align: center;
  }

  @include hsvs.view('socialLinksNav') {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    gap: 20px;
  }
}
