@use '../../../../cdk/src/hsvs/hsvs';

@keyframes aza-dropdown-slide-down {
  from {
    transform: translateY(-10px);

    opacity: 0;
  }

  to {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes aza-dropdown-slide-up {
  from {
    transform: translateY(10px);

    opacity: 0;
  }

  to {
    transform: translateY(0);

    opacity: 1;
  }
}

@mixin dropdown-base($encapsulated: false) {
  @include hsvs.host('aza', 'dropdown', $encapsulated) {
    display: block;

    @include hsvs.view('portal') {
      animation: aza-dropdown-slide-down 0.25s ease-out forwards;

      @include hsvs.state((), (), ([upwards])) {
        animation: aza-dropdown-slide-up 0.25s ease-out forwards;
      }
    }
  }
}
