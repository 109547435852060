@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/functions';

@include hsvs.host('aza', 'discordButton') {
  --discord-button-size: 70px;
  --discord-button-icon-size: 22px;

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--discord-button-size);
  height: var(--discord-button-size);

  transition: background-color 100ms;

  border-radius: 50%;

  background-color: #5865f2;
  box-shadow: 0 4px 10px 0 rgba(#000000, 0.7);

  color: var(--aza-light-stable-color);
  text-align: center;
  text-decoration: none;

  cursor: pointer;

  row-gap: 5px;

  @include hsvs.state((':hover')) {
    background-color: #717cf4;
  }

  @include hsvs.view('icon') {
    width: 100%;
    max-width: var(--discord-button-icon-size);
  }
}
