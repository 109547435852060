@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use 'functions';

@mixin media-breakpoint-up($name, $breakpoints) {
  $min: functions.breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin gradient-button($gradient-angle, $gradient-colors) {
  $multiplier: 3;

  @if list.length($gradient-colors) < $multiplier {
    @error 'Please provide at least #{$multiplier} gradient parts';
  }

  $gradient: null;
  @each $gradient-color in $gradient-colors {
    $gradient:
      $gradient,
      map.get($gradient-color, 'color')
        math.div(map.get($gradient-color, 'offset'), $multiplier);
  }
  background-color: transparent;
  background-image: linear-gradient($gradient-angle, $gradient);
  background-size: 100% * $multiplier 100% * $multiplier;
  background-position-x: 100%;

  &:not(:disabled):hover {
    $gradient-offset: math.div(
      map.get(list.nth($gradient-colors, 2), 'offset'),
      $multiplier
    );
    background-position-x: 100% - $gradient-offset;
  }

  &:not(:disabled):active {
    $gradient-offset: math.div(
      map.get(list.nth($gradient-colors, 3), 'offset'),
      $multiplier
    );
    background-position-x: 100% - $gradient-offset;
  }
}

@mixin gradient-background(
  $top-gradient,
  $bottom-gradient,
  $position-x: 50%,
  $position-y: 50%,
  $inflation: 0,
  $opacity: 1
) {
  $gradient-default-values: (
    offset-x: 0px,
    offset-y: 0px,
  );
  $top-gradient: map.merge($gradient-default-values, $top-gradient);
  $bottom-gradient: map.merge($gradient-default-values, $bottom-gradient);

  position: relative;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: -#{$inflation};
    bottom: -#{$inflation};
    left: 0;
    width: 100%;

    opacity: $opacity;
    background-image: map.get($top-gradient, 'gradient'),
      map.get($bottom-gradient, 'gradient');
    background-repeat: no-repeat;
    /* prettier-ignore */
    background-position:
      calc(#{$position-x} + #{map.get($top-gradient, 'offset-x')})
      calc(#{$position-y} + #{map.get($top-gradient, 'offset-y')}),
      calc(#{$position-x} + #{map.get($bottom-gradient, 'offset-x')})
      calc(#{$position-y} + #{map.get($bottom-gradient, 'offset-y')});
    background-size: map.get($top-gradient, 'size'),
      map.get($bottom-gradient, 'size');

    pointer-events: none;
    contain: strict;
  }
}

@mixin fixed-ratio-image-container(
  $width,
  $height,
  $objectFit: cover,
  $objectPosition: center
) {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: functions.get-height-percentage-from-resolution($width, $height);
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: $objectFit;
    object-position: $objectPosition;
  }
}

@mixin loading-indicator-background(
  $color-1: var(--aza-primary-color),
  $color-2: var(--aza-accent-color)
) {
  $part: math.div(100%, 8);

  animation-name: aza-loading-indicator;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  opacity: 0.2;
  background-image: repeating-linear-gradient(
    -45deg,
    $color-1 ($part * 0),
    $color-1 ($part * 1),
    $color-2 ($part * 1),
    $color-2 ($part * 3),
    $color-1 ($part * 3),
    $color-1 ($part * 5),
    $color-2 ($part * 5),
    $color-2 ($part * 7),
    $color-1 ($part * 7),
    $color-1 ($part * 8)
  );
  background-position: center center;
  background-size: 32px 32px;
}
