@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

%_title {
  @include typography.font('body-m', 'light');

  color: var(--aza-text-02-color);
}

@include hsvs.host('aza', 'azaBuyDialog') {
  display: block;
  padding: 24px 0;

  @include hsvs.view('header') {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    column-gap: 20px;
  }

  @include hsvs.view('controlContainer') {
    position: relative;
  }

  @include hsvs.view('errorContainer') {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;

    color: var(--aza-error-color);
    line-height: 1;

    row-gap: 10px;
  }

  @include hsvs.view('azaIcon') {
    --aza-core-aza-icon-size: 46px;
  }

  @include hsvs.view('headerTitle') {
    @include typography.font('h4-mobile', 'semi-bold');

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('cardTitle') {
    @extend %_title;

    margin: 0;
    margin-bottom: 14px;
  }

  @include hsvs.view('priceContainer') {
    display: flex;
    align-items: center;
    margin: 32px 0;
    column-gap: 18px;
  }

  @include hsvs.view('priceTitle') {
    @extend %_title;

    white-space: nowrap;
  }

  @include hsvs.view('price') {
    @include typography.font('body-l', 'semi-bold');
  }

  @include hsvs.view('fee') {
    @include typography.font('body-m');

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('priceLoader') {
    width: 28px;
  }

  @include hsvs.view('btnSubmit') {
    display: inline-flex;
    align-items: center;
    column-gap: 12px;
  }
}
