@mixin dark-theme {
  --aza-base-01-color: #1d1e20;
  --aza-base-02-color: #292b2d;
  --aza-top-bar-not-logged-in-alpha-color: rgba(41, 43, 45, 0.65);
  --aza-base-03-alpha-color: rgba(255, 255, 255, 0.16);
  --aza-base-03-hover-alpha-color: rgba(255, 255, 255, 0.24);

  --aza-text-01-color: #ffffff;
  --aza-text-02-color: #ced6ff;
  --aza-text-highlight-alpha-color: rgba(69, 33, 112, 0.8);
  --aza-text-highlight-hover-alpha-color: rgba(81, 39, 132, 0.8);

  --aza-primary-color: #5b24af;
  --aza-primary-inactive-hover-alpha-color: rgba(91, 36, 175, 0.4);
  --aza-primary-hover-color: #5519af;
  --aza-primary-active-color: #431b7e;

  --aza-accent-color: #ef3d57;
  --aza-accent-active-color: #c53449;

  --aza-link-normal-color: #9075ff;
  --aza-link-hover-color: #623aff;
  --aza-link-twitch-color: #9146ff;

  --aza-card-stub-alpha-color: rgba(255, 255, 255, 0.1);
  --aza-card-stub-glow-alpha-color: rgba(255, 255, 255, 0.2);
  --aza-card-bg-alpha-color: rgba(255, 255, 255, 0.08);

  --aza-inactive-alpha-color: rgba(195, 199, 219, 0.2);

  --aza-scroll-bar-alpha-color: rgba(255, 255, 255, 0.16);

  --aza-facebook-color: #1877f2;

  --aza-light-stable-color: #ffffff;

  --aza-error-color: #ff9900;
  --aza-error-active-color: #cb7b01;
  --aza-success-color: #27eac7;
}

@mixin light-theme {
  --aza-base-01-color: #efeff6;
  --aza-base-02-color: #ffffff;
  --aza-top-bar-not-logged-in-alpha-color: rgba(255, 255, 255, 1);
  --aza-base-03-alpha-color: rgba(223, 223, 234, 1);
  --aza-base-03-hover-alpha-color: rgba(204, 204, 227, 1);

  --aza-text-01-color: #0c003a;
  --aza-text-02-color: #36008e;
  --aza-text-highlight-alpha-color: rgba(171, 153, 222, 0.75);
  --aza-text-highlight-hover-alpha-color: rgba(209, 192, 255, 0.75);

  --aza-primary-color: #5b24af;
  --aza-primary-inactive-hover-alpha-color: rgba(91, 36, 175, 0.4);
  --aza-primary-hover-color: #5519af;
  --aza-primary-active-color: #431b7e;

  --aza-accent-color: #ef3d57;
  --aza-accent-active-color: #c53449;

  --aza-link-normal-color: #6038ff;
  --aza-link-hover-color: #330ad9;
  --aza-link-twitch-color: #9146ff;

  --aza-card-bg-alpha-color: rgba(255, 255, 255, 1);
  --aza-card-stub-glow-alpha-color: rgba(148, 144, 144, 0.5);
  --aza-card-stub-alpha-color: rgba(0, 0, 0, 0.1);

  --aza-inactive-alpha-color: rgba(195, 199, 219, 0.6);

  --aza-scroll-bar-alpha-color: rgba(200, 200, 224, 1);

  --aza-facebook-color: #1877f2;

  --aza-light-stable-color: #ffffff;

  --aza-error-color: #ff9900;
  --aza-error-active-color: #cb7b01;
  --aza-success-color: #00d0ab;
}
