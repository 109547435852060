@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'at-t') {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @include mixins.media-breakpoint-up('lg') {
    grid-template-columns: 274px 1fr 251px;
    gap: 40px;
  }

  @include hsvs.view('image') {
    display: flex;
    justify-content: center;
    height: 126px;
    overflow: hidden;

    border-radius: 7px;

    img {
      width: auto;
      height: 100%;
    }
  }

  @include hsvs.view('metadata') {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  @include hsvs.view('title') {
    @include typography.font('h6', 'semi-bold');
  }

  @include hsvs.view('duration') {
    color: var(--aza-text-02-color);
  }

  @include hsvs.view('description') {
    @include typography.font('body-m', 'light');

    margin-bottom: 0;
  }

  @include hsvs.view('action') {
    display: grid;
    align-items: center;

    a {
      text-align: center;
    }
  }

  @include hsvs.view('label') {
    display: inline-flex;
    margin-bottom: 10px;
    padding: 2px 6px;

    border-radius: 30px;

    background-color: var(--aza-text-01-color);

    color: var(--aza-base-01-color);

    @include typography.font('body-xs', 'semi-bold');
  }
}
