@use 'sass:map';

@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/variables';

@include hsvs.host(aza, shopPage) {
  display: block;
  position: relative;
  padding-top: 70px;

  @include hsvs.state(':before') {
    content: '';

    display: block;
    position: absolute;
    z-index: -1;
    top: -#{variables.$mobile-header-height};
    width: 100%;
    height: 412px;

    background-image: url(^assets/img/shop/shop-back-xl.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include mixins.media-breakpoint-up('lg') {
      top: -#{variables.$desktop-header-height};
    }

    @include mixins.media-breakpoint-up('xxxxl') {
      background-image: url(^assets/img/shop/shop-back-xxl.jpg);
    }
  }

  @include hsvs.view('offers') {
    margin-top: 40px;
  }

  @include hsvs.view('shopLayout') {
    overflow-anchor: none;

    min-height: 411px;
    margin-top: 0;
    margin-bottom: variables.$desktop-padding;

    @include mixins.media-breakpoint-up(lg) {
      margin-top: variables.$desktop-padding;
    }
  }

  @include hsvs.view('shopTitle') {
    @include typography.font('h2', 'semi-bold');

    margin: 0;
    margin-bottom: 30px;

    text-align: center;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h1', 'semi-bold');

      margin-bottom: 56px;
    }
  }

  @include hsvs.view('filterBar') {
    display: block;
    margin-top: 20px;
    margin-bottom: variables.$desktop-padding;

    @include mixins.media-breakpoint-up(lg) {
      margin-top: variables.$desktop-padding;
      margin-bottom: variables.$desktop-padding;
    }
  }

  @include hsvs.view('itemList') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(323px, 1fr));
    justify-content: center;
    column-gap: 20px;
  }
}
