@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'advertisePageContactsSection') {
  display: flex;
  flex-direction: column;
  max-width: 648px;
  gap: 2.5em;

  padding: 8em 0;

  @include hsvs.view('title') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);
    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-xl', 'light');

    margin: 0;

    color: var(--aza-text-02-color);
    text-align: center;
  }

  @include hsvs.view('form') {
    display: flex;
    flex-direction: column;
    gap: 20px;

    min-height: 348px;

    @include mixins.media-breakpoint-up('lg') {
      display: grid;
      grid-template-columns: 1fr 1fr;

      row-gap: 20px;

      column-gap: 10px;
    }
  }

  @include hsvs.view('field') {
    height: 56px;

    background-color: var(--aza-base-02-color);
  }

  @include hsvs.view('companyField') {
    height: 56px;

    background-color: var(--aza-base-02-color);

    @include mixins.media-breakpoint-up('lg') {
      grid-column-end: 3;
      grid-column-start: 1;
    }
  }

  @include hsvs.view('messageField') {
    --aza-field-background-color: var(--aza-base-03-alpha-color);
    --aza-field-background-color-hover: var(--aza-base-03-hover-alpha-color);
    display: flex;
    z-index: 1;
    grid-row-end: 8;
    grid-row-start: 6;
    flex-direction: column;
    height: 120px;
    padding: 1em 1em 0;

    border-radius: 12px;

    opacity: 1;
    background-color: var(--aza-base-02-color);

    &:focus-within {
      outline: 0.15em solid black;

      span {
        transform: translateY(-0.75em);
      }
    }

    @include hsvs.state(([disabled])) {
      opacity: 0.5;
    }

    @include hsvs.state([disabled], (), ':hover') {
      background-color: var(--aza-field-background-color);
    }

    @include mixins.media-breakpoint-up('lg') {
      grid-column-end: 3;
      grid-column-start: 1;
      grid-row-end: 6;
      grid-row-start: 4;
    }
  }

  @include hsvs.view('messageLabel') {
    position: absolute;
    margin-bottom: auto;
    overflow: hidden;

    transition: transform 0.1s;

    opacity: 0.7;

    color: var(--aza-text-01-color);
    font-size: 0.875em;
    font-weight: typography.font-weight('light');
    line-height: 1.25em;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include hsvs.state([labelUp]) {
      transform: translateY(-0.75em);
    }
  }

  @include hsvs.view('messageArea') {
    min-width: 100%;
    max-width: 100%;
    min-height: 90%;
    max-height: 90%;
    margin: 0.25em 0 0;
    padding: 0;

    transition: transform 0.1s;

    border: 0;

    background: transparent;

    color: var(--aza-text-01-color);
    font-size: 1em;
    line-height: 1.5em;

    &:focus {
      outline: none;
    }
  }

  @include hsvs.view('buttonField') {
    display: flex;
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-start: 8;
    flex-direction: column;
    align-items: center;

    @include mixins.media-breakpoint-up(lg) {
      grid-row-start: 6;
      padding-top: 2em;
    }
  }

  @include hsvs.view('submitLink') {
    width: 100%;
    max-width: 366px;
  }
}
