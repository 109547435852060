@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'addAzaCoinsDialog') {
  @include hsvs.view('header') {
    display: flex;
    gap: 20px;

    align-items: center;
  }

  @include hsvs.view('headerText') {
    @include typography.font('h4-mobile', 'semi-bold');
    margin: 0;

    @include mixins.media-breakpoint-up(md) {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('azaIcon') {
    --aza-core-aza-icon-size: 46px;
  }

  @include hsvs.view('subText') {
    @include typography.font('body-m', 'light');
    margin: 40px 0 0;
  }

  @include hsvs.view('methods') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 30px;

    margin-top: 20px;
  }
}
