@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../../../core/src/styles/themes' as themes;

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaContactUsPopup') {
  @include themes.light-theme();

  display: flex;
  flex-direction: column;
  align-items: center;

  @include hsvs.view('image') {
    width: 140px;
    height: 140px;
    padding-top: 2em;
  }

  @include hsvs.view('header') {
    @include typography.font('h6-mobile', 'semi-bold');

    max-width: 473px;

    color: var(--aza-text-01-color);
    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-xl', 'regular');

    max-width: 473px;

    color: var(--aza-text-02-color);
    text-align: center;
  }

  @include hsvs.view('contactsDescription') {
    @include typography.font('body-xl', 'semi-bold');

    max-width: 473px;

    color: var(--aza-text-01-color);
    text-align: center;
  }

  @include hsvs.view('socialLinksNav') {
    display: flex;

    gap: 20px;
  }
}
