@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'dialogDeleteConfirmation') {
  display: block;

  color: var(--aza-text-01-color);
  text-align: center;

  @include hsvs.view('title') {
    @include typography.font('h4-mobile', 'semi-bold');
    margin: 21px 0 28px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h4', 'semi-bold');
      margin: 35px 0 38px;
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-l', 'regular');
    margin-bottom: 30px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('body-xl', 'regular');
      margin-bottom: 40px;
    }
  }

  @include hsvs.view('image') {
    width: 100px;
    height: 100px;

    @include mixins.media-breakpoint-up(lg) {
      width: 140px;
      height: 140px;
    }
  }

  @include hsvs.view('btn') {
    margin-bottom: 40px;
    padding: 12px 40px;

    @include mixins.media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }
}
