@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/nav-sidebar';
@use '../../../styles/abstracts/base-classes/header';

@include hsvs.host('aza', 'streamerDashboard') {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @include hsvs.view('header') {
    @extend %aza-header;
  }

  @include hsvs.view('main') {
    @extend %nav-sidebar-container;
  }

  @include hsvs.view('sidebar') {
    @extend %nav-sidebar;
  }

  @include hsvs.view('navItemContent') {
    @extend %nav-item-container;
  }

  @include hsvs.view('externalIcon') {
    display: none;

    @include mixins.media-breakpoint-up('xxl') {
      display: inline-block;
    }
  }
}
