@use '../functions';

%aza-header {
  position: fixed;
  z-index: functions.z-index('header');
  top: 0;
  width: 100%;
}

%aza-header-transparent {
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(41, 43, 45, 0.65);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  }
}
