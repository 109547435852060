@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'verticalMenuItemExternal') {
  @include typography.font('body-m');

  display: block;

  @include hsvs.view('link') {
    @extend %aza-vertical-nav-item-spacing;

    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    transition: color 150ms;

    background-color: transparent;

    color: var(--aza-text-02-color);
    text-decoration: none;

    @include hsvs.state((), (), ':hover') {
      color: var(--aza-link-normal-color);
    }
  }

  @include hsvs.view('content') {
    display: flex;
    align-items: center;
    overflow: hidden;
    column-gap: 12px;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include hsvs.view('contentWrapper') {
    margin-left: auto;
  }
}
