@use '../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'feedCard') {
  display: block;
  overflow: hidden;

  border-radius: 10px;

  background-color: var(--aza-base-02-color);
}
