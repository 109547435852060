@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'classicTriviaPartyGameQuestionState') {
  display: grid;
  gap: 10px;

  @include hsvs.view('gameItem') {
    display: flex;
    align-items: center;
    gap: 10px;

    min-height: 38px;
  }

  @include hsvs.view('gameItemTitle') {
    @include typography.font('body-m');

    transition: color 100ms;

    text-wrap: nowrap;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-l');
    }

    @include hsvs.state((), (), ([primary])) {
      @include typography.font('body-xl', 'semi-bold');

      color: var(--aza-success-color);

      @include mixins.media-breakpoint-up('lg') {
        @include typography.font('body-xxl', 'semi-bold');
      }
    }
  }

  @include hsvs.view('gameItemTitleElement') {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 10px;
  }
}
