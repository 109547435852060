@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'paypalValue') {
  display: inline-flex;
  align-items: center;
  column-gap: 18px;

  color: var(--aza-text-01-color);

  @include hsvs.view('paypalValue') {
    @include typography.font('body-m', 'semi-bold');
  }
}
