@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

%_buttonState {
  transform: translateY(-3px);

  box-shadow: 2px 3px 0 0 var(--aza-base-01-color);
}

@include hsvs.host('aza', 'mocaButton') {
  @include typography.font('body-s', 'semi-bold');

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px;
  column-gap: 8px;

  transform: translateY(-5px);
  transition-property: box-shadow, transform;
  transition-duration: 150ms;

  border: 1px solid var(--aza-base-01-color);
  border-radius: 40px;
  outline: none;

  background-color: var(--aza-light-stable-color);
  box-shadow: 3px 5px 0 0 var(--aza-base-01-color);

  color: var(--aza-base-01-color);
  text-decoration: none;
  text-transform: capitalize;

  cursor: pointer;

  @include hsvs.state(':hover') {
    @extend %_buttonState;
  }

  @include hsvs.state(':active') {
    transform: translateY(0);

    box-shadow: 0 0 0 0 var(--aza-base-01-color);
  }

  @include hsvs.state((':disabled')) {
    @extend %_buttonState;

    filter: grayscale(100%);
  }
}
