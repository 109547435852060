@use '../../../../../../cdk/src/hsvs/hsvs';

@use '../../../../styles/typography';

@mixin field-input-base($encapsulated: false) {
  @include hsvs.host('aza', 'fieldInput', $encapsulated) {
    width: 100%;
    margin-top: 1em;
    padding: 0;

    border: 0;
    outline: none;

    background: none;

    color: var(--aza-text-01-color);
    font-size: 1em;
    line-height: 1.5em;

    &::placeholder {
      opacity: 0.7;

      color: var(--aza-text-01-color);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
      margin: 0;
    }

    @include hsvs.state((':not(:focus)')) {
      &::placeholder {
        color: transparent;
      }
    }
  }
}
