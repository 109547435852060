@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../../../core/src/styles/base-classes/forms';
@use '../../../../../core/src/styles/base-classes/links';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'transferToCryptoAddressDialog') {
  display: block;

  @include hsvs.view('title') {
    @include typography.font('h4-mobile', 'semi-bold');

    display: flex;
    align-items: center;
    gap: 22px;

    margin-bottom: 40px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m', 'light');
  }

  @include hsvs.view('form') {
    margin-top: 28px;
  }

  @include hsvs.view('field') {
    margin: 26px 0;
  }

  @include hsvs.view('fieldError') {
    @extend %aza-form-error;
  }

  @include hsvs.view('formActions') {
    margin-top: 30px;
  }

  @include hsvs.view('submit') {
    @include hsvs.state((), (), [ transferAction]) {
      min-width: 214px;
    }

    @include hsvs.state((), (), [ codeAction]) {
      min-width: 186px;
    }

    @include hsvs.state((), (), [ loading]) {
      @include mixins.loading-indicator-background();
    }
  }

  @include hsvs.view('note') {
    @include typography.font('body-xs');
    color: var(--aza-text-02-color);
  }

  @include hsvs.view('strong') {
    font-weight: typography.font-weight('semi-bold');
  }

  @include hsvs.view('codeFormContent') {
    display: grid;
    grid-template-areas:
      'codeInput codeInput'
      'codeFormActions .'
      'retryText .';
    grid-template-columns: repeat(2, minmax(0, max-content));
    align-items: center;
    margin-top: 28px;
    gap: 16px 25px;

    @include mixins.media-breakpoint-up('sm') {
      grid-template-areas:
        'codeInput codeInput'
        'codeFormActions retryText';
    }

    @include mixins.media-breakpoint-up('md') {
      grid-template-areas:
        'codeInput codeFormActions'
        'retryText retryText';
    }
  }

  @include hsvs.view('codeInput') {
    grid-area: codeInput;

    @include mixins.media-breakpoint-up('sm') {
      font-size: 18.6px;
    }
  }

  @include hsvs.view('codeFormActions') {
    grid-area: codeFormActions;

    @include mixins.media-breakpoint-up('sm') {
      font-size: 18.6px;
    }
  }

  @include hsvs.view('retryText') {
    @include typography.font('body-m');
    grid-area: retryText;

    @include mixins.media-breakpoint-up('md') {
      text-align: right;
    }
  }

  @include hsvs.view('azaField') {
    position: relative;
  }

  @include hsvs.view('availableTransferDescription') {
    @include typography.font('body-m', 'light');
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 26px 0 15px;
    gap: 6px;
  }

  @include hsvs.view('availableAza') {
    font-weight: typography.font-weight('semi-bold');
  }

  @include hsvs.view('azaLoader') {
    width: 20px;
  }

  @include hsvs.view('linkButton') {
    @extend %aza-link-button;
  }

  @include hsvs.view('failureMessage') {
    @include typography.font('body-s', 'semi-bold');
    margin-top: 10px;

    color: var(--aza-error-color);
  }

  @include hsvs.view('transferDetails') {
    display: flex;
    flex-direction: column;
    margin: 10px 0 25px;
    gap: 8px;
  }

  @include hsvs.view('divider') {
    width: 100%;
    margin: 0;

    border: 0 solid var(--aza-base-03-alpha-color);
    border-top-width: 1px;
  }

  @include hsvs.view('transferDetailsNote') {
    margin-top: 16px;
  }
}
