@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'acceptTos') {
  display: block;

  @include hsvs.view('tosContainer') {
    max-width: 324px;
    margin: 0 auto;
  }

  @include hsvs.view('description') {
    @include typography.font('body-l', 'semi-bold');
    margin: 40px 0 30px;
  }

  @include hsvs.view('tosInfo') {
    @include typography.font('body-s');

    margin: 30px 0;
  }

  @include hsvs.view('acceptTosContainer') {
    margin: 30px 0;
  }

  @include hsvs.view('acceptTosButton') {
    min-width: 240px;

    @include hsvs.state((), (), (':disabled')) {
      @include mixins.loading-indicator-background();
    }
  }

  @include hsvs.view('icon') {
    display: inline-block;

    color: var(--aza-link-normal-color);
    --aza-svg-icon-size: 18px;
    vertical-align: sub;
  }
}
