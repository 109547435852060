@use '../../../../../../../cdk/src/hsvs/hsvs';

@use '../../../../../styles/abstracts/functions';
@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'shopBanners') {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  gap: 20px;

  @include hsvs.view('banner') {
    overflow: hidden;
    isolation: isolate;

    border-radius: 15px;
  }

  @include hsvs.view('bannerImage') {
    width: 100%;
    height: 100%;

    transition: 0.3s transform ease;
    object-fit: cover;

    @include hsvs.state(
      (),
      (
        banner: ':hover',
      )
    ) {
      transform: scale(1.1);
    }
  }
}
