@use 'sass:math';

@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'streamerPageHero') {
  display: block;

  @include hsvs.view('content') {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 60px;

    align-items: center;
    padding: 56px 0;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-columns: 1fr 1fr;
      gap: 60px;
    }
  }

  @include hsvs.view('text') {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    gap: 30px 50px;

    @include mixins.media-breakpoint-up('lg') {
      max-width: 700px;
    }
  }

  @include hsvs.view('title') {
    @include typography.font('h1-mobile', 'semi-bold');

    margin: 25px 0;

    word-break: break-word;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h1', 'semi-bold');
      margin: 30px 0;
    }

    span {
      color: var(--aza-accent-color);
      white-space: nowrap;
    }
  }

  @include hsvs.view('subtitle') {
    @include typography.font('body-xl', 'regular');

    margin: 25px 0 30px;

    color: var(--aza-text-02-color);

    @include mixins.media-breakpoint-up('lg') {
      margin: 30px 0 60px;
    }
  }

  @include hsvs.view('buttonsContainer') {
    min-height: 56px;

    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      margin: 30px 0 60px;

      text-align: left;
    }
  }

  @include hsvs.view('imageWrapper') {
    width: 100%;
    min-width: 0;
    max-width: 612px;
    margin: auto;

    @include mixins.media-breakpoint-up('lg') {
      margin-right: 0;
      margin-left: auto;
    }
  }

  @include hsvs.view('imageContainer') {
    @include mixins.fixed-ratio-image-container(612, 611, contain);
  }

  @include hsvs.view('button') {
    width: 100%;
    @include mixins.media-breakpoint-up('lg') {
      width: fit-content;
      padding: 16px 40px;
    }
  }

  @include hsvs.view('mobileContainer') {
    display: block;

    @include mixins.media-breakpoint-up('lg') {
      display: none;
    }
  }

  @include hsvs.view('desktopContainer') {
    display: none;
    @include mixins.media-breakpoint-up('lg') {
      display: block;
    }
  }
}
