@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'userCurrencies') {
  @include typography.font('body-xxl', 'semi-bold');

  display: flex;
  align-items: center;
  gap: 12px;

  @include hsvs.view('azaIcon') {
    --aza-core-aza-icon-size: 30px;
  }

  @include hsvs.view('usdBalance') {
    display: flex;
    align-items: center;

    color: var(--aza-success-color);
  }

  @include hsvs.view('divider') {
    display: block;
    width: 1px;
    height: 24px;

    background-color: var(--aza-base-03-alpha-color);
  }
}
