@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../../../core/src/styles/typography';
@use '../../../../../core/src/styles/base-classes/links';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/news-post';

@include hsvs.host('aza', 'newsPostCard') {
  display: block;
  overflow: hidden;

  border-radius: 10px;

  background-color: var(--aza-card-bg-alpha-color);

  @include hsvs.view('link') {
    color: var(--aza-text-01-color);
    text-decoration: none;
  }

  @include hsvs.view('imageContainer') {
    @extend %aza-news-post-image;
  }

  @include hsvs.view('content') {
    padding: 20px 20px 30px;
  }

  @include hsvs.view('info') {
    @extend %aza-news-info;
  }

  @include hsvs.view('date') {
    @extend %aza-news-post-date;
  }

  @include hsvs.view('title') {
    @extend %aza-news-post-title;
  }

  @include hsvs.view('description') {
    @extend %aza-news-post-description;
  }

  @include hsvs.view('detailsLink') {
    @extend %aza-link;
    @include typography.font('body-s', 'semi-bold');

    display: flex;
    align-items: center;
    gap: 14px;
  }
}
