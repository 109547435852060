@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaTransferOptionsDialog') {
  display: block;

  @include hsvs.view('title') {
    @include typography.font('h4-mobile', 'semi-bold');

    display: flex;
    align-items: center;
    gap: 22px;

    margin-bottom: 38px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m', 'light');
  }

  @include hsvs.view('methods') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 30px;

    margin-top: 20px;
  }
}
