@use 'sass:map';

$default-font-families: 'Prompt', sans-serif;

$-sizes: (
  display: (
    font-size: 75px,
    line-height: 88px,
  ),
  display-mobile: (
    font-size: 68px,
    line-height: 78px,
  ),
  h1: (
    font-size: 65px,
    line-height: 76px,
  ),
  h1-mobile: (
    font-size: 50px,
    line-height: 58px,
  ),
  h2: (
    font-size: 50px,
    line-height: 60px,
  ),
  h2-mobile: (
    font-size: 38px,
    line-height: 52px,
  ),
  h3: (
    font-size: 44px,
    line-height: 50px,
  ),
  h3-mobile: (
    font-size: 34px,
    line-height: 49px,
  ),
  h4: (
    font-size: 36px,
    line-height: 42px,
  ),
  h4-mobile: (
    font-size: 30px,
    line-height: 38px,
  ),
  h5: (
    font-size: 30px,
    line-height: 38px,
  ),
  h5-mobile: (
    font-size: 26px,
    line-height: 32px,
  ),
  h6: (
    font-size: 26px,
    line-height: 34px,
  ),
  h6-mobile: (
    font-size: 22px,
    line-height: 32px,
  ),
  body-xxl: (
    font-size: 24px,
    line-height: 38px,
  ),
  body-xl: (
    font-size: 20px,
    line-height: 32px,
  ),
  body-l: (
    font-size: 18px,
    line-height: 28px,
  ),
  body-m: (
    font-size: 16px,
    line-height: 24px,
  ),
  body-s: (
    font-size: 14px,
    line-height: 20px,
  ),
  body-xs: (
    font-size: 12px,
    line-height: 16px,
  ),
  body-xxs: (
    font-size: 10px,
    line-height: 12px,
  ),
);

$-font-weights: (
  light: 300,
  regular: 400,
  semi-bold: 600,
  extra-bold: 800,
);

@function font-weight($font-weight-key) {
  $font-weight: map.get($-font-weights, $font-weight-key);

  @if ($font-weight == null) {
    @error "Font weight #{$font-weight} does not exist.";
  }

  @return $font-weight;
}

@function typography-size($typography-size-key) {
  $typography-size: map.get($-sizes, $typography-size-key);

  @if ($typography-size == null) {
    @error "Typography size #{$typography-size-key} does not exist.";
  }

  @return $typography-size;
}

@mixin font($size-key, $font-weight-key: 'regular') {
  $typography-size: typography-size($size-key);
  font-size: map.get($typography-size, 'font-size');
  font-weight: font-weight($font-weight-key);
  line-height: map.get($typography-size, 'line-height');
}
