@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'selectOption') {
  display: inline-flex;
  gap: 8px;

  @include mixins.media-breakpoint-up('lg') {
    gap: 20px;
  }
}
