@use '../../../../cdk/src/hsvs/hsvs';

@keyframes aza-loader_rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes aza-loader_dash {
  0% {
    stroke-dasharray: 3%, 280%;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 212%, 280%;
    stroke-dashoffset: -71%;
  }

  100% {
    stroke-dasharray: 212%, 280%;
    stroke-dashoffset: -280%;
  }
}

@mixin loader-base($encapsulated: false) {
  @include hsvs.host('aza', 'loader', $encapsulated) {
    display: block;

    @include hsvs.state([size small]) {
      width: 34px;
    }

    @include hsvs.state([size medium]) {
      width: 62px;
    }

    @include hsvs.state([size large]) {
      width: 90px;
    }

    @include hsvs.view('constraintContainer') {
      position: relative;
      height: 0;
      padding-top: 100%;
    }

    @include hsvs.view('spinner') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      animation: aza-loader_rotate 2s linear infinite;
    }

    @include hsvs.view('path') {
      animation: aza-loader_dash 1.5s ease-in-out infinite;

      stroke-linecap: round;
    }

    @include hsvs.view('sc1') {
      stop-color: var(--aza-loader-color-1, #90469f);
    }

    @include hsvs.view('sc2') {
      stop-color: var(--aza-loader-color-2, #f13886);
    }

    @include hsvs.view('sc3') {
      stop-color: var(--aza-loader-color-3, #ee3a57);
    }
  }
}
