@use '../../../../cdk/src/hsvs/hsvs';

@mixin svg-icon-base($encapsulated: false) {
  @include hsvs.host('aza', 'svgIcon', $encapsulated) {
    display: flex;
    width: var(--aza-svg-icon-size, 20px);
    min-width: var(--aza-svg-icon-size, 20px);
    height: var(--aza-svg-icon-size, 20px);
    min-height: var(--aza-svg-icon-size, 20px);

    color: var(--aza-svg-icon-color, currentColor);
  }
}
