@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

%_layout {
  display: grid;
  grid-template-rows: auto 1fr;
}

@include hsvs.host('aza', 'triviaGameSelectAnswer') {
  @extend %_layout;

  @include hsvs.view('title') {
    @include typography.font('body-xl', 'semi-bold');

    margin-top: 10px;
    margin-bottom: 30px;
  }

  @include hsvs.view('form') {
    display: flex;
    flex-direction: column;
  }

  @include hsvs.view('textarea') {
    @include typography.font('body-m');
  }

  @include hsvs.view('action') {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  @include hsvs.view('button') {
    min-width: 150px;
  }

  @include hsvs.view('btnBack') {
    @include typography.font('body-m', 'semi-bold');

    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    transition: color 150ms;

    border: none;
    outline: none;

    background: none;

    color: var(--aza-link-normal-color);

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      color: var(--aza-link-hover-color);
    }
  }
}
