@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'triviaGameHeader') {
  display: flex;
  gap: 12px 44px;

  flex-wrap: wrap;
  align-items: flex-start;

  @include hsvs.view('image') {
    width: 100%;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 274px;
    }
  }

  @include hsvs.view('game') {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }

  @include hsvs.view('details') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 9px;
  }

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h5-mobile', 'semi-bold');
    }
  }

  @include hsvs.view('gameStateTitle') {
    @include typography.font('body-m', 'light');

    color: var(--aza-text-02-color);
    text-wrap: nowrap;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-xl', 'light');
    }
  }

  @include hsvs.view('gameItemTitleElement') {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 10px;
  }

  @include hsvs.view('gameItem') {
    display: flex;
    align-items: center;
    min-height: 38px;
    column-gap: 16px;

    @include hsvs.state((), (), ([disabled])) {
      opacity: 0.4;
    }
  }

  @include hsvs.view('label') {
    @include typography.font('body-m', 'semi-bold');

    min-width: 60px;

    transition: background-color 100ms;

    background-color: var(--aza-text-01-color);

    color: var(--aza-base-01-color);

    @include hsvs.state((), (), ([primary])) {
      background-color: var(--aza-success-color);
    }
  }

  @include hsvs.view('gameItemTitle') {
    @include typography.font('body-m');

    transition: color 100ms;

    text-wrap: nowrap;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-l');
    }

    @include hsvs.state((), (), ([primary])) {
      @include typography.font('body-xl', 'semi-bold');

      color: var(--aza-success-color);

      @include mixins.media-breakpoint-up('lg') {
        @include typography.font('body-xxl', 'semi-bold');
      }
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m');

    margin: 0;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('addGameBtn') {
    @include typography.font('body-m', 'semi-bold');

    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    transition: color 150ms;

    border: none;
    outline: none;

    background: none;

    color: var(--aza-link-normal-color);

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      color: var(--aza-link-hover-color);
    }
  }

  @include hsvs.view('btnGameStart') {
    width: 100%;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 250px;
    }
  }

  @include hsvs.view('btnEmergency') {
    @include typography.font('body-xs', 'light');

    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 142px;

    border: none;

    background: none;

    color: var(--aza-text-02-color);
    text-align: left;

    cursor: pointer;
    gap: 5px;
  }

  @include hsvs.view('btnTitle') {
    @include typography.font('body-s', 'semi-bold');
  }
}
