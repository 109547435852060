@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'confirmGameStopDialog') {
  display: block;

  @include hsvs.view('header') {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 20px;
  }

  @include hsvs.view('title') {
    @include typography.font('h5-mobile', 'semi-bold');

    margin: 0;
  }

  @include hsvs.view('actionInfo') {
    @include typography.font('body-m', 'light');

    margin: 0;
  }

  @include hsvs.view('action') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 37px;
    gap: 40px;
  }

  @include hsvs.view('button') {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
  }

  @include hsvs.view('icon') {
    display: none;

    @include mixins.media-breakpoint-up('sm') {
      display: inline-block;
    }
  }
}
