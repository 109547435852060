@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'setupPageAccountList') {
  display: block;

  @include hsvs.view('accountListTitle') {
    @include typography.font('body-m');

    margin: 0 0 18px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('platformLabelList') {
    display: flex;
    margin-top: 40px;
    overflow: auto;
    column-gap: 32px;
  }

  @include hsvs.view('platformButton') {
    padding: 0;

    border: 0;

    background: none;

    cursor: pointer;
  }

  @include hsvs.view('platformLabel') {
    display: block;
    min-width: 119px;
    height: 44px;
  }

  @include hsvs.view('accountList') {
    margin-top: 40px;
  }

  @include hsvs.view('accountContainer') {
    display: flex;
    flex: 1;
    align-items: center;
    height: 46px;
    margin-bottom: 12px;
    overflow: hidden;
    column-gap: 27px;

    @include hsvs.state((), (), ':last-child') {
      margin-bottom: 0;
    }
  }

  @include hsvs.view('btnBin') {
    margin-left: auto;
  }
}
