@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/titles';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'purchasesPage') {
  display: block;
  padding-top: 40px;

  @include hsvs.view('purchasesContainer') {
    display: grid;
    grid-gap: 30px 20px;
    grid-template-columns: repeat(auto-fill, minmax(323px, 1fr));
    justify-content: center;
  }

  @include hsvs.view('emptyStateContainer') {
    @include typography.font('h6-mobile', 'semi-bold');
    justify-content: flex-start;
    margin-top: 18px;

    opacity: 0.5;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h6', 'semi-bold');
      margin-top: 55px;
    }
  }

  @include hsvs.view('emptyStateIcon') {
    --aza-svg-icon-size: 24px;
  }
}
