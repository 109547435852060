@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

%_element {
  visibility: hidden;
  z-index: 0;
  grid-area: 1/1 / 2/2;

  transition: opacity 150ms;

  opacity: 0;
}

%_element-active {
  visibility: visible;
  z-index: 1;

  opacity: 1;
}

@include hsvs.host('aza', 'triviaAddQuestion') {
  display: block;

  @include hsvs.view('title') {
    @include typography.font('h5-mobile', 'semi-bold');

    margin-top: 0;
    margin-bottom: 40px;
  }

  @include hsvs.view('wrapper') {
    display: grid;
  }

  @include hsvs.view('question') {
    @extend %_element;

    @include hsvs.state((), (), ([visible])) {
      @extend %_element-active;
    }
  }

  @include hsvs.view('selectAnswer') {
    @extend %_element;

    @include hsvs.state((), (), ([visible])) {
      @extend %_element-active;
    }
  }
}
