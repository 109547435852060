@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/variables';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/functions';

@include hsvs.host('aza', 'heroVideoSection') {
  display: grid;
  grid-template-rows: repeat(3, calc(100vh - var(--media-offset)));

  @include hsvs.view('sectionSticky') {
    display: grid;
    position: sticky;
    z-index: 2;
    top: 0;
    grid-area: 1 /1 / 2/2;
    grid-template-areas:
      'title'
      'video';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    overflow: hidden;
    gap: 70px 100px;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-areas: 'video title ';
      grid-template-columns: 1.5fr 1fr;
      grid-template-rows: 1fr;
    }
  }

  @include hsvs.view('sectionContent') {
    grid-area: 1/1 / 2/2;
  }

  @include hsvs.view('scrollTrigger') {
    @include hsvs.state((), (), ([first])) {
      grid-area: 1/1 / 2/2;
    }
  }

  @include hsvs.view('container') {
    width: 100%;
    margin: 0 auto;
    margin-bottom: auto;

    @include mixins.media-breakpoint-up('lg') {
      max-width: none;
      margin-bottom: 0;
    }
  }

  @include hsvs.view('videoContainer') {
    position: relative;
    grid-area: video;
    width: 100%;
    height: 0;
    padding-top: functions.get-height-percentage-from-resolution(600, 338);
    overflow: hidden;

    border-radius: 5px;

    background-color: var(--aza-base-01-color);
    isolation: isolate;
  }

  @include hsvs.view('video') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include hsvs.view('title') {
    grid-area: title;
    margin-top: auto;

    font-size: clamp(68px, 7vw, 75px);
    font-weight: 600;
    line-height: clamp(78px, 7vw, 88px);

    @include mixins.media-breakpoint-up('lg') {
      margin-top: 0;
    }
  }

  @include hsvs.view('accept') {
    position: relative;

    color: var(--aza-accent-color);
  }

  @include hsvs.view('underline') {
    position: absolute;
    right: 0;
    bottom: 5%;
    left: 0;
    width: 100%;
    height: 18px;

    will-change: transform;
  }
}
