@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host(aza, currencyDisplay) {
  display: flex;
  align-items: center;
  gap: 8px;

  @include hsvs.view('aza') {
    --aza-core-aza-icon-size: 20px;
  }

  @include hsvs.view(equalTo) {
    @include typography.font('body-xs', 'semi-bold');
    color: var(--aza-success-color);
  }
}
