@use 'sass:map';
@use 'sass:list';
@use 'sass:selector';
@use 'sass:meta';
@use 'sass:math';
@use 'sass:string';
@use '../util/util';

$-prefix: null;
$-host: null;
$-view: null;
$-inside-state: false;
$-encapsulated: false;

@function -normalize-native($state) {
  @return '#{$state}';
}

@function -normalize-variant($state) {
  $length: list.length($state);

  @if $length > 3 or $length == 0 {
    @error 'Wrong length in variant (#{$length})';
  }

  $key: list.nth($state, 1);

  @if meta.type-of($key) != string {
    @error 'Key must be string';
  }

  @if $length == 1 {
    @return [string.unquote($key)];
  }

  $value: list.nth($state, 2);

  @if meta.type-of($value) != string {
    @error 'Key must be string';
  }

  @return [string.unquote($key) string.unquote($value)];
}

@function -normalize-name($name) {
  @if meta.type-of($name) != string {
    @error 'Name must be string';
  }

  @return '#{$name}';
}

@function -normalize-parent-states($parent-states) {
  @if meta.type-of($parent-states) == list and list.length($parent-states) == 0
  {
    @return util.empty-map();
  }

  @if meta.type-of($parent-states) != map {
    @error '$parent-states must be map';
  }

  $result: util.empty-map();

  @each $parent, $states in $parent-states {
    $result: map.set(
      $result,
      -normalize-name($parent),
      -normalize-states($states)
    );
  }

  @return $result;
}
@function -normalize-states($states) {
  @if meta.type-of($states) == string {
    @return util.single-list(-normalize-native($states), $separator: space);
  }

  @if meta.type-of($states) != list {
    @error 'Wrong state type (#{meta.type-of($states)})';
  }

  @if list.is-bracketed($states) {
    @return util.single-list(-normalize-variant($states), $separator: space);
  }

  $result: ();

  @each $state in $states {
    @if meta.type-of($state) == string {
      $result: list.append(
        $result,
        -normalize-native($state),
        $separator: space
      );
    } @else if meta.type-of($state) == list {
      $result: list.append(
        $result,
        -normalize-variant($state),
        $separator: space
      );
    }
  }

  @return $result;
}

@function -get-host-selector($prefix, $host, $states) {
  $hostSelector: '.h_#{$prefix}_#{$host}';
  @if $-encapsulated == true {
    $hostSelector: ':host';
  }

  $selector: selector.append('#{$hostSelector}');

  @each $state in $states {
    @if meta.type-of($state) == string {
      $selector: selector.append($selector, $state);
    } @else {
      $key: list.nth($state, 1);

      @if list.length($state) == 1 {
        $selector: selector.append($selector, '.hs_#{$key}');
      } @else {
        $value: list.nth($state, 2);
        $selector: selector.append($selector, '.hs_#{$key}_#{$value}');
      }
    }
  }

  @return $selector;
}

@function -get-view-selector($prefix, $host, $view, $view-states) {
  $selector: selector.append('.hv_#{$prefix}_#{$host}', '.v_#{$view}');

  @each $state in $view-states {
    @if meta.type-of($state) == string {
      $selector: selector.append($selector, $state);
    } @else {
      $key: list.nth($state, 1);

      @if list.length($state) == 1 {
        $selector: selector.append($selector, '.vs_#{$key}');
      } @else {
        $value: list.nth($state, 2);
        $selector: selector.append($selector, '.vs_#{$key}_#{$value}');
      }
    }
  }

  @return $selector;
}

@mixin -host-state($prefix, $host, $states) {
  @at-root #{-get-host-selector($prefix, $host, $states)} {
    @content;
  }
}

@mixin -view-state(
  $prefix,
  $host-name,
  $host-states,
  $parent-map,
  $view-name,
  $view-states
) {
  $selector: -get-view-selector($prefix, $host-name, $view-name, $view-states);

  @each $parent-name, $parent-states in $parent-map {
    $selector: selector.nest(
      -get-view-selector($prefix, $host-name, $parent-name, $parent-states),
      $selector
    );
  }

  @if list.length($host-states) != 0 {
    $selector: selector.nest(
      -get-host-selector($prefix, $host-name, $host-states),
      $selector
    );
  }

  @at-root #{$selector} {
    @content;
  }
}

@mixin host($prefix, $host, $encapsulated: false) {
  @if $-host != null {
    @error 'Calling the host inside another host is not available';
  }

  @if $-inside-state {
    @error 'Calling the host inside state is not available';
  }

  $-prefix: -normalize-name($prefix) !global;
  $-host: -normalize-name($host) !global;
  $-encapsulated: $encapsulated !global;

  @include -host-state($-prefix, $-host, ()) {
    @content;
  }

  $-prefix: null !global;
  $-host: null !global;
}

@mixin view($view) {
  @if $-host == null {
    @error 'Calling the view outside of host is not available';
  }

  @if $-view != null {
    @error 'Calling the view inside another view is not available';
  }

  @if $-inside-state {
    @error 'Calling the view inside state is not available';
  }

  $-view: -normalize-name($view) !global;

  @include -view-state($-prefix, $-host, (), util.empty-map(), $-view, ()) {
    @content;
  }

  $-view: null !global;
}

@mixin state($host-states, $parent-states: (), $view-states: ()) {
  $host-states: -normalize-states($host-states);
  $parent-states: -normalize-parent-states($parent-states);
  $view-states: -normalize-states($view-states);

  @if $-host == null {
    @error 'Calling the state outside of host is not available';
  }

  @if $-inside-state {
    @error 'Calling the state inside another state is not available';
  }

  $-inside-state: true !global;

  @if $-view == null {
    @if list.length($parent-states) != 0 or list.length($view-states) != 0 {
      @error 'Cannot bind to state of children on host';
    }

    @include -host-state($-prefix, $-host, $host-states) {
      @content;
    }
  } @else {
    @include -view-state(
      $-prefix,
      $-host,
      $host-states,
      $parent-states,
      $-view,
      $view-states
    ) {
      @content;
    }
  }

  $-inside-state: false !global;
}
