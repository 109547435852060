@use 'sass:math';

@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/variables';

@include hsvs.host('aza', 'streamerPageEarn') {
  display: block;

  @include hsvs.view('content') {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 60px;

    align-items: center;
    padding: 56px 0;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-columns: 1fr 1fr;
      gap: 60px;
    }
  }

  @include hsvs.view('text') {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    gap: 30px 50px;

    @include mixins.media-breakpoint-up('lg') {
      max-width: 700px;
    }
  }

  @include hsvs.view('title') {
    @include typography.font('h1-mobile', 'semi-bold');

    margin: 25px 0;

    word-break: break-word;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h1', 'semi-bold');
      margin: 30px 0;
    }

    span {
      color: var(--aza-accent-color);
    }
  }

  @include hsvs.view('subtitle') {
    @include typography.font('body-xl', 'regular');

    margin: 25px 0 30px;

    color: var(--aza-text-02-color);

    @include mixins.media-breakpoint-up('lg') {
      margin: 30px 0 60px;
    }
  }

  @include hsvs.view('calculator') {
    margin: 0 calc(-1 * #{variables.$mobile-padding});

    @include mixins.media-breakpoint-up('sm') {
      margin: 0;

      border-radius: 10px;
    }
  }
}
