/* You can add global styles to this file, and also import other style files */
@use './styles/abstracts/variables';
@use './styles/abstracts/mixins';
@use './styles/abstracts/base-classes/common';

@use '../../core/src/styles/typography';
@use '../../core/src/styles/themes';

@use './styles/base/animations';
@use './styles/base/scrollbar';
@use './styles/base/links';
@use './styles/base/dialog';
@use './styles/base/markdown';
@use './styles/base/overlays';
@use 'app/app.module';

:root {
  --aza-container-responsive-width: #{variables.$container-responsive-width};
}

html {
  font-family: typography.$default-font-families;
  font-weight: 400;
  scroll-padding-top: variables.$mobile-header-height;

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    scroll-padding-top: variables.$desktop-header-height;
  }
}

body {
  @include themes.dark-theme;

  height: 100vh;
  margin: 0;

  background-color: var(--aza-base-01-color);

  color: var(--aza-text-01-color);
  -webkit-font-smoothing: antialiased;
}

input,
textarea,
button {
  font-family: inherit;
}

.__aza-container-fluid__ {
  @extend %aza-container-fluid;
}

.__aza-container__ {
  @extend %aza-container-responsive;
}

.__aza-text-with-icon__ {
  display: flex;
  align-items: center;
  gap: 14px;

  justify-content: center;
}
