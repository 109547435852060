@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaCoin') {
  display: block;
  padding-bottom: 140px;

  @include hsvs.view('heroSection') {
    @extend %aza-position-behind-header;

    background-image: url(^assets/img/coin-hero-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;

    @include mixins.media-breakpoint-up(xxxxl) {
      background-image: url(^assets/img/coin-hero-bg-xxxxl.svg);
      background-position: center;
    }
  }
}
