@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'profileMenu') {
  @include hsvs.view('trigger') {
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 0;

    border: none;

    background: none;

    color: inherit;
    font: inherit;

    cursor: pointer;
  }

  @include hsvs.view('chevron') {
    transform: rotateZ(0deg);
    transition: transform 200ms;

    @include hsvs.state((), (), ([opened])) {
      transform: rotateZ(180deg);
    }
  }

  @include hsvs.view('dropdown') {
    @extend %aza-visual-background;

    overflow: hidden;

    border-radius: variables.$panel-border-radius;

    box-shadow: variables.$shadow-s;
  }

  @include hsvs.view('dropdownNav') {
    --aza-vertical-nav-item-padding: 16px;

    display: flex;
    flex-direction: column;
  }
}
