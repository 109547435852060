@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/header';
@use '../../../styles/abstracts/variables';

@include hsvs.host('aza', 'landingWrapper') {
  --media-offset: #{variables.$mobile-header-height};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - var(--media-offset));

  @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
    --media-offset: #{variables.$desktop-header-height};
  }
}
