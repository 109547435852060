@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'classic-trivia') {
  display: block;

  @include hsvs.view('banner') {
    object-fit: cover;
  }

  @include hsvs.view('buttonWrapper') {
    display: grid;
  }

  @include hsvs.view('time') {
    @include typography.font('body-m', 'light');

    display: inline-flex;
    justify-content: center;
    min-height: 24px;
    column-gap: 5px;
  }

  @include hsvs.view('timeValue') {
    @include typography.font('body-m', 'semi-bold');
    font-variant-numeric: tabular-nums;

    min-width: 46px;
  }
}
