@use '../../../../../core/src/styles/typography';

@use '../mixins';

%aza-title {
  @include typography.font('h4-mobile', 'semi-bold');

  margin: 70px 0 40px;
}

%aza-page-title {
  @include typography.font('h5-mobile', 'semi-bold');

  margin: 0 0 30px 0;

  @include mixins.media-breakpoint-up('lg') {
    @include typography.font('h5', 'semi-bold');
  }
}

%aza-mobile-only-title {
  @include typography.font('h5-mobile', 'semi-bold');
  margin: 10px 0 20px;

  @include mixins.media-breakpoint-up('lg') {
    display: none;
  }
}

%aza-streamers-title {
  @include typography.font('h2-mobile', 'semi-bold');

  display: flex;
  align-items: center;
  gap: 16px;

  @include mixins.media-breakpoint-up(lg) {
    @include typography.font('h2', 'semi-bold');
  }
}

%aza-streamers-title_dot {
  flex-shrink: 0;
  width: 16px;
  height: 16px;

  border-radius: 50%;

  background-color: var(--aza-accent-color);
}
