@use '../../../../cdk/src/hsvs/hsvs';

@mixin animated-currency-amount-base($encapsulated: false) {
  %_side {
    display: flex;
    grid-area: 1/1/2/2;
    justify-content: flex-end;

    backface-visibility: hidden;
  }

  @include hsvs.host('aza', 'animatedCurrencyAmount', $encapsulated) {
    display: inline-block;
    overflow: hidden;

    perspective: 800px;

    @include hsvs.view('inner') {
      display: grid;
      justify-content: flex-end;

      transform-style: preserve-3d;

      text-align: right;
    }

    @include hsvs.view('front') {
      @extend %_side;
    }

    @include hsvs.view('back') {
      @extend %_side;

      transform: rotateX(180deg);
    }
  }
}
