@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'viewerStreams') {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;

  @include hsvs.view('title') {
    margin: 0 0 28px 0;

    font-size: clamp(38px, 7vw, 50px);
    font-weight: 600;
    line-height: clamp(52px, 7vw, 60px);
    text-align: center;
  }

  @include hsvs.view('accept') {
    color: var(--aza-accent-color);
  }

  @include hsvs.view('description') {
    max-width: 865px;
    margin: 0 auto 55px auto;

    font-size: clamp(14px, 7vw, 18px);
    line-height: clamp(20px, 7vw, 28px);
    text-align: center;
  }

  @include hsvs.view('streams') {
    display: grid;
    grid-template-columns: repeat(3, minmax(308px, 1fr));
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    overflow: auto;
    gap: 20px;
  }
}
