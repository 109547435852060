@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'filtersBar') {
  --aza-selector-height: 48px;
  max-width: 100%;
  overflow: hidden;

  @include hsvs.view('filterContainer') {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    overflow-x: auto;
    column-gap: 22px;
  }

  @include hsvs.view('form') {
    display: flex;
    align-items: center;
    column-gap: 22px;
  }

  @include hsvs.view('select') {
    width: 200px;
  }

  @include hsvs.view('regionSelect') {
    width: 170px;
  }

  @include hsvs.view('btnClearFilters') {
    width: 48px;
    height: 48px;
  }

  @include hsvs.view('btnReset') {
    margin: 0;
    padding: 0;

    border: none;

    background: none;

    color: var(--aza-text-01-color);

    cursor: pointer;
  }

  @include hsvs.view('field') {
    @include hsvs.state((), (), ([search])) {
      display: flex;
      min-width: 269px;

      @include mixins.media-breakpoint-up('lg') {
        min-width: 400px;
      }
    }
  }
}
