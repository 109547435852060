@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/mixins';

%cartSize {
  flex: 1;
}

%black-fab {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;

  background-color: var(--aza-base-02-color);

  color: var(--aza-svg-icon-color, currentColor);

  @media (hover: hover) and (pointer: fine) {
    display: inherit;
    width: 36px;
    height: 36px;

    @include mixins.media-breakpoint-up(lg) {
      width: 60px;
      height: 60px;
    }
  }

  &:disabled {
    background-color: var(--aza-inactive-alpha-color);
  }
}

@include hsvs.host('aza', 'shopItemsCarousel') {
  position: relative;

  & .owl-carousel .owl-stage,
  & .owl-carousel .owl-item {
    display: flex;
  }

  @include hsvs.view('shopCard') {
    @extend %cartSize;
  }

  @include hsvs.view('scrollElement') {
    @extend %cartSize;
  }

  @include hsvs.view('fabLeft') {
    @extend %black-fab;
    left: 0;

    @media (hover: hover) and (pointer: fine) {
      display: inherit;

      transform: translate(10%, -50%);
      @include mixins.media-breakpoint-up(lg) {
        transform: translate(-50%, -50%);
      }
    }
  }

  @include hsvs.view('fabRight') {
    @extend %black-fab;
    right: 0;

    transform: translate(-10%, -50%);
    @include mixins.media-breakpoint-up(lg) {
      transform: translate(50%, -50%);
    }
  }

  @include hsvs.view('buttonIcon') {
    margin: auto;
  }
}
