@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'paypal') {
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 360px;
  gap: 16px 0;

  @include hsvs.view('title') {
    @include typography.font('h5-mobile', 'semi-bold');

    margin: 35px 0 0;

    text-align: center;
  }

  @include hsvs.view('paymentContainer') {
    padding: 8px;

    border-radius: 5px;

    background-color: var(--aza-text-01-color);
  }

  // TODO use when google pay ready
  // @include hsvs.view('googlePayContainer') {
  //   height: 45px;
  //   margin-bottom: 14px;
  // }

  @include hsvs.view('loaderContainer') {
    display: flex;
    justify-content: center;
    margin: 35px 0 0;
  }

  @include hsvs.view('loader') {
    width: 32px;
  }
}
