@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'questConnectToAzarus') {
  display: block;

  @include hsvs.view('btnLogin') {
    flex: 1;
  }

  @include hsvs.view('buttonContent') {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
  }

  @include hsvs.view('btnContentTitle') {
    @include typography.font('body-s', 'semi-bold');

    text-wrap: nowrap;
  }
}
