@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'classicTriviaGameHeader') {
  display: block;

  @include hsvs.view('link') {
    @extend %aza-dialog-link;

    margin-right: auto;
  }

  @include hsvs.view('game') {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;
    gap: 12px 44px;
  }

  @include hsvs.view('gameInfo') {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    gap: 12px 44px;
  }

  @include hsvs.view('image') {
    width: 100%;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 274px;
    }
  }

  @include hsvs.view('details') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 9px;
  }

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h5-mobile', 'semi-bold');
    }
  }

  @include hsvs.view('gameStateTitle') {
    @include typography.font('body-m', 'light');

    color: var(--aza-text-02-color);
    text-wrap: nowrap;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-xl', 'light');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m');

    margin: 0;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('btnGameStart') {
    width: 100%;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 250px;
    }
  }

  @include hsvs.view('btnEmergency') {
    @include typography.font('body-xs', 'light');

    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 142px;

    border: none;

    background: none;

    color: var(--aza-text-02-color);
    text-align: left;

    cursor: pointer;
    gap: 5px;
  }

  @include hsvs.view('btnTitle') {
    @include typography.font('body-s', 'semi-bold');
  }
}
