@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'textarea') {
  display: flex;
  flex-direction: column;
  padding: 6px 12px;

  border-radius: 10px;

  background-color: var(--aza-base-03-alpha-color);

  @include hsvs.state((':focus-within')) {
    box-shadow: 0 0 0 2px inset var(--aza-text-01-color);
  }

  @include hsvs.state((':hover')) {
    background-color: var(--aza-base-03-hover-alpha-color);
  }

  @include hsvs.state(':hover' [disabled]) {
    background-color: var(--aza-base-03-alpha-color);
  }

  @include hsvs.state([error]) {
    box-shadow: 0 0 0 2px inset var(--aza-error-color);
  }

  @include hsvs.state(([disabled])) {
    opacity: 0.5;
  }

  @include hsvs.view('prefix') {
    @include typography.font('body-s', 'light');
  }
}
