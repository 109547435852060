@use '../../../cdk/src/hsvs/hsvs';
@use '../../../core/src/styles/typography';

@use '../styles/abstracts/variables';
@use '../styles/abstracts/mixins';
@use '../styles/abstracts/functions';

@include hsvs.host('aza', 'appRoot') {
  display: block;
  height: 100%;

  @include hsvs.view('mocaWidget') {
    position: fixed;
    top: variables.$mobile-header-height;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      top: variables.$desktop-header-height;
    }
  }

  @include hsvs.view('discordButton') {
    position: fixed;
    z-index: functions.z-index('discord-button');
    right: calc(#{variables.$container-padding} - 11px);
    bottom: 70px;
  }

  @include hsvs.view('discordButtonContent') {
    @include typography.font('body-xxs', 'semi-bold');
  }
}
