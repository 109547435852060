@use '../../../../../core/src/styles/typography';

@use '../mixins';

%aza-news-post-image {
  @include mixins.fixed-ratio-image-container(16, 9);
}

%aza-news-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
}

%aza-news-post-date {
  @include typography.font('body-xs', 'semi-bold');

  opacity: 0.6;

  letter-spacing: 0.4px;
}

%aza-news-post-title {
  @include typography.font('h6-mobile', 'semi-bold');

  margin: 14px 0;

  @include mixins.media-breakpoint-up(lg) {
    @include typography.font('h6', 'semi-bold');
  }
}

%aza-news-post-description {
  @include typography.font('body-s', 'light');

  margin: 14px 0;
}
