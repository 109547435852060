@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/functions';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/titles';

@include hsvs.host('aza', 'aboutAzarusPage') {
  display: block;

  @include hsvs.view('title') {
    @extend %aza-title;
  }

  @include hsvs.view('heroSection') {
    @extend %aza-position-behind-header;
    position: relative;
    z-index: functions.z-index('elevated-content');

    background-image: url(^assets/img/about-azarus-page-hero-bg.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 175%;

    @include mixins.media-breakpoint-up(sm) {
      background-size: 150%;
    }

    @include mixins.media-breakpoint-up(md) {
      background-size: 135%;
    }

    @include mixins.media-breakpoint-up(lg) {
      background-size: auto;
    }

    @include mixins.media-breakpoint-up(xxxxl) {
      background-image: url(^assets/img/about-azarus-page-hero-bg-xxxxl.jpg);
    }
  }

  @include hsvs.view('companySection') {
    margin: 0 0 90px;

    @include mixins.media-breakpoint-up(lg) {
      margin: 60px 0 120px;
    }
  }

  @include hsvs.view('infoSection') {
    padding: 50px 0 100px;

    background-color: var(--aza-card-bg-alpha-color);

    @include mixins.media-breakpoint-up(lg) {
      padding: 100px 0 115px;
    }
  }

  @include hsvs.view('infoContainer') {
    display: grid;
    grid-gap: 90px;
    grid-template-columns: 1fr;

    @include mixins.media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include mixins.media-breakpoint-up(xl) {
      grid-gap: 160px;
    }

    @include mixins.media-breakpoint-up(xxl) {
      grid-gap: 220px;
    }
  }

  @include hsvs.view('infoContent') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include hsvs.view('infoTitle') {
    @include typography.font('h2-mobile', 'semi-bold');
    margin: 0 0 30px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('infoDescription') {
    @include typography.font('body-xl', 'light');
    flex: 1;
    max-width: 658px;
    margin-bottom: 40px;

    color: var(--aza-text-02-color);

    @include mixins.media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }
}
