@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'classicTriviaPartyQuestion') {
  display: block;

  @include hsvs.view('question') {
    @include typography.font('body-xs', 'semi-bold');

    margin: 0;
    margin-bottom: 10px;

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('answers') {
    @include typography.font('body-xxs', 'light');

    z-index: 1;
    grid-area: 2/1 / 3/3;
    margin: 0;
    padding-left: 23px;

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('answer') {
    @include hsvs.state((), (), (':not(:last-child)')) {
      margin-bottom: 4px;
    }

    @include hsvs.state((), (), ([currentAnswer])) {
      @include typography.font('body-xxs', 'semi-bold');
    }
  }
}
