@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

%_purchaseViewCode {
  @include typography.font('body-l', 'semi-bold');

  flex: 1;
  align-items: center;
  justify-content: center;
  height: 68px;
  margin: 0;
  padding: 0 10px;

  transition-property: background-color;
  transition-duration: 150ms;

  border: none;
  border-radius: 5px;

  background-color: var(--aza-text-highlight-alpha-color);

  color: var(--aza-text-01-color);

  cursor: pointer;

  &:hover {
    background-color: var(--aza-text-highlight-hover-alpha-color);
  }
}

@include hsvs.host('aza', 'purchaseCode') {
  display: flex;

  @include hsvs.view('btnRevealCode') {
    @extend %_purchaseViewCode;
  }

  @include hsvs.view('btnDownloadCode') {
    @extend %_purchaseViewCode;

    display: flex;
    column-gap: 10px;
  }

  @include hsvs.view('icon') {
    color: var(--aza-accent-color);
  }
}
