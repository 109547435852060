@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'paymentPagePurchasesItem') {
  @include typography.font('body-m', 'semi-bold');

  display: grid;
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
  padding: 30px 20px;

  border-radius: 5px;

  background-color: var(--aza-base-02-color);

  @include hsvs.view('header') {
    display: flex;
    margin-bottom: 30px;
    column-gap: 20px;
  }

  @include hsvs.view('poster') {
    border-radius: 4px;
  }

  @include hsvs.view('title') {
    @include typography.font('body-l', 'semi-bold');

    margin: 0;
  }

  @include hsvs.view('subTitle') {
    @include typography.font('body-s', 'regular');

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('userBalance') {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include hsvs.view('divider') {
    height: 1px;
    margin: 18px 0 16px 0;

    background-color: var(--aza-base-03-alpha-color);
  }

  @include hsvs.view('purchaseInfo') {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include hsvs.view('paymentValue') {
    @include typography.font('body-m', 'semi-bold');
  }
}
