@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'purchaseErrorDialog') {
  @include typography.font('body-xl', 'semi-bold');

  color: var(--aza-text-01-color);
  text-align: center;

  @include hsvs.view('image') {
    display: flex;
    width: 140px;
    height: 140px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('text') {
    color: var(--aza-text-02-color);
  }

  @include hsvs.view('link') {
    @extend %aza-dialog-link;
  }

  @include hsvs.view('defaultSectionMessage') {
    margin: 20px 0;

    color: var(--aza-error-color);
  }

  @include hsvs.view('codeSection') {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;

    color: var(--aza-error-color);
    white-space: nowrap;
  }

  @include hsvs.view('code') {
    max-width: 100%;
    overflow: hidden;

    text-overflow: ellipsis;
  }

  @include hsvs.view('btnCopyCode') {
    margin: 0;
    padding: 5px;

    border: none;

    background: none;

    color: currentColor;

    cursor: pointer;
  }
}
