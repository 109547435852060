@use '../../../../cdk/src/hsvs/hsvs';

@mixin slider-base($encapsulated: false) {
  %_flex-position {
    display: flex;
    justify-content: center;
  }

  @include hsvs.host('aza', 'slider', $encapsulated) {
    display: flex;
    position: relative;

    @include hsvs.view('groups') {
      display: grid;
      width: 100%;
      margin: var(--aza-slider-groups-margin, 0);
      overflow: hidden;
    }

    @include hsvs.view('group') {
      display: flex;
      grid-area: 1/1 / 2/2;
      align-items: center;
      justify-content: var(--aza-slider-items-position, space-between);
      column-gap: var(--aza-slider-item-column-gap, 0);

      @include hsvs.state((), (), [groupItemsPosition]) {
        --aza-slider-items-position: flex-start;
      }
    }

    @include hsvs.view('btn') {
      @extend %_flex-position;

      z-index: 1;
      align-items: var(--aza-slider-btn-position, center);

      cursor: default;
    }

    @include hsvs.view('dotsBox') {
      @extend %_flex-position;

      position: absolute;
      top: var(--aza-slider-dots-top-position, auto);
      right: 0;
      bottom: var(--aza-slider-dots-bottom-position, auto);
      left: 0;
      align-items: center;

      @include hsvs.state((), (), (':empty')) {
        &:empty {
          display: none;
        }
      }
    }
  }
}
