@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaGreetingPopupNovember') {
  display: block;

  @include hsvs.view('imageContainer') {
    // width: 85%;
    // margin: 7% auto 0;

    img {
      display: block;
      width: 100%;
    }
  }

  @include hsvs.view('button') {
    display: block;
    width: max-content;
    min-width: 50%;
    margin: 36px auto 48px;
  }

  @include hsvs.view('buttonAbsolute') {
    display: block;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    width: max-content;
    min-width: 50%;

    transform: translateX(-50%);
  }
}
