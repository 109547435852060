@use '../../../../../../cdk/src/hsvs/hsvs';

@use '../../../../styles/typography';

@mixin field-input-base($encapsulated: false) {
  @include hsvs.host('aza', 'fieldLabel', $encapsulated) {
    overflow: hidden;

    opacity: 0.7;

    color: var(--aza-text-01-color);
    font-size: 0.875em;
    font-weight: typography.font-weight('light');
    line-height: 1.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
