@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'rockPaperScissorsGame') {
  display: flex;
  gap: 12px 44px;

  flex-wrap: wrap;
  align-items: flex-start;

  @include hsvs.view('image') {
    width: 100%;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 274px;
    }
  }

  @include hsvs.view('game') {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @include hsvs.view('gameMeta') {
    display: grid;
    gap: 11px 0;
  }

  @include hsvs.view('gameItem') {
    display: flex;
    align-items: center;
    min-height: 38px;
    column-gap: 16px;

    @include hsvs.state((), (), ([disabled])) {
      opacity: 0.4;
    }
  }

  @include hsvs.view('gameItemTitleElement') {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 10px;
  }

  @include hsvs.view('label') {
    @include typography.font('body-m', 'semi-bold');

    min-width: 60px;

    transition: background-color 100ms;

    background-color: var(--aza-text-01-color);

    color: var(--aza-base-01-color);

    @include hsvs.state((), (), ([primary])) {
      background-color: var(--aza-success-color);
    }
  }

  @include hsvs.view('gameItemTitle') {
    @include typography.font('body-m');

    transition: color 100ms;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-l');
    }

    @include hsvs.state((), (), ([primary])) {
      @include typography.font('body-xl', 'semi-bold');

      color: var(--aza-success-color);

      @include mixins.media-breakpoint-up('lg') {
        @include typography.font('body-xxl', 'semi-bold');
      }
    }
  }

  @include hsvs.view('options') {
    margin-top: 5px;
  }

  @include hsvs.view('action') {
    margin-top: 11px;
  }

  @include hsvs.view('buttonSelect') {
    min-width: 200px;
  }
}
