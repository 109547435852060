@use '../mixins';

%hero-background {
  background-image: url(^assets/img/landing-hero-bg.svg);
  background-repeat: no-repeat;

  @include mixins.media-breakpoint-up(xxxxl) {
    background-image: url(^assets/img/landing-hero-bg-xxxxl.svg);
    background-position: center;
  }
}
