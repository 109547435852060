@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'numbersSection') {
  display: block;
  margin-top: 120px;

  text-align: center;

  $top-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(0, 102, 255, 0.2),
        rgba(0, 102, 255, 0)
      ),
    size: 639px 530px,
    offset-x: 300px,
    offset-y: 400px,
  );
  $bottom-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(204, 0, 177, 0.3),
        rgba(204, 0, 176, 0)
      ),
    size: 680px 480px,
    offset-x: 300px,
    offset-y: 400px,
  );
  $gradient-inflation: 200px;

  @include mixins.gradient-background(
    $top-gradient,
    $bottom-gradient,
    calc(50%),
    calc(25%),
    $gradient-inflation,
    0.75
  );

  @include hsvs.view('sectionTitle') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0 0 22px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('sectionSabTitle') {
    max-width: 500px;
    margin: 0 auto 54px auto;

    text-align: center;
  }

  @include hsvs.view('bigNumber') {
    @include typography.font('h1-mobile', 'semi-bold');

    display: inline-block;
    margin-top: 70px;
    margin-bottom: 4px;

    -webkit-background-clip: text;
    background-image: linear-gradient(
      273.86deg,
      #90469f 0%,
      #f13886 40.7%,
      #ee3a57 77.33%
    );

    -webkit-text-fill-color: transparent;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('display', 'semi-bold');
    }
  }

  @include hsvs.view('bigNumberTitle') {
    @include typography.font('body-l', 'semi-bold');

    margin: 0 0 70px;

    color: var(--aza-text-02-color);
    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-xxl', 'semi-bold');
    }
  }

  @include hsvs.view('cardList') {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(318px, 1fr));

    gap: 20px;

    text-align: left;
  }

  @include hsvs.view('card') {
    padding: 40px;

    border-radius: 10px;

    background: var(--aza-base-02-color);
  }

  @include hsvs.view('cardHeader') {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  @include hsvs.view('iconContainer') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;

    border-radius: 50%;

    background-color: var(--aza-text-02-color);
  }

  @include hsvs.view('cardIcon') {
    color: var(--aza-primary-color);
  }

  @include hsvs.view('cardTitle') {
    @include typography.font('body-l', 'semi-bold');

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-xl', 'semi-bold');
    }
  }

  @include hsvs.view('cardNumber') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 50px 0 10px 0;

    color: var(--aza-link-normal-color);

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('cardDescription') {
    margin: 0;

    color: var(--aza-text-02-color);
  }
}
