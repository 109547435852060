@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/functions';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'streamCard') {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 10px;

  @include hsvs.view('previewWrapper') {
    position: relative;
    padding-top: functions.get-height-percentage-from-resolution(387, 220);
  }

  @include hsvs.view('preview') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include hsvs.view('info') {
    display: flex;
    align-items: flex-start;
    padding: 14px;
    gap: 16px;

    background-color: var(--aza-card-bg-alpha-color);
  }

  @include hsvs.view('description') {
    display: flex;
    flex-direction: column;

    text-align: left;
  }

  @include hsvs.view('streamTitle') {
    @include typography.font('body-s', 'semi-bold');

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('streamName') {
    @include typography.font('body-s', 'light');
  }
}
