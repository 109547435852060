@use '../../../../cdk/src/hsvs/hsvs';

@use '../../styles/typography';

@mixin button-toggle-base($encapsulated: false) {
  @include hsvs.host('aza', 'buttonToggle', $encapsulated) {
    @include typography.font('body-m', 'semi-bold');
    display: flex;
    align-items: center;
    padding: 0.656rem 0.787rem;

    transition-property: background-color;
    transition-duration: 150ms;

    border: 2px solid
      var(--aza-toggle-button-border-color, var(--aza-primary-color));
    outline: none;

    background: none;

    color: var(--aza-text-01-color);
    text-decoration: none;

    cursor: pointer;

    @include hsvs.state(([select])) {
      background-color: var(
        --aza-toggle-button-selected-background-color,
        var(--aza-primary-color)
      );

      color: var(
        --aza-toggle-button-selected-text-color,
        var(--aza-light-stable-color)
      );
    }

    @include hsvs.state((':hover')) {
      background-color: var(
        --aza-toggle-button-hover-background-color,
        var(--aza-primary-inactive-hover-alpha-color)
      );
    }

    @include hsvs.state((':focus')) {
      background-color: var(
        --aza-toggle-button-focused-background-color,
        var(--aza-primary-inactive-hover-alpha-color)
      );
    }

    @include hsvs.state((':hover' [select])) {
      background-color: var(
        --aza-toggle-button-hover-selected-background-color,
        var(--aza-primary-hover-color)
      );
    }

    @include hsvs.state((':focus' [select])) {
      background-color: var(
        --aza-toggle-button-focused-selected-color,
        var(--aza-primary-hover-color)
      );
    }

    @include hsvs.state((':first-child')) {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    @include hsvs.state((':last-child ')) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
