@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';

%_url-action {
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  padding: 0;
  column-gap: 16px;

  color: var(--aza-link-normal-color);
}

@include hsvs.host('aza', 'setupPageObsLayer') {
  display: flex;
  flex-direction: column;
  gap: 55px;

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    margin: 0 0 26px;

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('h6', 'semi-bold');
      margin-bottom: 32px;
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m');

    margin: 0.5em 0;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('urlSection') {
    @include typography.font('body-xl', 'semi-bold');
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    row-gap: 20px;

    white-space: nowrap;
  }

  @include hsvs.view('copyButton') {
    @extend %_url-action;
    border: 0;

    background: none;

    cursor: pointer;
  }

  @include hsvs.view('externalLink') {
    @extend %_url-action;
  }

  @include hsvs.view('url') {
    @include typography.font('body-xl', 'semi-bold');

    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include hsvs.view('list') {
    @include typography.font('body-m');
    padding-inline-start: 1em;
    list-style-type: '•';

    color: var(--aza-text-02-color);
    li {
      padding-left: 0.5em;
    }
  }

  @include hsvs.view('urlLabel') {
    margin-right: 16px;
  }

  @include hsvs.view('urlItem') {
    display: flex;
    max-width: 100%;
  }
}
