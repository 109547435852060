@use '../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../styles/typography';
@use '../../../../styles/base-classes/forms';

@mixin field-base($encapsulated: false) {
  %_field-element {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include hsvs.host('aza', 'field', $encapsulated) {
    --aza-field-shadow-color: var(--aza-text-01-color);
    --aza-field-background-color: var(--aza-base-03-alpha-color);
    --aza-field-background-color-hover: var(--aza-base-03-hover-alpha-color);
    --aza-field-border-radius: 0.625em;
    --aza-field-padding: 0 1em;
    --aza-field-height: 3em;
    --aza-field-column-gap: 1em;
    --aza-field-error: var(--aza-error-color);

    display: flex;
    position: relative;
    height: var(--aza-field-height);
    padding: var(--aza-field-padding);
    column-gap: var(--aza-field-column-gap);

    border-radius: var(--aza-field-border-radius);

    background-color: var(--aza-field-background-color);

    @include hsvs.state((':focus-within')) {
      box-shadow: 0 0 0 2px inset var(--aza-field-shadow-color);
    }

    @include hsvs.state([error]) {
      box-shadow: 0 0 0 2px inset var(--aza-field-error);
    }

    @include hsvs.state(([disabled])) {
      opacity: 0.5;
    }

    @include hsvs.state((':hover')) {
      background-color: var(--aza-field-background-color-hover);
    }

    @include hsvs.state(':hover' [disabled]) {
      background-color: var(--aza-field-background-color);
    }

    @include hsvs.view('label') {
      display: flex;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;

      transform: translateY(-50%);
      transition-property: transform;
      transition-duration: 100ms;

      @include hsvs.state([labelUp]) {
        transform: translateY(calc(-100% - 2px));
      }

      @include hsvs.state(':focus-within') {
        transform: translateY(calc(-100% - 2px));
      }
    }

    @include hsvs.view('prefix') {
      @extend %_field-element;
    }

    @include hsvs.view('input') {
      @extend %_field-element;

      position: relative;
      flex: 1;
    }

    @include hsvs.view('suffix') {
      @extend %_field-element;
    }

    @include hsvs.view('errors') {
      @extend %aza-form-error;
      visibility: hidden;

      @include hsvs.state(([error])) {
        visibility: visible;
      }
    }
  }
}
