@use '../../../../cdk/src/hsvs/hsvs';

@use '../../styles/typography';

@mixin badge-base($encapsulated: false) {
  @include hsvs.host('aza', 'badge', $encapsulated) {
    @include typography.font('body-xs', 'semi-bold');

    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: var(--aza-success-color);

    color: var(--aza-base-01-color);

    @include hsvs.state([ 'type', 'default']) {
      width: 20px;
      height: 20px;

      border-radius: 50%;
    }

    @include hsvs.state([ 'type', 'label']) {
      border-radius: 50px;
    }
  }
}
