@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'classicTriviaPartyGameQuestion') {
  display: grid;
  gap: 10px;

  @include hsvs.view('option') {
    @include typography.font('body-s', 'light');

    padding: 7px 17px;

    border-radius: 2px;

    background: var(--aza-text-highlight-alpha-color);

    @include hsvs.state((), (), ([isCorrect])) {
      @include typography.font('body-s', 'semi-bold');
    }
  }
}
