@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/placeholder-loaders';

%_title {
  display: flex;
  align-items: center;
  gap: 22px;

  margin-bottom: 40px;
}

%_actions {
  margin-top: 40px;
}

@include hsvs.host('aza', 'cryptoTransferStatusDialog') {
  display: block;

  @include hsvs.view('title') {
    @extend %_title;
    @include typography.font('h4-mobile', 'semi-bold');

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m', 'light');

    @include hsvs.state((), (), [ error]) {
      color: var(--aza-error-color);
    }
  }

  @include hsvs.view('pendingDescription') {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  @include hsvs.view('actions') {
    @extend %_actions;
  }

  @include hsvs.view('hashLoader') {
    width: 20px;
  }

  @include hsvs.view('titleStubGroup') {
    @extend %_title;
  }

  @include hsvs.view('titleImageStub') {
    @extend %placeholder-loader-stub-circle;
    flex-shrink: 0;
    width: 66px;
    height: 52px;
  }

  @include hsvs.view('titleTextStub') {
    @extend %placeholder-loader-stub-line;
    flex: 1;
    height: 42px;
  }

  @include hsvs.view('descriptionStub') {
    @extend %placeholder-loader-stub-line;
    height: 24px;
  }

  @include hsvs.view('actionsStub') {
    @extend %_actions;
    @extend %placeholder-loader-stub-line;
    width: 214px;
    height: 48px;
  }
}
