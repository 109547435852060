@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'classicTriviaEditPage') {
  display: block;

  @include hsvs.view('questions') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    margin-top: 20px;
    gap: 20px;
  }

  @include hsvs.view('question') {
    @include typography.font('body-xs', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);
  }
}
