@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'gamesPage') {
  display: block;

  @include hsvs.view('games') {
    display: grid;
    grid-gap: 40px;
  }

  @include hsvs.view('gameAdsTitle') {
    @include typography.font('h4', 'semi-bold');

    margin: 60px 0 20px;

    text-align: center;
  }

  @include hsvs.view('link') {
    @extend %aza-dialog-link;

    white-space: nowrap;
  }

  @include hsvs.view('remark') {
    margin: 0;
    margin-bottom: 20px;

    text-align: center;
  }
}
