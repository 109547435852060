@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';

%_paragraph {
  @include typography.font('body-m');
}

@include hsvs.host('aza', 'purchaseWithCoinbaseSuccessDialog') {
  @include typography.font('body-m', 'regular');

  color: var(--aza-text-01-color);
  text-align: center;

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @extend %_paragraph;

    margin: 3px 0 0;

    @include mixins.media-breakpoint-up(lg) {
      margin: 3px 0 0;
    }
  }

  @include hsvs.view('emphasizedLink') {
    @extend %aza-dialog-link-with-underline;

    @include typography.font('body-m', 'semi-bold');
    text-decoration: underline;
  }

  @include hsvs.view('image') {
    display: block;
    width: 140px;
    height: 140px;
    margin-right: auto;
    margin-bottom: 35px;
    margin-left: auto;
  }
}
