@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../../../core/src/styles/base-classes/links';

@include hsvs.host('aza', 'transactionCard') {
  @include typography.font('body-xs');

  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 9px 14px;
  column-gap: 10px;

  border-radius: 5px;

  background-color: var(--aza-card-bg-alpha-color);

  @include hsvs.view('descriptionSection') {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @include hsvs.view('createdDate') {
    color: var(--aza-text-02-color);
    font-weight: typography.font-weight('light');
  }

  @include hsvs.view('description') {
    @include typography.font('body-s');
  }

  @include hsvs.view('direction') {
    color: var(--aza-success-color);
    font-weight: typography.font-weight('semi-bold');

    @include hsvs.state((), (), [directionOut]) {
      color: var(--aza-error-color);
    }
  }

  @include hsvs.view('detailsButton') {
    @extend %aza-link-button;
  }
}
