@use '../typography';

@mixin aza-link {
  transition:
    color 0.25s,
    text-decoration-color 0.25s;

  color: var(--aza-link-normal-color);
  font-weight: typography.font-weight('semi-bold');
  text-decoration: underline;
  text-decoration-color: transparent;

  &:hover {
    $color: var(--aza-link-hover-color);

    color: $color;
    text-decoration-color: $color;
  }
}

@mixin aza-link-primary {
  color: var(--aza-primary-color);

  &:hover {
    $color: var(--aza-primary-active-color);

    color: $color;
    text-decoration-color: $color;
  }
}

@mixin aza-link-accent {
  color: var(--aza-accent-color);

  &:hover {
    $color: var(--aza-accent-active-color);

    color: $color;
    text-decoration-color: $color;
  }
}

@mixin aza-link-error {
  color: var(--aza-error-color);

  &:hover {
    $color: var(--aza-error-active-color);

    color: $color;
    text-decoration-color: $color;
  }
}

%aza-link {
  @include aza-link;

  &--primary {
    @include aza-link-primary;
  }

  &--accent {
    @include aza-link-accent;
  }

  &--error {
    @include aza-link-error;
  }
}

%aza-link-button {
  @extend %aza-link;
  padding: 0;

  border: 0;

  background: none;

  font-size: inherit;

  cursor: pointer;
}
