@use '../../../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'textareaField') {
  margin: 0;
  padding: 0;

  border: none;
  outline: none;

  background: none;

  color: var(--aza-text-01-color);

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  resize: none;
}
