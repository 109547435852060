@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'classicTriviaPartyHeader') {
  display: flex;
  gap: 12px 44px;

  flex-wrap: wrap;
  align-items: flex-start;

  @include hsvs.view('image') {
    width: 100%;
    aspect-ratio: 554/307;
    object-fit: cover;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 274px;
    }
  }

  @include hsvs.view('game') {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }

  @include hsvs.view('details') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 9px;
  }

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h5-mobile', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-m');

    margin: 0;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('addBtn') {
    @include typography.font('body-m', 'semi-bold');

    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    transition: color 150ms;

    border: none;
    outline: none;

    background: none;

    color: var(--aza-link-normal-color);

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      color: var(--aza-link-hover-color);
    }
  }

  @include hsvs.view('btnGameStart') {
    width: 100%;

    @include mixins.media-breakpoint-up('sm') {
      max-width: 250px;
    }
  }

  @include hsvs.view('timerContainer') {
    @include typography.font('body-l', 'regular');

    display: flex;
    align-items: center;
    column-gap: 16px;

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('timer') {
    @include typography.font('body-m', 'semi-bold');

    display: inline-block;
    min-width: 45px;
    padding: 2px 15px;

    border-radius: 30px;

    background-color: var(--aza-text-01-color);

    color: var(--aza-base-01-color);
  }

  @include hsvs.view('message') {
    color: var(--aza-text-02-color);
  }
}
