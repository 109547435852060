@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../../../core/src/styles/themes' as themes;

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/functions';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/hero-background';
@use 'sass:map';

%_title {
  margin-top: 0;
  margin-bottom: 40px;

  text-align: center;

  @include mixins.media-breakpoint-up('lg') {
    margin-bottom: 60px;
  }
}

@include hsvs.host('aza', 'advertisePage') {
  display: block;
  @include themes.light-theme();

  background-color: var(--aza-base-02-color);

  @include hsvs.view('heroSectionWrapper') {
    @extend %aza-position-behind-header;

    position: relative;

    background: linear-gradient(
      273.05deg,
      #2c51bd 0%,
      #543ba1 70.89%,
      #583488 145.14%
    );

    @include mixins.media-breakpoint-up(lg) {
      &:before {
        $gradient-default-values: (
          offset-x: 0px,
          offset-y: 0px,
        );

        $top-gradient: (
          gradient:
            radial-gradient(
              closest-side,
              rgba(0, 26, 255, 0.3),
              rgba(0, 102, 255, 0)
            ),
          size: 629px 521.64px,
          offset-x: 100px,
          offset-y: 150px,
        );
        $bottom-gradient: (
          gradient:
            radial-gradient(
              closest-side,
              rgba(65, 0, 204, 0.3),
              rgba(65, 0, 204, 0)
            ),
          size: 327.51px 306.91px,
          offset-x: 220px,
          offset-y: 220px,
        );

        $top-gradient: map.merge($gradient-default-values, $top-gradient);
        $bottom-gradient: map.merge($gradient-default-values, $bottom-gradient);

        content: '';

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        opacity: 70%;
        background-image: map.get($top-gradient, 'gradient'),
          map.get($bottom-gradient, 'gradient');
        background-repeat: no-repeat;
        background-position:
          68% 75%,
          53% 23%;
        background-size: map.get($top-gradient, 'size'),
          map.get($bottom-gradient, 'size');
      }
    }
  }

  @include hsvs.view('heroSection') {
    margin-top: 60px;
    padding-bottom: 1em;

    @include mixins.media-breakpoint-up(lg) {
      margin-top: 85px;

      &:before {
        $gradient-default-values: (
          offset-x: 0px,
          offset-y: 0px,
        );

        $top-gradient: (
          gradient:
            radial-gradient(
              closest-side,
              rgba(0, 26, 255, 0.3),
              rgba(0, 102, 255, 0)
            ),
          size: 476.69px 282.75px,
        );
        $bottom-gradient: (
          gradient:
            radial-gradient(
              closest-side,
              rgba(53, 0, 204, 0.3),
              rgba(53, 0, 204, 0)
            ),
          size: 193.23px 162.54px,
        );

        $top-gradient: map.merge($gradient-default-values, $top-gradient);
        $bottom-gradient: map.merge($gradient-default-values, $bottom-gradient);

        content: '';

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        opacity: 60%;
        background-image: map.get($top-gradient, 'gradient'),
          map.get($bottom-gradient, 'gradient');
        background-repeat: no-repeat;
        background-position:
          96% 64%,
          90% 45%;
        background-size: map.get($top-gradient, 'size'),
          map.get($bottom-gradient, 'size');

        pointer-events: none;
        contain: strict;
      }
    }
  }

  @include hsvs.view('experienceSectionWrapper') {
    position: relative;
    padding-bottom: 1em;

    background: linear-gradient(
      180deg,
      #ececfb 0%,
      rgba(236, 236, 251, 0) 137.81%
    );

    @include mixins.media-breakpoint-up(xl) {
      padding: 8em 0 10em;
    }
  }

  @include hsvs.view('bg1') {
    $gradient-default-values: (
      offset-x: 0px,
      offset-y: 0px,
    );

    $top-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(53, 0, 204, 0.15),
          rgba(53, 0, 204, 0)
        ),
      size: 193.23px 162.54px,
    );
    $bottom-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(0, 26, 255, 0.3),
          rgba(0, 102, 255, 0)
        ),
      size: 476.69px 282.75px,
    );

    $top-gradient: map.merge($gradient-default-values, $top-gradient);
    $bottom-gradient: map.merge($gradient-default-values, $bottom-gradient);

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 50%;
      background-image: map.get($top-gradient, 'gradient'),
        map.get($bottom-gradient, 'gradient');
      background-repeat: no-repeat;
      background-position:
        150% 45%,
        -90% 54%;
      background-size: map.get($top-gradient, 'size'),
        map.get($bottom-gradient, 'size');

      pointer-events: none;
      contain: strict;
    }

    @include mixins.media-breakpoint-up(lg) {
      &::before {
        background-image: map.get($top-gradient, 'gradient'),
          map.get($bottom-gradient, 'gradient');
        background-position:
          100% 40%,
          108% 54%;
        background-size: map.get($top-gradient, 'size'),
          map.get($bottom-gradient, 'size');
      }
    }
  }

  @include hsvs.view('bg2') {
    $gradient-default-values: (
      offset-x: 0px,
      offset-y: 0px,
    );

    $top-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(65, 0, 204, 0.23),
          rgba(53, 0, 204, 0)
        ),
      size: 327.51px 306.91px,
    );
    $bottom-gradient: (
      gradient:
        radial-gradient(
          closest-side,
          rgba(0, 26, 255, 0.3),
          rgba(0, 102, 255, 0)
        ),
      size: 629px 521.64px,
    );

    $top-gradient: map.merge($gradient-default-values, $top-gradient);
    $bottom-gradient: map.merge($gradient-default-values, $bottom-gradient);

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 50%;
      background-image: map.get($top-gradient, 'gradient'),
        map.get($bottom-gradient, 'gradient');
      background-repeat: no-repeat;
      background-position:
        100% 5%,
        -70% 16%;
      background-size: map.get($top-gradient, 'size'),
        map.get($bottom-gradient, 'size');

      pointer-events: none;
      contain: strict;
    }

    @include mixins.media-breakpoint-up(lg) {
      &::before {
        background-image: map.get($top-gradient, 'gradient'),
          map.get($bottom-gradient, 'gradient');
        background-position:
          6% 15%,
          3% 44%;
        background-size: map.get($top-gradient, 'size'),
          map.get($bottom-gradient, 'size');
      }
    }
  }

  @include hsvs.view('investmentPotentialSectionWrapper') {
    position: relative;
    min-height: 952px;

    background: linear-gradient(
      180deg,
      #ececfb 0%,
      rgba(236, 236, 251, 0) 137.81%
    );
  }

  @include hsvs.view('contactsSectionWrapper') {
    position: relative;

    background: linear-gradient(
      180deg,
      #ececfb 0%,
      rgba(236, 236, 251, 0) 137.81%
    );
  }
}
