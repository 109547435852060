@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'propertyDisplay') {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content);
  align-items: center;
  gap: 18px;

  @include hsvs.view('label') {
    @include typography.font('body-m', 'light');

    color: var(--aza-text-02-color);

    @include hsvs.state([important]) {
      @include typography.font('body-l', 'semi-bold');
    }
  }

  @include hsvs.view('value') {
    @include typography.font('body-l', 'semi-bold');
    overflow: hidden;

    text-overflow: ellipsis;
  }
}
