@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'classicTriviaPage') {
  display: block;

  @include hsvs.view('parties') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    margin-top: 20px;
    gap: 20px;
  }

  @include hsvs.view('addBtn') {
    @include typography.font('body-s', 'semi-bold');

    margin: 0;
    padding: 0;

    transition: color 150ms;

    border: none;
    outline: none;

    background: none;

    color: var(--aza-link-normal-color);

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      color: var(--aza-link-hover-color);
    }
  }

  @include hsvs.view('label') {
    @include typography.font('body-m', 'semi-bold');

    padding: 0px 10px;

    border-radius: 30px;

    background-color: white;

    color: var(--aza-base-01-color);
  }

  @include hsvs.view('cardFooter') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
  }
}
