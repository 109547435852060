@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../../src/styles/abstracts/variables';
@use '../../../../src/styles/abstracts/functions';

@include hsvs.host('aza', 'scrollTopButton') {
  position: fixed;
  z-index: functions.z-index('scroll-top-button');
  right: variables.$container-padding;
  bottom: 215px;

  @include hsvs.view('btnScroll') {
    display: flex;
    position: absolute;
    right: 0;
    align-items: center;
    justify-content: center;

    box-shadow: variables.$shadow-s;

    color: var(--aza-light-stable-color);
  }
}
