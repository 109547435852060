@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'viewerFooter') {
  display: grid;
  grid-template-rows: 1fr auto;
  flex: 1;
  overflow: hidden;

  @include hsvs.view('logoContainer') {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 15px;
  }

  @include hsvs.view('logo') {
    width: 100%;
    max-width: 400px;
  }
}
