@use '../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'radioButton') {
  --aza-radio-button-size: 24px;
  --aza-radio-button-stroke-width: 3px;

  @include typography.font('body-s', 'light');

  display: inline-flex;
  align-items: center;
  column-gap: 12px;

  cursor: pointer;

  @include hsvs.view('radio') {
    display: inline-block;
    width: var(--aza-radio-button-size);
    height: var(--aza-radio-button-size);
    padding: 2px;

    transition: background-color 150ms;

    border: none;
    border-radius: 50%;

    background-color: var(--aza-base-03-alpha-color);

    color: var(--aza-text-01-color);

    cursor: pointer;

    @include hsvs.state((':hover')) {
      background-color: var(--aza-base-03-hover-alpha-color);
    }

    @include hsvs.state(([select])) {
      background-color: var(--aza-primary-color);
    }
  }

  @include hsvs.view('svg') {
    width: 100%;

    transition: opacity 250ms;

    stroke-width: var(--aza-radio-button-stroke-width);
    stroke: var(--aza-text-01-color);

    opacity: 0;

    @include hsvs.state(([select])) {
      opacity: 1;
    }
  }
}
