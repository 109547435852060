@use '../../../../cdk/src/hsvs/hsvs';

@mixin checkbox-base($encapsulated: false) {
  %_flex {
    align-items: center;
    justify-content: center;
  }

  $_default-check-mark-size: 24px;
  $_default-check-mark-radius: 5px;
  $_default-check-mark-color: var(--aza-primary-color);
  $_default-check-mark-hover-color: var(--aza-base-03-hover-alpha-color);

  @include hsvs.host('aza', 'checkbox', $encapsulated) {
    @extend %_flex;

    display: inline-flex;
    position: relative;

    cursor: pointer;
    gap: 5px;

    @include hsvs.view('checkMarkContainer') {
      @extend %_flex;

      display: flex;
      position: relative;
      min-width: var(
        --aza-checkbox-check-mark-size,
        #{$_default-check-mark-size}
      );
      min-height: var(
        --aza-checkbox-check-mark-size,
        #{$_default-check-mark-size}
      );
      overflow: hidden;

      transition-property: background-color;
      transition-duration: 150ms;

      border-radius: var(
        --aza-checkbox-check-mark-radius,
        #{$_default-check-mark-radius}
      );

      background-color: var(--aza-base-03-alpha-color);

      @include hsvs.state(':focus-within') {
        background-color: var(
          --aza-checkbox-check-mark-hover,
          #{$_default-check-mark-hover-color}
        );
      }

      @include hsvs.state((), (), ':hover') {
        background-color: var(
          --aza-checkbox-check-mark-hover,
          #{$_default-check-mark-hover-color}
        );
      }
    }

    @include hsvs.view('container') {
      position: absolute;

      transform: scale(0);
    }

    @include hsvs.view('checkMark') {
      position: absolute;
      z-index: 9;
      width: 100%;

      stroke: var(--aza-light-stable-color);
    }

    @include hsvs.view('checkMarkPath') {
      $_path-length: 24;

      transition-property: stroke-dasharray, stroke-dashoffset;
      transition-duration: 250ms;

      stroke-width: 2px;
      stroke-dasharray: $_path-length;
      stroke-dashoffset: $_path-length;

      @include hsvs.state([checked]) {
        stroke-dashoffset: 0;
      }
    }

    @include hsvs.view('checkMarkBack') {
      width: var(--aza-checkbox-check-mark-size, #{$_default-check-mark-size});
      height: var(--aza-checkbox-check-mark-size, #{$_default-check-mark-size});

      transform: scale(0);
      transition-property: transform, opacity;
      transition-duration: 200ms;

      border-radius: 50%;

      opacity: 0;
      background-color: var(
        --aza-checkbox-check-mark-color,
        #{$_default-check-mark-color}
      );

      @include hsvs.state([checked]) {
        transform: scale(2);

        opacity: 1;
      }
    }
  }
}
