@use '../../../../cdk/src/hsvs/hsvs';

@mixin number-input-base($encapsulated: false) {
  @include hsvs.host('azaCore', 'numberInput', $encapsulated) {
    display: inline-flex;
    column-gap: 0.5em;

    @include hsvs.view('input') {
      width: 3.2em;
      margin-top: 0;
    }

    @include hsvs.view('azaIcon') {
      --aza-core-aza-icon-size: 1.875em;
    }

    @include hsvs.view('btn') {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 3.75em;
      height: 3em;

      font-size: inherit;
    }

    @include hsvs.view('icon') {
      --aza-svg-icon-size: 1.25em;
    }
  }
}
