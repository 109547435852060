@use 'sass:map';
@use 'sass:math';
@use '../variables';
@use '../mixins';

%shop-card-layout {
  display: grid;
  position: relative;
  grid-template-rows: 392px auto;
  margin-bottom: 30px;
  overflow: hidden;

  border-radius: variables.$card-border-radius;

  background-color: var(--aza-card-bg-alpha-color);
}

%shop-card-poster {
  position: relative;
  grid-area: 1/1 / 2/2;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: var(--aza-card-stub-alpha-color);
}

%shop-card-poster-back {
  $blur: 10px;
  $blurOffset: 10px * 2;

  position: absolute;
  top: -$blur;
  left: -$blur;
  width: calc(100% + #{$blurOffset});
  height: calc(100% + #{math.div($blurOffset, 2)});
  object-fit: cover;
  filter: blur($blur);

  opacity: 0.4;
}

%shop-card-poster-image {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 323px;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  object-position: top;
}

%shop-card-description-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}
