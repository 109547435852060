@use '../abstracts/mixins';

%_dialog {
  --mdc-dialog-container-color: var(--aza-base-01-color);
  --mdc-dialog-container-shape: 10px;

  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    min-width: unset;
    max-width: unset;
    min-height: unset;
    max-height: unset;
  }

  .mat-mdc-dialog-surface {
    padding: 32px 16px;

    @include mixins.media-breakpoint-up(lg) {
      padding: 32px;
    }
  }
}

.aza-dialog {
  @extend %_dialog;
}

.aza-top-gradient-dialog {
  @extend %_dialog;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: linear-gradient(
        180deg,
        rgba(94, 16, 162, 0.8) 0%,
        rgba(29, 30, 32, 0) 40%
      ),
      linear-gradient(
        180deg,
        var(--mdc-dialog-container-color) 0%,
        var(--mdc-dialog-container-color) 100%
      );
    background-color: transparent;
  }
}

.aza-top-gradient-dialog-no-side {
  @extend %_dialog;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: linear-gradient(
        180deg,
        rgba(94, 16, 162, 0.8) 0%,
        rgba(29, 30, 32, 0) 40%
      ),
      linear-gradient(
        180deg,
        var(--mdc-dialog-container-color) 0%,
        var(--mdc-dialog-container-color) 100%
      );
    background-color: transparent;
  }

  .mat-mdc-dialog-surface {
    padding: 32px 0;
  }
}

.aza-top-gradient-dialog-no-side-radial {
  @extend %_dialog;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: radial-gradient(
      75.81% 75.81% at 50% 44.13%,
      #8567ff 0%,
      #000 100%
    );
    background-color: transparent;
  }

  .mat-mdc-dialog-surface {
    padding: 32px 0;
  }
}

.aza-top-gradient-dialog-black-friday {
  @extend %_dialog;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent;
  }

  .mat-mdc-dialog-surface {
    padding: 0 0;
  }
}

.aza-contact-us-dialog {
  @extend %_dialog;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: #fff;
  }
}

.aza-aza-buy-dialog {
  @extend %_dialog;

  width: 100%;
  max-width: min(80vw, 680px) !important;
}
