@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'shopItemMetadata') {
  display: block;

  @include hsvs.view('container') {
    margin: 0;
  }

  @include hsvs.view('label') {
    @include typography.font('body-s', 'light');
    display: inline-block;
  }

  @include hsvs.view('value') {
    @include typography.font('body-m', 'semi-bold');
    display: inline-block;
    margin-inline-start: 0.5rem;
  }
}
