@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/variables';

@include hsvs.host('aza', 'filterPrice') {
  display: inline-block;

  @include hsvs.view('btnPrice') {
    display: flex;
    align-items: center;
    min-width: 120px;
    min-height: 48px;
    padding-right: 15px;
    padding-left: 20px;
    column-gap: 15px;

    border: none;
    border-radius: 10px;

    background-color: var(--aza-base-03-alpha-color);

    color: var(--aza-text-01-color);

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      background-color: var(--aza-base-03-hover-alpha-color);
    }

    @include hsvs.state((), (), (':focus')) {
      box-shadow: 0 0 0 2px inset var(--aza-text-01-color);
    }
  }

  @include hsvs.view('topography') {
    text-align: left;
  }

  @include hsvs.view('price') {
    white-space: nowrap;
  }

  @include hsvs.view('icon') {
    transition-property: transform;
    transition-duration: 250ms;

    @include hsvs.state((), (), ([opened])) {
      transform: rotate3d(1, 0, 0, 180deg);
    }
  }

  @include hsvs.view('priceRange') {
    box-sizing: border-box;
    width: 360px;
    padding: 20px;

    border-radius: 5px;

    background-color: var(--aza-base-02-color);
  }

  @include hsvs.view('title') {
    @include typography.font('body-m');

    margin-bottom: 15px;
  }

  @include hsvs.view('sliderRange') {
    margin-bottom: 15px;
  }

  @include hsvs.view('priceRangeGroup') {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    column-gap: 15px;
  }

  @include hsvs.view('field') {
    flex: 1;
  }

  @include hsvs.view('btnSave') {
    display: block;
    margin-left: auto;
    padding: 6px 52px;
  }
}
