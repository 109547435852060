@use '../../../../cdk/src/hsvs/hsvs';

@mixin logo-svg-base($encapsulated: false) {
  @include hsvs.host('aza', 'logoSvg', $encapsulated) {
    display: inline-flex;

    color: #ffffff;

    @include hsvs.view('svg') {
      width: 100%;
      height: 100%;
    }
  }
}
