@use 'sass:math';

@use '../../../../../cdk/src/hsvs/hsvs';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/base-classes/common';

$menu-trigger-size: 36px;
$mobile-menu-clip-path-offset-x: calc(
  #{math.div($menu-trigger-size, 2)} + #{variables.$container-padding}
);
$mobile-menu-clip-path-offset-y: math.div(variables.$mobile-header-height, 2);
$mobile-menu-toggle-animation-timing: cubic-bezier(0.4, 0, 0.2, 1);

%_trigger-bar {
  width: 100%;
  height: 2px;

  transition:
    top 0.2s $mobile-menu-toggle-animation-timing,
    transform 0.4s $mobile-menu-toggle-animation-timing;

  border-radius: 2px;

  background-color: var(--aza-text-01-color);
}

%_trigger-bar-pseudo {
  content: '';

  position: absolute;
  left: 0;
}

%_nav-item-spacing {
  padding: 16px;
}

@include hsvs.host('aza', 'mobileMenuBase') {
  display: flex;

  --aza-mobile-menu-overlay-clip-path: circle(
    0% at #{$mobile-menu-clip-path-offset-x} #{$mobile-menu-clip-path-offset-y}
  );
  --aza-mobile-menu-overlay-open-clip-path: circle(
    calc(
        100% + #{$mobile-menu-clip-path-offset-x} * #{2 * math.$pi} + #{$mobile-menu-clip-path-offset-y *
          2 * math.$pi}
      )
      at #{$mobile-menu-clip-path-offset-x} #{$mobile-menu-clip-path-offset-y}
  );

  position: relative;

  @include hsvs.view('trigger') {
    box-sizing: border-box;
    width: $menu-trigger-size;
    height: $menu-trigger-size;
    padding: 0 4px;

    border: none;

    background: none;
  }

  @include hsvs.view('triggerBars') {
    @extend %_trigger-bar;
    position: relative;

    @include hsvs.state((), (), [opened]) {
      transform: rotate(45deg);
    }

    @include hsvs.state((), (), ('::before')) {
      @extend %_trigger-bar;
      @extend %_trigger-bar-pseudo;
      top: -9px;
    }

    @include hsvs.state((), (), ([opened] '::before')) {
      top: 0;

      transform: rotate(0);
    }

    @include hsvs.state((), (), ('::after')) {
      @extend %_trigger-bar;
      @extend %_trigger-bar-pseudo;
      top: 9px;
    }

    @include hsvs.state((), (), ([opened] '::after')) {
      top: 0;

      transform: rotate(90deg);
    }
  }

  @include hsvs.view('overlay') {
    @extend %aza-visual-background;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  @include hsvs.view('overlayContent') {
    height: calc(100% - #{variables.$mobile-header-height});
    margin-top: variables.$mobile-header-height;
    overflow: auto;
  }

  @include hsvs.view('nav') {
    display: flex;
    flex-direction: column;

    transition: opacity 0.4s $mobile-menu-toggle-animation-timing;

    opacity: 0;

    @include hsvs.state((), (), [opened]) {
      opacity: 1;
    }
  }

  @include hsvs.view('logoLink') {
    margin: 8px 0;
    padding: 0 20px;

    line-height: 0;

    cursor: pointer;
  }
}
