@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

%_text-center {
  text-align: center;
}

@include hsvs.host('aza', 'getMoreEntriesPage') {
  display: block;
  padding: 70px 0;

  @include hsvs.view('pageTitle') {
    @extend %_text-center;

    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0 0 22px 0;

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @extend %_text-center;

    @include typography.font('body-m');

    margin: 0 0 45px 0;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('quests') {
    display: grid;
    gap: 16px 0;

    grid-template-columns: minmax(auto, max-content);
    justify-content: center;
  }
}
