@use 'sass:map';
@use '../../../../core/src/styles/typography';
@use '../abstracts/functions';

body {
  @for $i from 1 through 6 {
    $typography-size: typography.typography-size('h#{$i}');
    --aza-markdown-h#{$i}-font-size: #{map.get($typography-size, 'font-size')};
    --aza-markdown-h#{$i}-line-height: #{map.get(
        $typography-size,
        'line-height'
      )};
  }
  --aza-markdown-blockquote-border: 5px solid var(--aza-base-03-alpha-color);

  --aza-markdown-code-pre-bg-color: var(--aza-base-03-alpha-color);
  --aza-markdown-link-color: var(--aza-accent-color);
  --aza-markdown-link-color-active: var(--aza-accent-active-color);
  --aza-markdown-inline-code-color: var(--aza-accent-color);
  --aza-markdown-table-min-width: 600px;
  --aza-markdown-table-head-font-weight: #{typography.font-weight('semi-bold')};
  --aza-markdown-table-cell-border: 1px solid var(--aza-text-01-color);
}
