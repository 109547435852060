@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'accountVerificationDialog') {
  @include hsvs.view('header') {
    display: flex;
    gap: 20px;

    align-items: center;
  }

  @include hsvs.view('image') {
    width: 66px;
    height: 66px;
  }

  @include hsvs.view('headerText') {
    @include typography.font('h4-mobile', 'semi-bold');

    @include mixins.media-breakpoint-up(md) {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('perksHeader') {
    @include typography.font('body-m', 'semi-bold');
    margin-top: 35px;
  }

  @include hsvs.view('perks') {
    @include typography.font('body-m', 'light');

    margin-block-end: 0;
    padding-inline-start: 0.8em;
    list-style-type: '•';
  }

  @include hsvs.view('perksItem') {
    padding-left: 0.8em;
  }

  @include hsvs.view('verificationCostLine') {
    @include typography.font('body-m', 'semi-bold');
    margin-top: 24px;
  }

  @include hsvs.view('mainActionButton') {
    width: 100%;
    margin-top: 40px;

    @include mixins.media-breakpoint-up(md) {
      width: fit-content;
      min-width: 250px;
    }
  }
}
