@use '../../../../../../../cdk/src/hsvs/hsvs';

@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/variables';

@include hsvs.host('aza', 'streamerDashboardHeader') {
  display: block;

  @include hsvs.view('userMobMenu') {
    margin-bottom: 8px;
    padding: 0 14px;
  }

  @include hsvs.view('profileMenu') {
    display: none;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      display: flex;
    }
  }
}
