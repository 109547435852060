@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

%_flex-x-center {
  display: flex;
  justify-content: center;
}

@include hsvs.host('aza', 'rpsOption') {
  @include typography.font('body-m', 'semi-bold');

  display: grid;
  grid-template-rows: 1fr auto;
  gap: 6px;

  width: 105px;
  height: 105px;
  overflow: hidden;

  border-radius: 10px;

  background-color: var(--aza-base-02-color);

  cursor: pointer;

  @include mixins.media-breakpoint-up('lg') {
    width: 140px;
    height: 140px;
  }

  @include hsvs.state(([disabled])) {
    opacity: 0.5;

    cursor: auto;
  }

  @include hsvs.view('option') {
    @extend %_flex-x-center;

    z-index: 2;
    grid-area: 1/1 / 2/2;
    align-items: flex-end;
  }

  @include hsvs.view('image') {
    @extend %_flex-x-center;

    z-index: 2;
    grid-area: 2/1 / 3/2;
    max-height: 75px;

    img {
      height: 100%;
    }

    @include mixins.media-breakpoint-up('lg') {
      max-height: 90px;
    }
  }

  @include hsvs.view('back') {
    grid-area: 1/1 / 3/2;

    transition-property: background-color;
    transition-duration: 150ms;

    background-color: transparent;

    @include hsvs.state(([selected])) {
      background-color: rgba(39, 234, 199, 0.08);
    }

    @include hsvs.state((':hover')) {
      background-color: rgba(39, 234, 199, 0.08);
    }

    @include hsvs.state(([disabled])) {
      background-color: transparent;
    }
  }

  @include hsvs.view(border) {
    z-index: 3;
    grid-area: 1/1 / 3/2;

    transition-property: box-shadow;
    transition-duration: 150ms;

    border-radius: 10px;

    @include hsvs.state(([selected])) {
      box-shadow: 0 0 0 3px inset var(--aza-success-color);
    }
  }
}
