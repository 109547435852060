@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaCoinPressHitsSection') {
  display: block;
  margin-top: 120px;

  @include hsvs.view('sectionTitle') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0 0 22px;

    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('pressHits') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 35px;
  }

  @include hsvs.view('pressHit') {
    display: block;
    overflow: hidden;

    border-radius: 10px;
    isolation: isolate;

    line-height: 0;
  }
}
