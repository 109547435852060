@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaCoinUtilitySection') {
  display: block;
  margin-top: 120px;

  @include hsvs.view('sectionTitle') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0 0 22px;

    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('sectionDescription') {
    @include typography.font('body-m');
    max-width: 834px;
    margin: 22px auto 50px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('features') {
    display: grid;
    grid-gap: 60px 50px;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
