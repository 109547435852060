@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/titles';

%_faq-content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include mixins.media-breakpoint-up(lg) {
    gap: 16px;
  }
}

@include hsvs.host('aza', 'faqPage') {
  display: block;

  @include hsvs.view('title') {
    @extend %aza-title;

    margin-bottom: 20px;
  }

  @include hsvs.view('faqGroupColumnContainer') {
    display: grid;
    gap: 90px;

    grid-template-columns: 1fr;
    margin: 30px 0 60px;

    @include mixins.media-breakpoint-up(lg) {
      gap: 16px 40px;

      grid-template-columns: 1fr 1fr;
      margin: 50px 0 120px;
    }
  }

  @include hsvs.view('faqGroupTitle') {
    @include typography.font('h5-mobile', 'semi-bold');

    margin: 0;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h5', 'semi-bold');
    }
  }

  @include hsvs.view('faqSubGroupTitle') {
    @include typography.font('body-xl', 'semi-bold');

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('faqGroupContainer') {
    @extend %_faq-content;

    @include hsvs.state((), (), ':nth-child(n + 2)') {
      margin-top: 90px;
    }
  }

  @include hsvs.view('faqSubGroupContainer') {
    @extend %_faq-content;

    @include hsvs.state((), (), ':nth-child(n + 3)') {
      margin-top: 48px;
    }
  }
}
