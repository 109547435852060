@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'user') {
  display: inline-flex;
  column-gap: 10px;

  @include hsvs.view('picture') {
    $size: 36px;

    flex-shrink: 0;
    width: $size;
    height: $size;

    border-radius: 50%;
  }

  @include hsvs.view('userNameWrapper') {
    @include typography.font('body-xs', 'light');

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @include hsvs.view('userName') {
    @include typography.font('body-m', 'semi-bold');

    max-width: 180px;
    overflow-x: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
