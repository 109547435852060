@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'classicTriviaGameTimer') {
  @include typography.font('body-m', 'semi-bold');

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;

  transition: background-color 100ms;

  border-radius: 50px;

  background-color: var(--aza-text-01-color);

  color: var(--aza-base-01-color);

  @include hsvs.state(([isActive])) {
    background-color: var(--aza-success-color);
  }
}
