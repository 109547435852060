@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/variables';
@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/functions';
@use '../../../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'streamerPageStandOut') {
  display: block;

  @include hsvs.view('content') {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    gap: 60px 0;

    @include mixins.media-breakpoint-up('xxl') {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include hsvs.view('streamer') {
    display: flex;
    justify-content: center;
    overflow: hidden;

    @include mixins.media-breakpoint-up(xxl) {
      justify-content: flex-start;
      margin-right: 40px;
    }
  }

  @include hsvs.view('streamerImageContainerWrapper') {
    width: 100%;
    max-width: 662px;
  }

  @include hsvs.view('streamerImageContainer') {
    @include mixins.fixed-ratio-image-container(662, 635, contain);
  }

  @include hsvs.view('partnerInfo') {
    position: relative;
    z-index: functions.z-index('elevated-content');
  }

  @include hsvs.view('partnerInfoTitle') {
    @include typography.font('h2-mobile', 'semi-bold');
    margin: 0 0 20px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h2', 'semi-bold');
      margin: 0 0 40px;
    }
  }

  @include hsvs.view('partnerInfoDescription') {
    @include typography.font('body-m');

    margin: 20px 0 15px;

    @include mixins.media-breakpoint-up(lg) {
      margin: 40px 0 30px;
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-l');

    margin: 5px 0;
  }

  @include hsvs.view('benefitsContainer') {
    display: grid;
    grid-gap: 30px 105px;
    grid-template-columns: 1fr;
    margin: 30px 0 30px;

    @include mixins.media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include hsvs.view('benefit') {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }

  @include hsvs.view('image') {
    width: 60px;
    height: 60px;
  }
}
