@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'faqCard') {
  display: block;

  @include hsvs.view('expansionPanel') {
    --aza-expansion-panel-background-color: var(--aza-card-bg-alpha-color);

    --aza-expansion-panel-color: var(--aza-text-01-color);
  }

  @include hsvs.view('question') {
    @include typography.font('body-l', 'semi-bold');
  }

  @include hsvs.view('answer') {
    @include typography.font('body-s', 'light');
  }
}
