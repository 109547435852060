@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'transactions') {
  display: block;

  @include hsvs.view('transactionList') {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;

    gap: 10px 40px;

    @include mixins.media-breakpoint-up('md') {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include hsvs.view('loader') {
    position: absolute;
    z-index: -1;
    top: 75%;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @include hsvs.view('emptyState') {
    @include typography.font('h6-mobile', 'semi-bold');
    display: flex;
    align-items: center;
    column-gap: 13px;

    opacity: 0.5;

    color: var(--aza-text-02-color);

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('noTransactionIcon') {
    --aza-svg-icon-size: 24px;

    display: inline-flex;
  }
}
