@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'streamerEarnCalculator') {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 50px 15px;

  background: linear-gradient(
    273.86deg,
    #2348b6 0%,
    #482e9a 50.18%,
    #4c2089 97.32%
  );

  color: var(--aza-text-01-color);

  @include mixins.media-breakpoint-up('lg') {
    padding: 50px 36px;
  }

  @include hsvs.view('header') {
    margin: 0;

    @include typography.font('body-xl', 'semi-bold');
  }

  @include hsvs.view('rangeContainer') {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  @include hsvs.view('range') {
    --aza-slim-range-slider-color: var(--aza-text-01-color);
    flex: 1;
  }

  @include hsvs.view('ccvWidget') {
    box-sizing: border-box;
    width: 101px;
    margin: 0;
    padding: 6px 12px;

    border-radius: 10px;

    background: rgba(255, 255, 255, 0.16);
    dt {
      @include typography.font('body-s', 'light');
      opacity: 0.72;
    }
    dd {
      @include typography.font('body-m', 'regular');
      margin-inline-start: 0;
    }
  }

  @include hsvs.view('toggleGroup') {
    width: 100%;
    min-height: 56px;
    --aza-toggle-button-border-color: var(--aza-text-01-color);
    --aza-toggle-button-selected-background-color: var(--aza-text-01-color);
    --aza-toggle-button-selected-text-color: var(--aza-primary-color);
    --aza-toggle-button-hover-selected-background-color: var(
      --aza-text-01-color
    );
    --aza-toggle-button-focused-selected-color: var(--aza-text-01-color);
  }

  @include hsvs.view('btnToggle') {
    @include typography.font('body-s', 'semi-bold');
    flex: 1;
    justify-content: center;
  }

  @include hsvs.view('btnToggleContent') {
    text-align: left;
  }

  @include hsvs.view('part') {
    display: none;
    @include mixins.media-breakpoint-up('sm') {
      display: initial;
    }
  }

  @include hsvs.view('payoutLabel') {
    @include typography.font('body-l', 'semi-bold');
    display: flex;
    gap: 12px;

    align-items: center;
  }

  @include hsvs.view('payoutDisplay') {
    @include typography.font('h4', 'semi-bold');
    color: var(--aza-accent-color);
  }

  @include hsvs.view('payoutFiat') {
    @include typography.font('body-s', 'regular');
  }

  @include hsvs.view('disclaimer') {
    @include typography.font('body-xxs', 'light');
  }

  @include hsvs.view('payoutGroup') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0px 1px;
    gap: 8px;
  }
}
