@use '../../../../../../cdk/src/hsvs/hsvs';

@mixin markdown-block-base($encapsulated: false) {
  @include hsvs.host('aza', 'markdownBlock', $encapsulated) {
    li > p {
      margin: 4px 0;
    }

    @for $i from 1 through 6 {
      @include hsvs.view('h#{$i}') {
        font-size: var(
          --aza-markdown-h#{$i}-font-size,
          2em - ($i - 1) * 0.25em
        );
        line-height: var(--aza-markdown-h#{$i}-line-height, 1.2);
      }
    }

    @include hsvs.view('blockquote') {
      margin-right: 0;
      margin-left: 0;
      padding-left: 15px;

      border-left: var(--aza-markdown-blockquote-border, 5px solid #ddd);
    }

    @include hsvs.view('codePre') {
      padding: 10px;

      background-color: var(--aza-markdown-code-pre-bg-color, #f5f5f5);
    }

    @include hsvs.view('tableWrapper') {
      width: 100%;
      overflow: auto;
    }

    @include hsvs.view('table') {
      min-width: var(--aza-markdown-table-min-width, 100%);
      border-collapse: collapse;
    }

    @include hsvs.view('tableHead') {
      font-weight: var(--aza-markdown-table-head-font-weight, 700);
    }

    @include hsvs.view('tableCell') {
      padding: 0.5em;

      border: var(--aza-markdown-table-cell-border, none);
    }
  }
}
