@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'advertisePageNumbersSection') {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0 150px;
  gap: 25px;

  color: var(--aza-text-02-color);

  @include hsvs.view('titleContent') {
    display: grid;
    grid-template-rows: 130px;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-rows: 100px;
    }
  }

  @include hsvs.view('title') {
    @include typography.font('h2-mobile', 'semi-bold');

    color: var(--aza-text-01-color);
    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('subheader') {
    @include typography.font('body-xxl', 'semi-bold');

    max-width: 985px;

    color: var(--aza-text-02-color);
    text-align: center;
  }

  @include hsvs.view('viewers') {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px;
    width: 100%;
    min-width: 330px;
    max-height: 179px;

    border-radius: 12px;

    background: linear-gradient(
      273.86deg,
      #2c51bd 0%,
      #543ba1 50.18%,
      #583488 97.32%
    );

    @include mixins.media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
      max-width: 1260px;
      min-height: 162px;
    }
  }

  @include hsvs.view('viewersCountIcon') {
    position: relative;
    top: -18px;
    left: 25px;
    max-height: 78px;
    overflow: visible;

    @include mixins.media-breakpoint-up(xl) {
      top: -22px;
      left: 122px;
      max-height: 146px;
    }
  }

  @include hsvs.view('viewersDescription') {
    @include typography.font('h4-mobile', 'semi-bold');

    padding: 0 25px;

    color: var(--aza-base-02-color);
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;

    @include mixins.media-breakpoint-up(md) {
      padding-left: 25px;

      text-align: center;
    }

    @include mixins.media-breakpoint-up(xl) {
      @include typography.font('h3', 'semi-bold');

      padding: 23px 80px 0 0;

      font-size: 40px;
    }
  }

  @include hsvs.view('numbersContainer') {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 14px;

    @include mixins.media-breakpoint-up('lg') {
      gap: 20px;

      max-width: 1260px;
    }
  }

  @include hsvs.view('numbersFirstLine') {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 14px;
    grid-template-columns: 1fr;
    min-height: 156px;

    @include mixins.media-breakpoint-up('lg') {
      grid-column-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include hsvs.view('numbersSecondLine') {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 14px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    min-height: 156px;

    @include mixins.media-breakpoint-up('lg') {
      grid-template-columns: repeat(3, 1fr);
      min-height: 188px;
    }
  }

  @include hsvs.view('numbersCard') {
    display: grid;
    grid-gap: 14px;
    grid-template-rows: repeat(2, 1fr);
    padding: 30px 5px 30px 5px;

    border-radius: 12px;

    background: #edeefb;
  }

  @include hsvs.view('numbers') {
    @include typography.font('h3', 'extra-bold');

    margin: 0;

    font-size: 54px;
    text-align: center;
  }

  @include hsvs.view('numbersDescription') {
    @include typography.font('body-xxl', 'regular');

    margin: 0;

    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
  }
}
