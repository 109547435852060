@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../../../core/src/styles/base-classes/links';
@use '../../../../../../../core/src/styles/mixins' as coreMixins;

@use '../../../../../styles/abstracts/mixins';

@keyframes email-sent-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@include hsvs.host('aza', 'loginForm') {
  display: grid;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 200px;

  @include hsvs.view('formTitle') {
    @include typography.font('h2', 'semi-bold');

    margin-bottom: 40px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h1', 'semi-bold');
    }
  }

  @include hsvs.view('formDescription') {
    margin-bottom: 28px;
  }

  @include hsvs.view('fieldIcon') {
    opacity: 0.5;
  }

  @include hsvs.view('formActions') {
    margin-top: 30px;
  }

  @include hsvs.view('emailSentImageWrapper') {
    max-width: 280px;
    margin: 0 auto;
  }

  @include hsvs.view('emailSentImageContainer') {
    @include coreMixins.fixed-ratio-image-container(1, 1);

    animation: email-sent-animation 3s infinite;
  }

  @include hsvs.view('passCodeField') {
    @include typography.font('body-l');

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-xxl');
    }
  }

  @include hsvs.view('retryText') {
    margin-top: 30px;
  }

  @include hsvs.view('linkButton') {
    @extend %aza-link-button;
  }

  @include hsvs.view('failureMessage') {
    @include typography.font('body-s', 'semi-bold');

    margin-top: 20px;

    color: var(--aza-error-color);
  }

  @include hsvs.view('formHelp') {
    margin-top: 20px;

    font-weight: typography.font-weight('light');
  }

  @include hsvs.view('submit') {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 190px;
    margin: 0 auto;
    column-gap: 15px;

    @include hsvs.state((), (), [loading]) {
      @include coreMixins.loading-indicator-background();
    }
  }

  @include hsvs.view('strong') {
    font-weight: typography.font-weight('semi-bold');
  }
}
