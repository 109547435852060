@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'socialSection') {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 100px;
  row-gap: 50px;

  @include hsvs.view('title') {
    max-width: 510px;
    margin: 0;

    font-size: clamp(38px, 7vw, 50px);
    font-weight: 600;
    line-height: clamp(52px, 7vw, 60px);
    text-align: center;

    @include mixins.media-breakpoint-up('md') {
      text-align: left;
    }
  }

  @include hsvs.view('accept') {
    position: relative;

    color: var(--aza-accent-color);
    text-wrap: nowrap;
  }

  @include hsvs.view('underline') {
    position: absolute;
    right: 0;
    bottom: -10%;
    left: 0;
    width: 100%;
    height: 18px;
  }

  @include hsvs.view('discordButton') {
    --discord-button-size: 156px;
    --discord-button-icon-size: 80px;
  }

  @include hsvs.view('discordButtonContent') {
    @include typography.font('body-s', 'semi-bold');
  }
}
