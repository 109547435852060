@use '../../../../cdk/src/hsvs/hsvs';

@use '../../styles/typography';

@mixin select-base($encapsulated: false) {
  %_select-icon {
    width: 20px;
    height: 20px;
  }

  %_content {
    flex: 1;
    min-width: 0;
    margin-right: 4px;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include hsvs.host('aza', 'select', $encapsulated) {
    display: inline-block;

    @include hsvs.view('dropdownTrigger') {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      width: 100%;
      min-width: 150px;
      height: var(--aza-selector-height, 56px);
      padding: 5px 16px;

      transition:
        color 0.3s,
        background-color 0.3s,
        border-color 0.3s;

      border-width: 2px;
      border-style: solid;
      border-radius: 10px;
      border-color: transparent;

      background-color: rgba(0, 0, 0, 0);

      color: var(--aza-text-01-color);
      text-align: left;

      cursor: pointer;

      @include hsvs.state([appearance, flat]) {
        background-color: var(--aza-base-03-alpha-color);
      }

      @include hsvs.state([appearance, flat], (), (':hover')) {
        background-color: var(--aza-base-03-hover-alpha-color);
      }

      @include hsvs.state([appearance, flat], (), (':focus')) {
        border-color: var(--aza-text-01-color);
      }

      @include hsvs.state([appearance, flat], (), (':disabled')) {
        opacity: 0.5;

        cursor: default;
      }

      @include hsvs.state([appearance, bare], (), (':hover')) {
        background-color: var(--aza-base-03-hover-alpha-color);
      }

      @include hsvs.state([appearance, bare], (), (':disabled')) {
        opacity: 0.5;

        cursor: default;
      }
    }

    @include hsvs.view('dropdownTriggerContent') {
      @extend %_content;
    }

    @include hsvs.view('dropdownTriggerArrow') {
      @extend %_select-icon;

      transform: rotate3d(0, 0, 0, 0);
      transition: 0.25s transform ease-out;

      color: var(--aza-text-01-color);

      @include hsvs.state(
        (),
        (
          dropdownTrigger: [opened],
        ),
        ()
      ) {
        transform: rotate3d(1, 0, 0, 180deg);
      }
    }

    @include hsvs.view('optionContent') {
      @extend %_content;
    }

    @include hsvs.view('label') {
      @include typography.font('body-s', 'light');

      opacity: 0.7;

      @include hsvs.state([isSelected]) {
        transform: translateX(-6.25%) scale(0.875);

        line-height: 0.875;
      }
    }

    @include hsvs.view('value') {
      @include typography.font('body-m');
      overflow: hidden;

      color: var(--aza-text-01-color);
      text-overflow: ellipsis;
    }

    @include hsvs.view('optionsContainer') {
      position: relative;
      height: 100%;
      overflow-y: auto;

      border-radius: 10px;

      background-color: var(--aza-base-01-color);
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    }

    @include hsvs.view('container') {
      padding: 10px 0;

      background-color: var(--aza-base-03-alpha-color);
    }

    @include hsvs.view('option') {
      display: flex;
      align-items: center;
      padding: 10px 16px;

      transition: 0.1s background-color;

      background-color: transparent;

      color: var(--aza-text-01-color);

      cursor: pointer;

      @include hsvs.state((), (), (':hover')) {
        background-color: var(--aza-base-03-hover-alpha-color);
      }
    }

    @include hsvs.view('optionCheckmark') {
      @extend %_select-icon;
    }
  }
}
