@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'advertisePageStudiesSection') {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;

  padding: 145px 0;
  column-gap: 34px;
  row-gap: 70px;

  @include mixins.media-breakpoint-up('xl') {
    grid-template-columns: 50% 50%;
    grid-template-rows: 60px 1fr;
    align-items: start;
    justify-items: start;

    max-width: 1260px;
  }

  @include hsvs.view('title') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);
    text-align: center;

    @include mixins.media-breakpoint-up('xl') {
      @include typography.font('h2', 'semi-bold');

      text-align: start;
    }
  }

  @include hsvs.view('sliderBulletsContainer') {
    display: flex;
    gap: 10px;

    width: 100%;
    max-width: 619px;
  }

  @include hsvs.view('sliderBullets') {
    cursor: pointer;
  }

  @include hsvs.view('sliderButton') {
    width: 100%;
    height: 100%;
  }

  @include hsvs.view('streamerImage') {
    width: 100%;

    @include mixins.media-breakpoint-up('xl') {
      max-width: 590px;

      border-radius: 12px;
    }
  }

  @include hsvs.view('contentWrapper') {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 34px;

    column-gap: 34px;

    @include mixins.media-breakpoint-up('xl') {
      grid-column-end: 3;
      grid-column-start: 1;
      grid-template-columns: 1fr 1fr;
      min-height: 687px;
    }
  }

  @include hsvs.view('imageWrapper') {
    @include mixins.media-breakpoint-up('xl') {
      grid-row-end: 5;
      grid-row-start: 1;
    }
  }

  @include hsvs.view('contentTitle') {
    @include typography.font('h6', 'semi-bold');

    margin: 0;

    color: var(--aza-text-02-color);
    text-align: center;

    @include mixins.media-breakpoint-up('xl') {
      text-align: start;
    }
  }

  @include hsvs.view('subheader') {
    @include typography.font('body-xl', 'semi-bold');

    margin: 0;

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('text') {
    @include typography.font('body-l', 'light');

    color: var(--aza-text-01-color);
  }

  @include hsvs.view('numbersContainer') {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));

    gap: 25px;

    width: 100%;

    @include mixins.media-breakpoint-up('xl') {
      max-width: 568px;
      gap: 90px;
    }
  }

  @include hsvs.view('numbersCard') {
    display: flex;
    flex-direction: column;

    gap: 7px;
  }

  @include hsvs.view('numbers') {
    @include typography.font('h3', 'extra-bold');

    margin: 0;

    color: var(--aza-text-02-color);
    font-size: 40px;
    text-align: center;

    @include mixins.media-breakpoint-up('xl') {
      @include typography.font('h2', 'extra-bold');
      line-height: 50px;
    }
  }

  @include hsvs.view('numbersDescription') {
    @include typography.font('body-s', 'semi-bold');

    margin: 0;

    color: var(--aza-text-02-color);
    text-align: center;

    @include mixins.media-breakpoint-up('xl') {
      @include typography.font('body-l', 'semi-bold');

      line-height: 18px;
    }
  }
}
