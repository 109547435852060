@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

%_flex_gup {
  gap: 18px;
}

@include hsvs.host('aza', 'questLayout') {
  @extend %_flex_gup;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 22px;

  border-radius: 5px;

  background-color: #292a2d;

  @include hsvs.view('wrapper') {
    @extend %_flex_gup;

    display: flex;
    flex: 10;
    align-items: center;
  }

  @include hsvs.view('title') {
    @include typography.font('body-m');

    margin: 0;

    text-wrap: nowrap;
  }

  @include hsvs.view('prize') {
    @include typography.font('body-xs');
  }

  @include hsvs.view('action') {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    margin-left: auto;
  }
}
