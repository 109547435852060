@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'triviaGameCard') {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 10px;
  overflow: hidden;

  transition: box-shadow 150ms;

  border-radius: 10px;

  background-color: var(--aza-base-02-color);

  gap: 12px 20px;

  @include mixins.media-breakpoint-up('md') {
    padding: 20px;
  }

  @include hsvs.state(([selected])) {
    box-shadow: 0 0 0 2px inset var(--aza-success-color);
  }

  @include hsvs.state(([readonly])) {
    box-shadow: none;
  }

  @include hsvs.view('overlay') {
    z-index: 0;
    grid-area: 1/1 / 4/3;
    margin: -20px;

    transition: background-color 150ms;

    @include hsvs.state(([selected])) {
      background-color: rgba(39, 234, 199, 0.08);
    }

    @include hsvs.state(([readonly])) {
      background: none;
    }
  }

  @include hsvs.view('overlaySelect') {
    z-index: 2;
    grid-area: 1/1 / 4/3;
    margin: -20px;

    cursor: default;
  }

  @include hsvs.view('headerContent') {
    z-index: 1;
    grid-area: 1/1 / 2/2;
    min-height: 25px;
    margin: 0;
  }

  @include hsvs.view('action') {
    display: flex;
    z-index: 3;
    grid-area: 1/2 / 2/3;
    column-gap: 10px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('duration') {
    @include typography.font('body-xxs', 'light');

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @include hsvs.view('time') {
    font-size: 13px;
    font-weight: 600;
  }

  @include hsvs.view('btn') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    border: none;
    border-radius: 5px;

    background-image: linear-gradient(
      273.86deg,
      rgba(44, 81, 189, 1),
      rgba(84, 59, 161, 1),
      rgba(88, 52, 136, 1)
    );

    cursor: pointer;
  }

  @include hsvs.view('bodyContent') {
    z-index: 1;
    grid-area: 2/1 / 3/3;
    margin: 0;
  }

  @include hsvs.view('footerContent') {
    z-index: 3;
    grid-area: 3/1 / 4/3;
    margin-top: auto;
  }

  @include hsvs.view('icon') {
    --aza-svg-icon-size: 14px;

    color: var(--aza-light-stable-color);
  }
}
