@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../../../core/src/styles/mixins' as coreMixins;

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'shopItemPurchaseDialog') {
  display: block;

  @include hsvs.view('content') {
    @include typography.font('body-m', 'semi-bold');

    display: grid;
    grid-template-areas:
      'poster title'
      'description description';
    grid-template-columns: 69px 1fr;
    gap: 16px;

    @include mixins.media-breakpoint-up(lg) {
      grid-template-areas:
        'poster title'
        'poster description';
      grid-template-columns: 323px 1fr;
      grid-template-rows: auto 1fr;
      gap: 0 32px;
    }
  }

  @include hsvs.view('poster') {
    @include coreMixins.fixed-ratio-image-container(
      323,
      392,
      $objectPosition: top
    );

    grid-area: poster;
  }

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    grid-area: title;
    margin: 14px 0;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('descriptionSection') {
    display: flex;
    grid-area: description;
    flex-direction: column;
  }

  @include hsvs.view('noticeSection') {
    @include typography.font('body-s', 'regular');

    margin-top: auto;
  }

  @include hsvs.view('actionsSection') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    row-gap: 16px;
  }

  @include hsvs.view('loginTitle') {
    @include typography.font('body-xl', 'semi-bold');

    margin-bottom: 16px;

    color: var(--aza-success-color);
    text-align: center;
  }

  @include hsvs.view('locationWarning') {
    margin: 18px 0;

    color: var(--aza-text-02-color);
    @include typography.font('body-s', 'regular');
  }

  @include hsvs.view('divider') {
    height: 1px;
    margin: 12px 0 16px 0;

    background-color: var(--aza-base-03-alpha-color);
  }

  @include hsvs.view('criticalWarning') {
    width: 100%;
  }

  @include hsvs.view('criticalWarningMessage') {
    color: var(--aza-text-02-color);
  }

  @include hsvs.view('mainActionButton') {
    width: 100%;
  }

  @include hsvs.view('shopIcon') {
    display: inline-block;
    margin-right: 12px;

    vertical-align: sub;
  }

  @include hsvs.view('userBalance') {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    column-gap: 18px;
  }

  @include hsvs.view('loaderContainer') {
    display: flex;
    justify-content: center;
  }

  @include hsvs.view('balanceControl') {
    display: grid;
    gap: 15px 0;
  }

  @include hsvs.view('loader') {
    width: 56px;
  }

  @include hsvs.view('paypalDisabledWarning') {
    @include typography.font('body-s', 'semi-bold');
  }

  @include hsvs.view('cryptoButton') {
    position: relative;
  }

  @include hsvs.view('buttonLoader') {
    --aza-loader-color-1: white;
    --aza-loader-color-2: white;
    --aza-loader-color-3: #ffffff00;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
}
