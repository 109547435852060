@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'tokenomicsSection') {
  display: block;
  margin-top: 120px;

  $top-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(0, 102, 255, 0.2),
        rgba(0, 102, 255, 0)
      ),
    size: 639px 530px,
    offset-x: 300px,
    offset-y: 100px,
  );
  $bottom-gradient: (
    gradient:
      radial-gradient(
        closest-side,
        rgba(204, 0, 177, 0.3),
        rgba(204, 0, 176, 0)
      ),
    size: 680px 480px,
    offset-x: 300px,
    offset-y: 100px,
  );
  $gradient-inflation: 200px;

  @include mixins.gradient-background(
    $top-gradient,
    $bottom-gradient,
    calc(50%),
    calc(25%),
    $gradient-inflation,
    0.75
  );

  @include hsvs.view('sectionTitle') {
    @include typography.font('h2-mobile', 'semi-bold');

    margin: 0;
    margin-bottom: 22px;

    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h2', 'semi-bold');
    }
  }

  @include hsvs.view('sectionSabTitle') {
    max-width: 500px;
    margin: 0 auto 54px auto;

    text-align: center;
  }

  @include hsvs.view('charts') {
    display: grid;
    grid-template-columns: 1fr;

    gap: 40px;

    @include mixins.media-breakpoint-up('xl') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include hsvs.view('chartDataSection') {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @include mixins.media-breakpoint-up('md') {
      grid-template-columns: 1fr auto;
    }
  }

  @include hsvs.view('chartTitle') {
    margin: 0;
    margin-bottom: 40px;

    text-align: center;

    @include typography.font('h5', 'semi-bold');
  }

  @include hsvs.view('imageWrapperCircleChart') {
    display: none;

    @include mixins.media-breakpoint-up('sm') {
      display: block;
      max-width: 758px;
    }
  }

  @include hsvs.view('imageContainerCircleChart') {
    @include mixins.fixed-ratio-image-container(758, 484);
  }

  @include hsvs.view('imageWrapperCircleChartSmall') {
    display: grid;

    gap: 30px 0;

    @include mixins.media-breakpoint-up('sm') {
      display: none;
    }
  }

  @include hsvs.view('chartCircleDataList') {
    display: grid;
    gap: 10px 30px;

    grid-template-columns: repeat(2, 1fr);
  }

  @include hsvs.view('imageContainerCircleChartSmall') {
    @include mixins.fixed-ratio-image-container(330, 330);
  }

  @include hsvs.view('imageWrapperLinerChart') {
    max-width: 758px;
  }

  @include hsvs.view('imageContainerLinerChart') {
    @include mixins.fixed-ratio-image-container(504, 360);
  }

  @include hsvs.view('chartDataList') {
    display: flex;
    row-gap: 10px;

    flex-direction: column;
    align-items: flex-start;
  }

  @include hsvs.view('chartData') {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  @include hsvs.view('square') {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;

    border-radius: 2px;

    @include hsvs.state((), (), [shadow]) {
      box-shadow: 0 0 0 1px inset white;
    }
  }

  @include hsvs.view('chartDataDescription') {
    @include typography.font('body-m', 'semi-bold');

    max-width: 200px;
  }

  @include hsvs.view('action') {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  @include hsvs.view('link') {
    display: flex;
    align-items: center;
    margin: 0 auto;
    column-gap: 15px;
  }
}
