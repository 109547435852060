@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'news') {
  display: block;

  --new-card-max-size: 472px;
  --new-cards-column-gap: 20px;
  --max-container-width: calc(
    var(--new-card-max-size) * 3 + var(--new-cards-column-gap) * 2
  );

  @include hsvs.view('title') {
    margin: 0 0 55px 0;

    font-size: clamp(38px, 7vw, 50px);
    font-weight: 600;
    line-height: clamp(52px, 7vw, 60px);
    text-align: center;
  }

  @include hsvs.view('newsList') {
    display: grid;
    grid-template-columns: repeat(3, minmax(315px, 1fr));
    max-width: var(--max-container-width);
    margin: 0 auto;
    padding: 0 15px 15px;
    overflow: auto;
    gap: var(--new-cards-column-gap);
  }

  @include hsvs.view('action') {
    display: flex;
    max-width: var(--max-container-width);
    margin: 0 auto;
    padding: 0 15px;
  }

  @include hsvs.view('link') {
    @extend %aza-link;

    margin-left: auto;
  }
}
