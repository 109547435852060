@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';

%_paragraph {
  @include typography.font('body-m');
}

@include hsvs.host('aza', 'purchaseSuccessDialog') {
  @include typography.font('body-m', 'regular');

  color: var(--aza-text-01-color);
  text-align: center;

  @include hsvs.view('title') {
    @include typography.font('h6-mobile', 'semi-bold');

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h6', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @extend %_paragraph;

    margin: 20px 0 30px;

    @include mixins.media-breakpoint-up(lg) {
      margin: 30px 0 50px;
    }
  }

  @include hsvs.view('purchaseCode') {
    margin: 65px 0;
  }

  @include hsvs.view('hint') {
    @extend %_paragraph;

    margin: 30px 0 10px;

    @include mixins.media-breakpoint-up(lg) {
      margin: 55px auto 30px;
    }
  }

  @include hsvs.view('emphasizedLink') {
    @extend %aza-dialog-link-with-underline;

    @include typography.font('body-m', 'semi-bold');
  }
}
