@use '../../../../cdk/src/hsvs/hsvs';

@mixin currency-amount-base($encapsulated: false) {
  @include hsvs.host('aza', 'currencyAmount', $encapsulated) {
    display: inline-flex;
    align-items: baseline;

    line-height: 1;

    @include hsvs.view('floatingPart') {
      font-size: 0.6em;
    }
  }
}
