@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'classicTriviaQuestionSettings') {
  display: grid;
  grid-template-rows: auto auto 1fr;

  @include hsvs.view('title') {
    @include typography.font('body-xl', 'semi-bold');
  }

  @include hsvs.view('settings') {
    display: grid;
    justify-content: start;
    gap: 30px;
  }

  @include hsvs.view('duration') {
    display: grid;
    max-width: 600px;
  }

  @include hsvs.view('action') {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  @include hsvs.view('button') {
    min-width: 150px;
  }

  @include hsvs.view('btnBack') {
    @include typography.font('body-m', 'semi-bold');

    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    transition: color 150ms;

    border: none;
    outline: none;

    background: none;

    color: var(--aza-link-normal-color);

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      color: var(--aza-link-hover-color);
    }
  }

  @include hsvs.view('check') {
    justify-content: start;
  }
}
