@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaCoinUtilityFeature') {
  display: block;

  @include hsvs.view('title') {
    @include typography.font('h4-mobile', 'semi-bold');
    display: flex;
    align-items: center;
    margin: 0 0 22px;
    gap: 24px;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h4', 'semi-bold');
    }
  }

  @include hsvs.view('description') {
    @include typography.font('body-l', 'light');
  }
}
