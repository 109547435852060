@use '../../../../cdk/src/hsvs/hsvs';

@mixin slide-toggle-base($encapsulated: false) {
  %_svg {
    z-index: 1;
    height: 100%;
  }

  @include hsvs.host('aza', 'slideToggle', $encapsulated) {
    --slide-toggle-padding: 2px;

    --slide-toggle-width: 16px;

    display: inline-flex;
    position: relative;
    align-items: center;
    height: 20px;
    padding: 0;
    overflow: hidden;
    isolation: isolate;

    border: none;
    border-radius: 12px;

    background: none;
    box-shadow: none;

    text-decoration: none;

    cursor: pointer;

    @include hsvs.state([disabledState]) {
      opacity: 0.5;

      cursor: default;
    }

    @include hsvs.state('::before') {
      $size: -2px;

      content: '';

      display: block;
      position: absolute;
      z-index: 0;
      top: $size;
      right: $size;
      bottom: $size;
      left: $size;

      transition: opacity 250ms;

      opacity: 0.5;
      background-color: var(--aza-link-normal-color);
    }

    @include hsvs.state(([activeState] '::before')) {
      opacity: 1;
    }

    @include hsvs.view('toggle') {
      position: absolute;
      z-index: 3;
      left: var(--slide-toggle-padding);
      width: var(--slide-toggle-width);
      height: calc(100% - var(--slide-toggle-padding) * 2);

      transition: left 200ms;

      border-radius: 50%;

      background-color: var(--aza-base-02-color);

      @include hsvs.state([activeState]) {
        left: calc(
          100% - var(--slide-toggle-width) - var(--slide-toggle-padding)
        );
      }
    }

    @include hsvs.view('svgMarck') {
      @extend %_svg;

      color: var(--aza-base-02-color);
    }

    @include hsvs.view('svgCircle') {
      @extend %_svg;

      color: var(--aza-inactive-alpha-color);
    }
  }
}
