@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/header';
@use '../../../styles/abstracts/variables';

$desktop-menu-active-font-weight: typography.font-weight('regular');

@include hsvs.host('aza', 'landingHeader') {
  display: block;

  @include hsvs.view('header') {
    @include hsvs.state((), (), [clear]) {
      @extend %aza-header-transparent;
    }
  }

  @include hsvs.view('nav') {
    display: none;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      display: flex;
      align-self: normal;
    }
  }

  @include hsvs.view('userMobMenu') {
    margin-bottom: 8px;
    padding: 0 14px;
  }

  @include hsvs.view('navItem') {
    @extend %aza-nav-link;

    align-items: center;
    justify-content: center;
    padding: 0 10px;
    column-gap: 10px;

    transition: background-color 0.25s ease-in-out;

    @include typography.font('body-xxs');

    @include hsvs.state((), (), (':hover')) {
      background-color: rgba(220, 2, 93, 0.15);
    }

    // This pseudoelement is used for preventing the change of the menu elements width
    // due to change of the font weight then the item is active

    @include hsvs.state((), (), ([active])) {
      @extend %aza-nav-link--active;

      background-color: rgba(220, 2, 93, 0.15);
    }

    @include mixins.media-breakpoint-up('sm') {
      @include typography.font('body-m');
    }

    @include mixins.media-breakpoint-up('xl') {
      padding: 0 24px;

      font-weight: $desktop-menu-active-font-weight;
    }
  }

  @include hsvs.view('profileMenu') {
    display: none;

    @include mixins.media-breakpoint-up(variables.$mobile-menu-breakpoint) {
      display: flex;
    }
  }

  @include hsvs.view('authButton') {
    margin-left: 10px;
  }
}
