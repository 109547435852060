%placeholder-loader-stub {
  background-color: var(--aza-card-stub-alpha-color);
}

%placeholder-loader-stub-line {
  @extend %placeholder-loader-stub;

  border-radius: 5px;
}

%placeholder-loader-stub-circle {
  @extend %placeholder-loader-stub;

  border-radius: 50%;
}
