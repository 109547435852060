@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';
@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/base-classes/common';

%_with-margin-top {
  margin-top: 10px;
}

%_verification-link {
  @extend %_with-margin-top;

  @extend %aza-dialog-link-with-underline;

  @include typography.font('body-s', 'semi-bold');

  @include mixins.media-breakpoint-up('lg') {
    @include typography.font('body-m', 'semi-bold');
  }
}

@include hsvs.host('aza', 'accountVerification') {
  display: flex;

  @include hsvs.view('content') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include hsvs.view('verificationSentence') {
    @include typography.font('body-m', 'semi-bold');

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-xl', 'semi-bold');
    }
  }

  @include hsvs.view('listItem') {
    padding-left: 0.8em;
  }

  @include hsvs.view('statusLink') {
    @extend %_verification-link;
  }

  @include hsvs.view('verificationLink') {
    @extend %_verification-link;

    display: block;
  }

  @include hsvs.view('verificationLinkDoc') {
    @extend %_verification-link;

    display: block;

    @include typography.font('body-xs', 'regular');

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('body-s', 'regular');
    }
  }
}
