@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'questPlayOnTwitch') {
  display: block;

  @include hsvs.view('discordButton') {
    @include typography.font('body-xs', 'semi-bold');

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 9px 12px;
    column-gap: 8px;

    transition: background-color 150ms;

    border-radius: 2px;

    background-color: #5865f2;

    color: var(--aza-text-01-color);
    text-decoration: none;

    cursor: pointer;

    @include hsvs.state((), (), (':hover')) {
      background-color: #4e5beb;
    }
  }

  @include hsvs.view('discordButtonTitle') {
    text-wrap: nowrap;
  }
}
