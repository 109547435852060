@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';
@use '../../../../../core/src/styles/base-classes/links';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/titles';
@use '../../../styles/abstracts/base-classes/common';

%content-grid {
  display: grid;
  grid-gap: 40px 80px;
  grid-template-columns: 1fr;

  @include mixins.media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
  }
}

@include hsvs.host('aza', 'dataCollectionPermissionsPage') {
  @extend %aza-container-responsive;
  display: block;
  padding-bottom: 90px;

  @include hsvs.view('title') {
    @extend %aza-title;
  }

  @include hsvs.view('optionsGrid') {
    @extend %content-grid;
  }

  @include hsvs.view('blockTitle') {
    @include typography.font('body-l', 'semi-bold');
    display: flex;
    margin: 0 0 22px;
    gap: 18px;
  }

  @include hsvs.view('blockTitleText') {
    flex: 1;
  }

  @include hsvs.view('permissionItem') {
    padding: 22px 0;

    border-top: 1px solid var(--aza-base-03-alpha-color);

    @include hsvs.state((), (), ':last-child') {
      border-bottom: 1px solid var(--aza-base-03-alpha-color);
    }
  }

  @include hsvs.view('controlsGrid') {
    @extend %content-grid;
    margin-top: 40px;

    @include mixins.media-breakpoint-up(lg) {
      margin-top: 24px;
    }
  }

  @include hsvs.view('deleteHeader') {
    @include typography.font('body-m', 'regular');
    color: var(--aza-text-01-color);
  }

  @include hsvs.view('deleteLink') {
    @extend %aza-link;
    @include typography.font('body-m', 'semi-bold');
    margin-top: 4px;

    cursor: pointer;
  }
}
