@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'aboutAzarusPageHero') {
  display: block;

  @include hsvs.view('content') {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 0 100px;

    text-align: center;

    @include mixins.media-breakpoint-up(md) {
      padding: 150px 0;
    }

    @include mixins.media-breakpoint-up(lg) {
      padding: 335px 0;
    }
  }

  @include hsvs.view('title') {
    @include typography.font('h1-mobile', 'semi-bold');
    margin: 0 0 20px;

    @include mixins.media-breakpoint-up(lg) {
      @include typography.font('h1', 'semi-bold');
      margin: 0 0 25px;
    }
  }

  @include hsvs.view('titleHighlight') {
    color: var(--aza-accent-color);
  }

  @include hsvs.view('description') {
    @include typography.font('body-xl', 'light');
    max-width: 912px;
    margin: 0 auto;
  }
}
