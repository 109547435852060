@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/functions';
@use '../../../styles/abstracts/base-classes/common';

@include hsvs.host(aza, verticalMenuItem) {
  display: block;

  @include hsvs.view('navItem') {
    @include typography.font('body-m');
    @extend %aza-vertical-nav-item-spacing;
    display: flex;
    align-items: center;
    gap: 14px;

    transition: background-color 0.2s;

    background-color: transparent;

    color: var(--aza-text-02-color);
    text-decoration: none;

    @include hsvs.state((), (), (':hover')) {
      background-color: var(--aza-base-01-color);
    }

    @include hsvs.state((), (), ([active])) {
      background: linear-gradient(
        273.86deg,
        #2c51bd 0%,
        #543ba1 50.18%,
        #583488 97.32%
      );

      color: var(--aza-light-stable-color);
      font-weight: typography.font-weight('semi-bold');
    }
  }
}
