@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/variables';
@use '../../../../../../../core/src/styles/typography';

%_element-absolute {
  display: inline-flex;
  position: absolute;
  width: clamp(196px, 30vw, 349px);

  transform: translateY(0%);
  perspective: 1000px;
  transition: transform 50ms;

  cursor: pointer;
}

@include hsvs.host('aza', 'sectionSpend') {
  --element-transition-value: 0%;

  --index: calc(1vh + 1vh);

  display: grid;
  grid-template-rows: repeat(2, calc(100vh - var(--media-offset)));

  @include hsvs.view('sectionSticky') {
    display: grid;
    position: sticky;
    z-index: 1;
    top: 0;
    grid-area: 1 /1 / 2/2;
  }

  @include hsvs.view('scrollTrigger') {
    @include hsvs.state((), (), ([first])) {
      grid-area: 1/1 / 2/2;
    }
  }

  @include hsvs.view('content') {
    display: flex;
    position: relative;
    align-items: flex-start;

    text-align: left;

    @include mixins.media-breakpoint-up('lg') {
      align-items: center;
    }
  }

  @include hsvs.view('title') {
    z-index: 3;
    margin: 0;
    margin-top: 100px;
    margin-bottom: 300px;

    font-size: clamp(38px, 7vw, 50px);
    font-weight: 600;
    line-height: clamp(52px, 7vw, 60px);

    filter: drop-shadow(2px 4px 6px rgba(6, 0, 18, 0.5));
  }

  @include hsvs.view('accept') {
    position: relative;

    color: var(--aza-accent-color);
    text-wrap: nowrap;
  }

  @include hsvs.view('underline') {
    position: absolute;
    right: 0;
    bottom: -10%;
    left: 0;
    width: 100%;
    height: 18px;
  }

  @include hsvs.view('latestGame') {
    @extend %_element-absolute;

    top: 35%;
    left: 40%;

    transform: translateY(calc(var(--element-transition-value) * 0.1));

    @include mixins.media-breakpoint-up('lg') {
      top: 10%;
      left: 50%;

      transform: translateY(calc(var(--element-transition-value) * 0.06));
    }
  }

  @include hsvs.view('retroFavorite') {
    @extend %_element-absolute;

    top: clamp(65%, 80vw, 70%);
    left: 2%;

    transform: translateY(calc(var(--element-transition-value) * 0.08));
  }

  @include hsvs.view('storeGift') {
    @extend %_element-absolute;

    top: clamp(80%, 80vw, 95%);
    right: 0;

    transform: translateY(calc(var(--element-transition-value) * 0.12));
  }

  @include hsvs.view('posterContent') {
    display: flex;
    position: relative;
    justify-content: center;
    inset: 0;

    transition: transform 300ms ease;

    box-shadow: 11.82px 11.82px 59.12px 5.91px rgba(6, 0, 18, 0.5);

    @include hsvs.state((), (), (':hover')) {
      transform: rotate3d(0.5, 0, 0.1, 5deg);
    }
  }

  @include hsvs.view('image') {
    width: 100%;
  }

  @include hsvs.view('cardTitle') {
    position: absolute;
    bottom: 5%;
    width: 65%;
  }
}
