@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';

%_wrapped-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 30px;
}

@include hsvs.host('aza', 'streamSessionInfo') {
  display: block;

  @include hsvs.view('infoCard') {
    padding: 9px 15px;

    border-radius: 10px;

    background-color: var(--aza-primary-color);

    @include mixins.media-breakpoint-up(lg) {
      padding: 9px 34px;
    }
  }

  @include hsvs.view('infoCardContent') {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    column-gap: 30px;
  }

  @include hsvs.view('streamInfo') {
    @extend %_wrapped-flex;

    max-width: 100%;
  }

  @include hsvs.view('platformInfoWrapper') {
    max-width: 100%;
  }

  @include hsvs.view('nameOnPlatform') {
    @include typography.font('body-xl', 'semi-bold');

    max-width: 100%;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include hsvs.view('streamSessionStats') {
    @extend %_wrapped-flex;
    @include typography.font('body-m', 'semi-bold');
  }

  @include hsvs.view('channelStatusIndicator') {
    @include typography.font('body-xs', 'light');

    position: relative;
    margin-left: auto;

    @include hsvs.state((), (), ([live])) {
      color: var(--aza-accent-color);

      &::before {
        content: ' ';

        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 10px;

        transform: translate(-150%, -50%);

        border-radius: 50%;

        background-color: var(--aza-accent-color);
      }
    }
  }
}
