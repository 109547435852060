@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/titles';

@include hsvs.host('aza', 'loginPage') {
  display: block;

  text-align: center;

  @include hsvs.view('extensionDescription') {
    @include typography.font('body-l');
    max-width: 520px;
    margin: 30px auto;
  }

  @include hsvs.view('supportedBrowsers') {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 40px;

    justify-content: center;
    margin: 30px 0;
  }

  @include hsvs.view('extensionLinkContainer') {
    margin: 30px 0;
  }
}
