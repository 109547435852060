@use 'sass:map';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1921px,
);

$layers: 'elevated-content', 'new-feed-items-button', 'discord-button',
  'scroll-top-button', 'header', 'banner';
$mobile-padding: 16px;
$desktop-padding: 40px;
$mobile-header-height: 60px;
$desktop-header-height: 80px;
$container-padding: clamp(#{$mobile-padding}, 2.5vw, #{$desktop-padding});
$container-responsive-padding: calc(
  #{$mobile-padding} + min(max(0px, (
            100vw - #{map.get($grid-breakpoints, 'sm')}
          ) * 100000), #{$desktop-padding - $mobile-padding})
);
$max-container-width: 1702px;
$container-fluid-width: calc(100% - #{$container-padding} * 2);
$container-responsive-width: calc(
  100% - (#{$container-responsive-padding} * 2)
);
$restricted-container-responsive-width: min(
  #{$container-responsive-width},
  #{$max-container-width}
);
$mobile-menu-breakpoint: 'xl';
$panel-border-radius: 5px;
$card-border-radius: 10px;
// stylelint-disable scss/no-global-function-names
// sidebar margin needs to match the dynamic margin of the of the page which uses container
// it has to be dynamically calculated for only one side
$sidebar-container-margin: calc(
  (
      100vw - min(
          100vw - #{$container-padding} * 2,
          #{$max-container-width} + #{$container-padding}
        )
    ) / 2
);
// stylelint-enable scss/no-global-function-names

$shadow-s: 0px 4px 8px rgba(0, 0, 0, 0.3);

$default-top-gradient: radial-gradient(
  closest-side,
  rgba(68, 0, 255, 0.25),
  rgba(70, 78, 255, 0)
);
$default-bottom-gradient: radial-gradient(
  closest-side,
  rgba(204, 0, 177, 0.24),
  rgba(204, 0, 176, 0)
);
