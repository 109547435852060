@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'questSuccess') {
  @include typography.font('body-s', 'semi-bold');

  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  color: var(--aza-success-color);
}
