@use '../../../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'scrollTrigger') {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--media-offset));

  @include hsvs.view('trigger') {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 12%;

    transform: translate(-50%, -50%);
  }
}
