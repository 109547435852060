@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'notFoundPage') {
  display: block;

  @include hsvs.view('contentWrapper') {
    max-width: 808px;
    height: 100%;
    margin: 40px auto 60px;

    @include mixins.media-breakpoint-up(lg) {
      margin: 110px auto 150px;
    }
  }

  @include hsvs.view('content') {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.media-breakpoint-up(lg) {
      $top-gradient: (
        gradient: variables.$default-top-gradient,
        size: 890px 730px,
      );
      $bottom-gradient: (
        gradient: variables.$default-bottom-gradient,
        size: 678px 635px,
        offset-x: 0px,
        offset-y: -100px,
      );
      $gradient-inflation: 200px;

      @include mixins.gradient-background(
        $top-gradient,
        $bottom-gradient,
        50%,
        calc(50% + 30px),
        $gradient-inflation
      );

      margin: -#{$gradient-inflation} calc(#{variables.$container-padding} * -1);
      padding: $gradient-inflation variables.$container-padding;
    }
  }

  @include hsvs.view('imageContainer') {
    @include mixins.fixed-ratio-image-container(808, 344);
  }

  @include hsvs.view('description') {
    @include typography.font('body-xl', 'semi-bold');

    margin: 40px 0;

    text-align: center;

    @include mixins.media-breakpoint-up(lg) {
      margin: 10px 0 40px;
    }
  }

  @include hsvs.view('button') {
    width: 220px;

    text-align: center;
  }
}
