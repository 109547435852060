@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/base-classes/common';
@use '../../../styles/abstracts/base-classes/news-post';

@include hsvs.host('aza', 'newsPostPage') {
  display: block;

  @include hsvs.view('container') {
    max-width: 1120px;
  }

  @include hsvs.view('imageContainer') {
    @extend %aza-news-post-image;
    margin: 20px 0 45px;

    border-radius: 10px;

    @include mixins.media-breakpoint-up(lg) {
      margin: 40px 0 50px;
    }
  }

  @include hsvs.view('info') {
    @extend %aza-news-info;
  }

  @include hsvs.view('date') {
    @extend %aza-news-post-date;
  }

  @include hsvs.view('title') {
    @extend %aza-news-post-title;
  }

  @include hsvs.view('body') {
    @extend %aza-news-post-description;

    margin-bottom: 60px;

    @include mixins.media-breakpoint-up(lg) {
      margin-bottom: 120px;
    }
  }
}
