@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'actionCard') {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 42px 22px 22px;
  overflow: hidden;

  border: 0;
  border-radius: 10px;

  background-color: var(--aza-base-02-color);

  color: var(--aza-text-01-color);
  text-align: center;
  text-decoration: none;
  isolation: isolate;

  cursor: pointer;

  @include hsvs.state(':disabled') {
    opacity: 0.4;

    cursor: default;
  }

  @include hsvs.view('content') {
    transition: 0.3s transform ease;

    @include hsvs.state(':not(:disabled):hover') {
      transform: scale(1.1);
    }
  }

  @include hsvs.view('actionText') {
    @include typography.font('body-xl', 'semi-bold');
    margin: 11px 0 0;
  }

  @include hsvs.view('actionDescription') {
    @include typography.font('body-xs', 'light');
    margin: 0;
  }
}
