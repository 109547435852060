@use 'sass:math';

@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@use '../../../../../styles/abstracts/mixins';
@use '../../../../../styles/abstracts/base-classes/common';

@include hsvs.host('aza', 'azaCoinPageHero') {
  display: block;
  @include hsvs.view('sectionContent') {
    margin-top: 53px;

    text-align: center;
  }

  @include hsvs.view('header') {
    @include typography.font('h1-mobile', 'semi-bold');
    margin: auto;

    text-align: center;

    @include mixins.media-breakpoint-up('lg') {
      @include typography.font('h1', 'semi-bold');
    }
  }

  @include hsvs.view('text') {
    @include typography.font('body-xl', 'regular');
    margin-top: 16px;

    color: var(--aza-text-02-color);
  }

  @include hsvs.view('video') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    border-radius: 4px;

    @include mixins.media-breakpoint-up('lg') {
      border-radius: 10px;
    }
  }

  @include hsvs.view('videoFrame') {
    display: inline-block;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;

    border-radius: 4px;

    background: rgba(87, 89, 94, 0.1);
    backdrop-filter: blur(10px);
    @include mixins.media-breakpoint-up('lg') {
      padding: 44px;

      border-radius: 12px;
    }
  }

  @include hsvs.view('videoSize') {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 56.2498%;
  }

  @include hsvs.view('videoContainer') {
    display: block;

    text-align: center;
  }

  @include hsvs.view('videoCrystal') {
    position: absolute;
    z-index: 2;
    right: -10%;
    bottom: -9%;
    width: 16%;
  }

  @include hsvs.view('relativeContainer') {
    display: inline-block;
    position: relative;
    width: 82.5%;
    max-width: 1213px;
    margin-top: 40px;
  }

  @include hsvs.view('leftCoin') {
    position: absolute;
    z-index: 1;
    top: 36%;
    left: -6%;
    width: 13%;
  }

  @include hsvs.view('button') {
    min-width: 210px;
    margin-top: 30px;
  }

  @include hsvs.view('metamaskLink') {
    @extend %aza-accent-link;
    @include typography.font('body-xl', 'semi-bold');
    display: block;
    max-width: fit-content;
    margin: 30px auto 0;
    padding: 0;

    border: none;

    background: none;

    text-decoration: underline;

    cursor: pointer;
  }
}
