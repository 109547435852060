@use '../mixins';
@use '../typography';

@mixin aza-button {
  @include typography.font('body-m', 'semi-bold');
  display: inline-block;
  box-sizing: border-box;

  transition: background-color 0.5s;

  border: 0;
  border-radius: var(--aza-button-border-radius, 10px);

  background-color: var(--aza-primary-color);

  color: var(--aza-text-01-color);
  text-decoration: none;

  cursor: pointer;

  &:disabled {
    opacity: 0.4;

    cursor: default;
  }

  &:not(:disabled):hover {
    background-color: var(--aza-primary-active-color);
  }
}

@mixin aza-button-primary {
  @include mixins.gradient-button(
    274.37deg,
    (color: #2c51bd, offset: 0%) (color: #543ba1, offset: 50.18%)
      (color: #583488, offset: 97.32%)
  );
  transition: background-position 0.5s;
}

@mixin aza-button-secondary {
  @include mixins.gradient-button(
    273.86deg,
    (color: var(--aza-gradient-secondary-1, #90469f), offset: 0%)
      (color: var(--aza-gradient-secondary-2, #f13886), offset: 40.7%)
      (color: var(--aza-gradient-secondary-3, #ee3a57), offset: 77.33%)
  );
  transition: background-position 0.5s;
}

%button {
  @include aza-button;

  &--primary {
    @include aza-button-primary;
  }

  &--secondary {
    @include aza-button-secondary;
  }
}
