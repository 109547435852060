@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/base-classes/titles';
@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/base-classes/common';

%_balance-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px 30px;
}

@include hsvs.host('aza', 'walletPage') {
  display: block;
  padding-top: 70px;
  padding-bottom: 100px;

  @include hsvs.view('pageTitle') {
    @extend %aza-page-title;
  }

  @include hsvs.view('balanceSection') {
    @extend %_balance-flex;

    margin-bottom: 20px;

    @include mixins.media-breakpoint-up('lg') {
      margin-bottom: 30px;
    }
  }

  @include hsvs.view('balanceControls') {
    @extend %_balance-flex;
  }

  @include hsvs.view('balanceControl') {
    min-width: 200px;
  }

  @include hsvs.view('nav') {
    display: flex;
    align-self: normal;
    width: min(100%, 670px);
    margin-top: 40px;

    box-shadow: 0 -2.5px 0 0 inset rgba(255, 255, 255, 0.7);
  }

  @include hsvs.view('navItem') {
    @extend %aza-nav-link;

    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0 12px 22px;
    column-gap: 10px;

    transition: background-color 0.25s ease-in-out;

    color: rgba(255, 255, 255, 0.7);

    @include typography.font('body-xl', 'light');

    @include hsvs.state((), (), ([active])) {
      @extend %aza-nav-link--active;

      color: rgba(255, 255, 255, 1);
    }
  }
}
