@use '../../../../../../../cdk/src/hsvs/hsvs';

@include hsvs.host('aza', 'questImageSuccess') {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include hsvs.state([success]) {
    filter: grayscale(100%);
  }
}
