@use '../../../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../../../core/src/styles/typography';

@include hsvs.host('aza', 'questTitleSuccess') {
  display: inline-block;

  @include hsvs.state([success]) {
    color: var(--aza-accent-color);
    font-weight: typography.font-weight('semi-bold');
  }
}
