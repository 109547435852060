@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';

@include hsvs.host('aza', 'azaGreetingPopupXmas') {
  display: block;

  @include hsvs.view('imageContainer') {
    img {
      display: block;
      width: 100%;
      height: auto;

      pointer-events: none;
    }
  }

  @include hsvs.view('contentWrapper') {
    position: absolute;
    top: 8%;
    left: 50%;
    width: 80%;

    transform: translateX(-50%);

    pointer-events: none;
  }

  @include hsvs.view('contentContainer') {
    @include mixins.fixed-ratio-image-container(535, 490);
  }

  @include hsvs.view('button') {
    display: block;
    width: max-content;
    min-width: 50%;
    margin: 36px auto 48px;
  }

  @include hsvs.view('buttonAbsolute') {
    display: block;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    width: max-content;
    min-width: 50%;

    transform: translateX(-50%);
  }
}
