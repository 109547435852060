@use '../../../../../cdk/src/hsvs/hsvs';
@use '../../../../../core/src/styles/typography';

@use '../../../styles/abstracts/mixins';
@use '../../../styles/abstracts/variables';

@include hsvs.host('aza', 'mocaWidget') {
  display: flex;
  z-index: 3;
  justify-content: center;
  width: 100%;

  @include hsvs.view('widget') {
    @include typography.font('body-l', 'semi-bold');

    $radius: 60px;

    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    padding: 10px 35px;
    column-gap: 12px;

    animation: moca-widget-show 200ms ease-out forwards;

    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;

    background-color: #00d1ff;

    color: var(--aza-base-01-color);
    text-transform: uppercase;

    @include mixins.media-breakpoint-up('md') {
      @include typography.font('body-xl', 'semi-bold');

      column-gap: 20px;
    }
  }

  @include hsvs.view('button') {
    min-width: 165px;
    min-height: 39px;
  }

  @include hsvs.view('subtitleMobile') {
    @include mixins.media-breakpoint-up('md') {
      display: none;
    }
  }

  @include hsvs.view('subtitle') {
    display: none;

    @include mixins.media-breakpoint-up('md') {
      display: inline;
    }
  }

  @include hsvs.view('btnTitle') {
    text-wrap: nowrap;
  }
}

@keyframes moca-widget-show {
  0% {
    transform: translateY(-100%);

    opacity: 0;
  }

  100% {
    transform: translateY(0);

    opacity: 1;
  }
}
